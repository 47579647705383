import { cdnPublicFolderUrl } from "."

const agentScheduler = [
  {
    title: "Introducing Agent Scheduler",
    description: [
      "The ultimate solution for seamless task management and control. Enhance your Stable Diffusion macros with this free extension and experience unparalleled efficiency.",
      "Say goodbye to generating tasks one by one and let Agent Scheduler generate hundreds of images while you sleep.",
    ],
    bannerImg: `https://cdn.protogaia.com/resources/public/images/landing/agent-scheduler/banner1.gif`,
  },
  {
    title: "Boost your Productivity",
    description: [
      "Automate your AI Art workflow and let Agent Scheduler work while you sleep! Enqueue all your AI image generation tasks and the Agent will remember all task parameters such as prompt, dimension, model, CFG scale, ControlNet settings, and more.",
    ],
    bannerImg: `https://cdn.protogaia.com/resources/public/images/landing/agent-scheduler/banner2.mp4`,
  },
  {
    title: "Real-time Task Queue Management",
    description: [
      "Keep track of all your tasks in real-time. Prioritize, cancel running tasks, or delete pending tasks, giving you complete control over the queue.",
    ],
    bannerImg: `https://cdn.protogaia.com/resources/public/images/landing/agent-scheduler/banner3.mp4`,
  },
  {
    title: "Tweak Parameters on the fly",
    description: [
      "Find the best settings for a specific prompt without having to wait for each image to generate",
      "Generate images with Different Models quickly. Find which checkpoint provides the best results without loading them into the VRAM first.",
    ],
    bannerImg: `https://cdn.protogaia.com/resources/public/images/landing/agent-scheduler/banner4.mp4`,
  },
  {
    title: "Task Organization",
    description: [
      "View all previously completed tasks via the Task History.",
      "Bookmark Tasks: Save tasks for later and find them quickly by pinning them to the top or using filters.",
      "Rename Tasks: Name each task so you can find them quickly next time. ",
      "Send to text, image, inpaint, or extras, to modify your images on the fly, or re-queue previous tasks to generate them again.",
    ],
    bannerImg: `https://cdn.protogaia.com/resources/public/images/landing/agent-scheduler/banner5.mp4`,
  },
  {
    title: "Testimonials",
    description: [
      "'Been waiting since November 2022 for something like this... And here you arrive like an angel. With this awesomely built queue feature. Thank you so damn much. Very deeply appreciated...'",
      "'I love the functionality of the extension... this might just deserve a bigger spotlight!'",
      "'This is going to help diversify the overnight generation marathons. Many Thanks'",
    ],
    bannerImg: `https://cdn.protogaia.com/resources/public/images/landing/agent-scheduler/banner6.png`,
  },
]

const links = {
  GET_STATED: "https://github.com/ArtVentureX/sd-webui-agent-scheduler",
  HOW_TO_INSTALLED: "https://github.com/ArtVentureX/sd-webui-agent-scheduler#installation",
}

export { agentScheduler, links }
