export const MenuSetting = {
  about: "Your account",
  security: "Security",
  privacy: "Privacy",
  info: "Info",
  members: "Members",
  plans: "Plans",
  billing: "Billing",
  "share-workspace": "Shared workspaces",
}
