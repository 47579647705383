export const regexOnlyWordAndNumber = /[^a-zA-Z0-9]/g

export const regexOnlyMention = /@\((.*?)\)/g

export const regexMentionString =
  "[" +
  "\\w\\.-" +
  "\u00C0-\u00D6" +
  "\u00D8-\u00F6" +
  "\u00F8-\u00FF" +
  "\u0100-\u0148" +
  "\u014A-\u017F" +
  "\u0410-\u044F" +
  "\u3005-\u3006" +
  "\u3040-\u309F" +
  "\u30A0-\u30FF" +
  "\u3130-\u318F" +
  "\uAC00-\uD7A3" +
  "\u4e00-\u9fa5" +
  "\u0600-\u06ff" +
  "\u00C0-\u1EF9" +
  "]"

export const imageExtensionRegex = /\.(?:png|jpeg|jpg|webp|gif)(?=$|\?.*)/

export const regexOnlyUpperCase = /[^A-Z]/g
