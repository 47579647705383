export const faqContent = [
  {
    name: "What is Agent Scheduler?",
    content: [
      {
        title: "Agent Scheduler is a tool will help you to track & monitor the Remix tasks on GAIA ",
      },
    ],
  },
  {
    name: "How can we use the Agent Scheduler?",
    content: [
      {
        title: "Ongoing tab - Show all the Queued tasks and the running tasks",
        description: [
          "- You can drag and drop to change the order of each task,",
          "- You can Cancel the pending tasks",
          "- Edit the destination folder for the output",
          "- View details of the task",
        ],
      },
      {
        title: "History tab will show all the completed, canceled, failed tasks",
        description: ["- Re-generate/ Re-run the canceled tasks", "- View details of the task", "- Delete the log"],
      },
      {
        image: "10.1.png",
      },
    ],
  },

  {
    name: `Tips and Tricks`,
    content: [
      {
        title: "You can learn more about the Feature updates, Tutorials, Prompt Book & FAQ here",
        image: "14.1.png",
      },
    ],
  },
]
