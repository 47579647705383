/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface SolanaAuthDto {
  origin: string;
  publicKey: string;
}

export interface SolanaAuthResponse {
  nonce: string;
  message: string;
}

export interface SolanaValidateAuthDto {
  origin: string;
  publicKey: string;
  nonce: string;
  signature: string;
  email?: string;
  code?: string;
  provider?: string;
}

export interface SolanaValidateAuthResponse {
  customToken: string;
}

export interface GetEmailVerifiedStatusResponseDto {
  success: boolean;
  emailVerified: boolean;
}

export interface SignUpConfirmationRequestDto {
  email: string;
}

export interface SendCodeResponseDto {
  success: boolean;
}

export interface VerifySignUpConfirmationRequestDto {
  email: string;
  code: string;
}

export interface VerifyCodeResponseDto {
  success: boolean;
}

export interface SignInPasscodeRequestDto {
  email: string;
}

export interface VerifySignInPasscodeRequestDto {
  email: string;
  code: string;
}

export interface VerifySignInPasscodeResponseDto {
  success: boolean;
  customToken: string;
}

export interface ResetPasswordRequestDto {
  email: string;
}

export interface VerifyResetPasswordRequestDto {
  email: string;
  code: string;
  password: string;
}

export interface LinkWalletRequestDto {
  email: string;
}

export interface VerifyLinkWalletRequestDto {
  email: string;
  code: string;
}

export interface LinkFarcasterRequestDto {
  email: string;
}

export interface VerifyLinkFarcasterRequestDto {
  email: string;
  code: string;
}

export interface ChangeEmailRequestDto {
  email: string;
}

export interface ConfirmChangeEmailCodeRequestDto {
  code: string;
}

export interface VerifySecureCode {
  code: string;
}

export interface GetConnectedUserResponse {
  uid: string;
}

export interface FarcasterAuthorizeRequest {
  nonce: string;
  signature: string;
  message: string;
  domain: string;
  farcasterId: number;
  displayName?: string;
  username?: string;
  pfpUrl?: string;
  email?: string;
  code?: string;
}

export interface FarcasterAuthorizeResponse {
  customToken: string;
}

export interface EvmAuthDto {
  origin: string;
  publicKey: string;
}

export interface EvmAuthResponse {
  nonce: string;
  message: string;
}

export interface EvmValidateAuthDto {
  origin: string;
  publicKey: string;
  nonce: string;
  signature: string;
  email?: string;
  code?: string;
  provider?: string;
}

export interface EvmValidateAuthResponse {
  customToken: string;
}

export type TelegramAuthorizeRequest = object;

export interface TelegramAuthorizeResponse {
  customToken: string;
}

export enum FilterGeneratedByOptions {
  GAIA = 'GAIA',
  OTHER = 'OTHER',
}

export enum SortOptions {
  MOSTCOMMENTS = 'MOST COMMENTS',
  MOSTREACTION = 'MOST REACTION',
  MOSTFAVORITE = 'MOST FAVORITE',
  MOST_VIEWS = 'MOST_VIEWS',
  NEWEST = 'NEWEST',
  AZ = 'A-Z',
  MOST_USED = 'MOST_USED',
}

export interface ImageOwner {
  uid: string;
  name: string;
  email: string;
  picture: string;
  username?: string;
}

/** @default "none" */
export enum ImageGenerationSource {
  Workflow = 'workflow',
  Recipe = 'recipe',
  None = 'none',
}

export enum Reaction {
  LIKE = 'LIKE',
  DISLIKE = 'DISLIKE',
  LAUGH = 'LAUGH',
  CRY = 'CRY',
  HEART = 'HEART',
}

export interface UserImageReaction {
  userUid: string;
  reaction: Reaction;
}

export interface ExploreImageSimpleMetric {
  id: number;
  heartCount: number;
  favoriteCount: number;
  downloadCount: number;
}

export interface ImageWorkflow {
  id: string;
  name: string;
}

export interface ImageRecipe {
  id: string;
  name: string;
}

export interface ImageRecipeTask {
  id: string;
}

export enum ContentModerationRating {
  Unrated = 'unrated',
  Sfw = 'sfw',
  SemiNsfw = 'semi-nsfw',
  HardcoreNsfw = 'hardcore-nsfw',
}

export interface ExploreImageSimpleDetail {
  id: string;
  url: string;
  name: string;
  width: number;
  height: number;
  blurHash: string | null;
  owner: ImageOwner;
  generationSource: ImageGenerationSource;
  userReactions: UserImageReaction[] | null;
  metric: ExploreImageSimpleMetric;
  workflow: ImageWorkflow | null;
  recipe: ImageRecipe | null;
  recipeTask?: ImageRecipeTask;
  createdAt: string;
  updatedAt: string;
  favoritedByUser?: boolean;
  moderationRating: ContentModerationRating;
  prompt: string | null;
  isHidden?: boolean;
}

export interface GetInspirationBoardImagesResult {
  images: ExploreImageSimpleDetail[];
  total: number;
}

export interface ExploreImageFullMetric {
  id: number;
  heartCount: number;
  favoriteCount: number;
  downloadCount: number;
  commentCount: number;
  shareCount: number;
  viewCount: number;
}

export interface ImageTag {
  id: number;
  name: string;
}

export interface ImageMetadataItem {
  key: string;
  value: string;
}

export interface ExploreImageFullDetail {
  id: string;
  url: string;
  name: string;
  width: number;
  height: number;
  blurHash: string | null;
  owner: ImageOwner;
  generationSource: ImageGenerationSource;
  userReactions: UserImageReaction[] | null;
  metric: ExploreImageFullMetric;
  workflow: ImageWorkflow | null;
  recipe: ImageRecipe | null;
  recipeTask?: ImageRecipeTask;
  createdAt: string;
  updatedAt: string;
  favoritedByUser?: boolean;
  moderationRating: ContentModerationRating;
  prompt: string | null;
  isHidden?: boolean;
  negativePrompt: string;
  note: string;
  tags: ImageTag[];
  metadata: ImageMetadataItem[] | null;
  downloadCount: number;
  /** @format date-time */
  deletedAt: string | null;
  /** @format date-time */
  discoverableAt: string;
}

export interface GetInspirationBoardSimilarImagesResult {
  images: ExploreImageSimpleDetail[];
  total: number;
}

export interface CreateInspirationBoardImageCommentDto {
  value: string;
  formatValue: string | null;
  uidMentions: string[] | null;
  parentCommentId?: string | null;
  x?: string;
  y?: string;
  nodeId?: number;
}

export interface Tag {
  createdAt: string;
  updatedAt: string;
  id: number;
  name: string;
}

export interface User {
  createdAt: string;
  updatedAt: string;
  uid: string;
  name: string;
  username?: string;
  email: string;
  picture: string;
  newNotificationsCount: number;
  storageSize: number;
  isFullStorage?: boolean;
  completedWalkthrough?: boolean;
  recipeTaskSearchKeywords?: string[];
  /** @default 0 */
  availableGeneration: number;
  /** @format date-time */
  deletedAt: string;
}

export enum WorkspaceRole {
  Owner = 'owner',
  Admin = 'admin',
  Member = 'member',
  Guest = 'guest',
}

export interface UserWorkspace {
  id: number;
  role: WorkspaceRole;
  user: User;
  workspace: Workspace;
  userUid: string;
  workspaceId: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  lastLoginAt: string;
  isActive: boolean;
  storageSize: number;
}

export interface Workspace {
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
  description: string;
  photoUrl: string;
  userWorkspaces: UserWorkspace[];
  workspacePhotoId: string;
}

export enum ResourceRole {
  Owner = 'owner',
  Editor = 'editor',
  Contributor = 'contributor',
  Viewer = 'viewer',
}

export interface FolderPermission {
  id: number;
  user: User | null;
  folder: Folder;
  workspace: Workspace | null;
  userUid: string | null;
  workspaceId: string | null;
  folderId: string | null;
  /** @default false */
  pinned: boolean;
  role: ResourceRole;
  isGuest?: boolean;
}

export interface Folder {
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
  allowNonStableDiffusion?: boolean;
  creator: User | null;
  creatorUid?: string;
  images: Image[];
  childFolders?: Folder[];
  parentFolder: Folder | null;
  parentFolderId: string;
  permissions?: FolderPermission[];
  /** @format date-time */
  deletedAt?: string;
  originalParentFolderId: string | null;
  softDeletedImages?: Image[];
  type: string;
  isRoot: boolean;
}

export interface CommentMention {
  createdAt: string;
  updatedAt: string;
  id: string;
  user: User;
  userUid: string;
  comment: Comment;
  commentId: string;
}

export interface Comment {
  createdAt: string;
  updatedAt: string;
  id: string;
  image: Image | null;
  value: string;
  formatValue: string | null;
  commenter: User | null;
  commenterUid: string;
  childComments?: Comment[];
  parentComment: Comment | null;
  parentCommentId: string;
  mentions?: CommentMention[];
  likedBy: User[];
  childCommentsCount: number | null;
  likeCount: number;
  /** @default false */
  isRead: boolean;
  nodeId?: number;
}

export enum RecipeType {
  Normal = 'normal',
  Inpaint = 'inpaint',
  Chain = 'chain',
  Comfyui = 'comfyui',
  Describe = 'describe',
  Turbo = 'turbo',
  Other = 'other',
}

export enum RecipeTaskStatus {
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  DRAFT = 'DRAFT',
}

export enum ShareByWorkspaceMode {
  Restricted = 'restricted',
  Workspace = 'workspace',
}

export enum RecipeOutputType {
  Image = 'image',
  Animated = 'animated',
  Text = 'text',
}

export enum RecipeInputType {
  Prompt = 'prompt',
  Text = 'text',
  Image = 'image',
  Model = 'model',
  Lora = 'lora',
  LoraSimple = 'lora-simple',
  Number = 'number',
  Boolean = 'boolean',
  Select = 'select',
  Radio = 'radio',
  Pose = 'pose',
  Style = 'style',
  Ratio = 'ratio',
}

export enum SDBaseModel {
  All = '$all',
  Sd1X = 'sd-1x',
  Sd2X = 'sd-2x',
  SdXl = 'sd-xl',
  Sd3X = 'sd-3x',
  Flux1 = 'flux1',
}

export interface RecipeInputFieldOption {
  display: string;
  value: string | number;
  thumbnail?: string;
}

export interface RecipeInputField {
  key: string;
  comfyKey: string;
  weightComfyKey?: string;
  name: string;
  description?: string;
  tooltip?: string;
  value?: string | number | boolean;
  type: RecipeInputType;
  baseModel?: SDBaseModel;
  subType?: 'slider' | 'manual_input';
  min?: number;
  max?: number;
  step?: number;
  options?: RecipeInputFieldOption[];
  hide?: boolean;
  optional?: boolean;
  depends?: string;
  title?: string;
  /**
   * If true, the input's value will be removed from metadata
   * @default false
   */
  private?: boolean;
  placeholder?: string;
  /** If input has value, all keys in this array will be removed from payload */
  removeKeys?: string[];
  clearTriggerKey?: string;
  allowWildcard?: boolean;
  examples?: string[];
}

export interface RecipeStep {
  index: number;
  name: string;
  description?: string;
  inputs: RecipeInputField[];
}

export interface SDWorkflow {
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
  description?: string;
  thumbnailFileId: string | null;
  thumbnailModerationRating: string;
  thumbnailUrl: string;
  workflow: Record<string, any>;
  prompt: Record<string, any>;
  steps: RecipeStep[];
  isDraft: boolean;
  workspaceId: string;
  sharingMode: ShareByWorkspaceMode;
  /** @format date-time */
  discoverableAt: string;
  /** @format date-time */
  deletedAt: string;
  isLatest: boolean;
  lastRecipeId: string;
}

export enum FeedbackRating {
  Good = 'good',
  NotGood = 'not-good',
}

export interface UserFeedback {
  id: number;
  rating: FeedbackRating;
}

export enum QueueType {
  Default = 'default',
  Fast = 'fast',
  Flux1 = 'flux1',
  Dedicated = 'dedicated',
}

export interface RecipeTask {
  createdAt: string;
  updatedAt: string;
  id: string;
  recipeId: string;
  recipeType: RecipeType;
  params: object;
  folderId?: string;
  creator: User | null;
  status: RecipeTaskStatus;
  priority: number;
  /** @format date-time */
  startedAt?: string;
  /** @format date-time */
  completedAt?: string;
  isDeleted?: boolean;
  /** @format date-time */
  deletedAt?: string;
  images?: Image[];
  name?: string;
  prompt?: string;
  seed?: number;
  runnerId?: string;
  error?: string;
  resultImages?: string[];
  /** @format date-time */
  discoverableAt: string;
  sharingMode: ShareByWorkspaceMode;
  outputType?: RecipeOutputType[];
  outputText?: string;
  outputTree?: object;
  turboSessionId?: string;
  executionDuration?: number;
  sdWorkflow?: SDWorkflow;
  sdWorkflowId: string | null;
  userFeedback?: UserFeedback;
  /** @default false */
  isHtml?: boolean;
  queueType: QueueType;
}

export interface SampleImage {
  url: string;
}

export interface Recipe {
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
  description: string;
  thumbnail: string;
  isActive: boolean;
  sendTo?: boolean;
  sendLoraTo?: boolean;
  steps: RecipeStep[];
  samples?: SampleImage[];
  prompt?: object;
  type: RecipeType;
  baseModel: SDBaseModel;
  workspaceId: string;
  sharingMode: ShareByWorkspaceMode;
  /** @format date-time */
  discoverableAt: string;
  /** @format date-time */
  deletedAt: string;
  /** @default "" */
  previewDescription: string;
  outputType?: RecipeOutputType[];
  sdWorkflowId: string;
  queueType: QueueType;
}

export interface ImagePermission {
  id: number;
  image: Folder;
  user: User | null;
  workspace: Workspace | null;
  imageId: string;
  userUid: string;
  workspaceId: string;
  /** Mark permission granted by clicking public link */
  isPublic: boolean;
  role: ResourceRole;
  isGuest?: boolean;
}

export interface Image {
  createdAt: string;
  updatedAt: string;
  id: string;
  s3Key: string;
  name: string | null;
  sampler: string | null;
  steps: number | null;
  width: number;
  height: number;
  cfgScale: string | null;
  seed: string | null;
  modelHash: string | null;
  modelName: string | null;
  prompt: string;
  negativePrompt: string;
  denoisingStrength: string | null;
  upscaler: string | null;
  scaleFactor: string | null;
  blurHash: string | null;
  note: string;
  /** @example {} */
  tags: Tag[];
  /** @example {} */
  owner: User;
  ownerUid?: string;
  /** @example {} */
  folder: Folder | null;
  folderId?: string;
  /** @format date-time */
  deletedAt: string;
  /** @format date-time */
  expireAt: string;
  /** @example {} */
  originalFolder: Folder | null;
  /** @example {} */
  comments?: Comment[];
  isTutorial: boolean;
  /** @example {} */
  recipeTask: RecipeTask | null;
  recipeTaskId?: string;
  /** @example {} */
  recipe: Recipe | null;
  recipeId?: string;
  downloadCount: number;
  /** @example {} */
  imagePermissions: ImagePermission[];
  /** @default 0 */
  size?: number;
  /** @default "" */
  fullMetadata?: string;
  sharingMode: ShareByWorkspaceMode;
  url?: string;
  isDiscoverable: boolean;
  moderationRating: ContentModerationRating;
}

export interface UserMentionable {
  uid: string;
  name: string;
  email?: string;
  picture: string;
}

export interface GetMentionableUsersResult {
  users: UserMentionable[];
  total: number;
}

export interface FavoriteResult {
  action: string;
}

export enum CommentsOrderOption {
  DESC = 'DESC',
  ASC = 'ASC',
}

export interface GetCommentsResult {
  comments: Comment[];
  total: number;
  totalParentComment: number;
  indexParent?: number;
  skipParent?: number;
  takeParent?: number;
  indexChild?: number;
  skipChild?: number;
  takeChild?: number;
  fromNotification?: Comment[];
}

/** @default "createdAt" */
export enum ImageSortByColumn {
  FavoriteCount = 'favoriteCount',
  DownloadCount = 'downloadCount',
  CreatedAt = 'createdAt',
  Prompt = 'prompt',
  Name = 'name',
}

export enum ImageOriginFilterOptions {
  Generated = 'generated',
  Upload = 'upload',
}

export enum ImageView {
  MyImages = 'my-images',
  SharedWithMe = 'shared-with-me',
  Trash = 'trash',
}

export interface SearchImagesOptions {
  /** @default 0 */
  skip?: number;
  /** @default 10 */
  take?: number;
  searchQuery?: string;
  folderId?: string | null;
  onlyMine?: boolean;
  onlyFavorite?: boolean;
  onlyDiscoverable?: boolean;
  onlyShared?: boolean;
  sortBy?: ImageSortByColumn;
  onlyDeleted?: boolean;
  isPinned?: boolean;
  /** @default "DESC" */
  sortOrder?: 'ASC' | 'DESC';
  recipeIds?: string[];
  recipeTaskId?: string;
  recipeTaskIds?: string[];
  turboSessionId?: string;
  ownerUids?: string[];
  origin?: ImageOriginFilterOptions;
  imageIds?: string[];
  view?: ImageView;
  wildcardId?: string;
  modelId?: string;
}

export interface ImageWorkspace {
  id: string;
  name: string;
  picture: string;
}

export interface ImageSimpleMetric {
  id: number;
  heartCount: number;
  favoriteCount: number;
  downloadCount: number;
}

export interface ImageRoleCapabilities {
  canView: boolean;
  canUpdate: boolean;
  canMove: boolean;
  canDelete: boolean;
  canRecover: boolean;
  canShare: boolean;
  canComment: boolean;
  canToggleDiscovery: boolean;
}

export interface ImageFolder {
  id: string;
  name: string;
  /** @format date-time */
  deletedAt: string;
}

export interface ImageSimpleDetail {
  id: string;
  url: string;
  name: string;
  width: number;
  height: number;
  blurHash: string | null;
  owner: ImageOwner;
  workspace?: ImageWorkspace;
  generationSource: ImageGenerationSource;
  userReactions: UserImageReaction[] | null;
  metric?: ImageSimpleMetric;
  workflow: ImageWorkflow | null;
  recipe: ImageRecipe | null;
  createdAt: string;
  updatedAt: string;
  capabilities?: ImageRoleCapabilities;
  folder: ImageFolder | null;
  recipeTask: ImageRecipeTask | null;
  metadata: ImageMetadataItem[] | null;
  isDiscoverable: boolean;
  size: number;
  favoritedByUser?: boolean;
  /** @format date-time */
  deletedAt: string | null;
  pinned: boolean;
  userFeedback?: UserFeedback;
  moderationRating: ContentModerationRating;
  prompt: string;
}

export interface SearchImagesResponse {
  images: ImageSimpleDetail[];
  total: number;
}

export interface GetSimilarImagesResult {
  images: ImageSimpleDetail[];
  total: number;
}

export interface MoveImagesToFolderDto {
  imageIds: string[];
  folderId: string;
}

export interface MoveImagesToFolderResponse {
  moved: number;
}

export interface DeleteImagesDto {
  imageIds: string[];
}

export interface SoftDeleteImageResponse {
  deleted: number;
  message: string;
}

export interface RecoverImagesDto {
  imageIds: string[];
}

export interface RecoverImagesResponse {
  recovered: number;
}

export interface ImageFullMetric {
  id: number;
  heartCount: number;
  favoriteCount: number;
  downloadCount: number;
  commentCount: number;
  shareCount: number;
  viewCount: number;
}

export interface ImageSdWorkflow {
  id: string;
  name: string;
}

export interface ImageFullDetail {
  id: string;
  url: string;
  name: string;
  width: number;
  height: number;
  blurHash: string | null;
  owner: ImageOwner;
  workspace?: ImageWorkspace;
  generationSource: ImageGenerationSource;
  userReactions: UserImageReaction[] | null;
  metric?: ImageFullMetric;
  workflow: ImageWorkflow | null;
  recipe: ImageRecipe | null;
  createdAt: string;
  updatedAt: string;
  capabilities?: ImageRoleCapabilities;
  folder: ImageFolder | null;
  recipeTask: ImageRecipeTask | null;
  metadata: ImageMetadataItem[] | null;
  isDiscoverable: boolean;
  size: number;
  favoritedByUser?: boolean;
  /** @format date-time */
  deletedAt: string | null;
  pinned: boolean;
  userFeedback?: UserFeedback;
  moderationRating: ContentModerationRating;
  prompt: string;
  negativePrompt: string;
  note: string;
  tags: ImageTag[];
  downloadCount: number;
  sdWorkflow: ImageSdWorkflow | null;
}

export interface IncreaseImagesDownloadDto {
  imageIds: string[];
}

export interface IncreaseImagesShareDto {
  imageIds: string[];
}

export interface RenameImageDto {
  name: string;
}

export enum SharingRole {
  Viewer = 'viewer',
  Editor = 'editor',
  Contributor = 'contributor',
}

export interface ShareUser {
  role: SharingRole;
  userId?: string;
  email?: string;
}

export interface ShareToUsersDto {
  shareUsers?: ShareUser[];
}

export interface UnshareDto {
  userIds: string[];
}

export interface SwitchWorkspaceSharingModeDto {
  mode: ShareByWorkspaceMode;
  workspaceId?: string;
  role?: SharingRole;
}

export interface UpdateSharingRoleDto {
  role: SharingRole;
}

export enum GrantAccessAction {
  Approve = 'approve',
  Ignore = 'ignore',
}

export interface GrantAccessDto {
  role: SharingRole;
  action: GrantAccessAction;
}

export interface StableDiffusionMetadata {
  prompt?: string;
  steps?: number;
  sampler?: string;
  cfgScale?: string;
  seed?: string;
  width: number;
  height: number;
  modelHash?: string;
  model?: string;
  negativePrompt?: string;
  denoisingStrength?: string;
  tiledDiffusionUpscaler?: string;
  tiledDiffusionScaleFactor?: string;
}

export interface UploadFileInfo {
  filename: string;
  mimetype: string;
  fileSize: number;
  metadata?: StableDiffusionMetadata;
  blurhash?: string;
}

export interface InitializeImageMultipartUploadDto {
  files: UploadFileInfo[];
  folderId?: string;
  /** @default true */
  isDiscoverable?: boolean;
}

export interface InitializeImageMultipartUploadResult {
  key: string;
  filename: string;
  uploadId: string;
  uploadUrls: string[];
  file: Image;
}

export interface CompletedPart {
  eTag: string;
  partNumber: number;
}

export interface CompleteMultipartUploadDto {
  key: string;
  uploadId: string;
  parts: CompletedPart[];
}

export interface AbortMultipartUploadDto {
  key: string;
  uploadId: string;
  fileId: string;
}

export interface ToggleImageReactionDto {
  reaction: Reaction;
}

export enum ToggleReactionAction {
  CREATED = 'CREATED',
  REMOVED = 'REMOVED',
  UNCHANGED = 'UNCHANGED',
}

export interface ToggleReactionResult {
  action: ToggleReactionAction;
}

export interface ToggleImageDiscoveryDto {
  isDiscoverable: boolean;
  imageIds: string[];
}

export interface CopyImagesDto {
  imageIds: string[];
  toFolderId: string;
}

export interface CopyImagesResult {
  newImageIds: string[];
}

export interface CommentImageDto {
  value: string;
  formatValue: string | null;
  uidMentions: string[] | null;
  parentCommentId: string | null;
}

export interface FolderRoleCapabilities {
  canView: boolean;
  canUpdate: boolean;
  canMove: boolean;
  canDelete: boolean;
  canRecover: boolean;
  canShare: boolean;
  canCreate: boolean;
  canUpload: boolean;
}

export interface FolderWithChildren {
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
  allowNonStableDiffusion?: boolean;
  creator: User | null;
  creatorUid?: string;
  images: Image[];
  childFolders?: Folder[];
  parentFolder: Folder | null;
  parentFolderId: string;
  permissions?: FolderPermission[];
  /** @format date-time */
  deletedAt?: string;
  originalParentFolderId: string | null;
  softDeletedImages?: Image[];
  type: string;
  isRoot: boolean;
  /** @example "" */
  children: FolderWithChildren[];
  pinned: boolean;
  capabilities?: FolderRoleCapabilities;
}

export interface HasUnclassifiedImages {
  owned: boolean;
  shared: boolean;
  deleted: boolean;
}

export interface GetFoldersReponse {
  /** @example "" */
  owned: FolderWithChildren[];
  /** @example "" */
  shared: FolderWithChildren[];
  /** @example "" */
  pinned: FolderWithChildren[];
  hasUnclassifiedImages: HasUnclassifiedImages;
}

export interface ParentFolder {
  id: string;
  name: string | null;
}

export interface FolderCreator {
  uid: string;
  name: string;
  email: string;
  picture: string;
  username?: string;
}

export interface FolderUser {
  uid: string;
  name: string;
  email: string;
  picture: string;
}

export interface FolderWorkspace {
  id: string;
  name: string;
  photoUrl: string;
  picture: string;
}

export interface FolderPermissionDetail {
  id: number;
  user: FolderUser | null;
  workspace: FolderWorkspace | null;
  role: ResourceRole;
  isGuest?: boolean;
}

export enum SubfolderView {
  MyFolder = 'my-folder',
  SharedWithMe = 'shared-with-me',
  Trash = 'trash',
}

export interface FolderDetail {
  id: string;
  name: string;
  isRoot: boolean;
  parentFolder: ParentFolder | null;
  pinned: boolean;
  createdAt: string;
  updatedAt: string;
  /** @format date-time */
  deletedAt: string | null;
  creator: FolderCreator;
  capabilities: FolderRoleCapabilities;
  permissions: FolderPermissionDetail[];
  ancestors?: ParentFolder[];
  workspace: FolderWorkspace;
  view?: SubfolderView;
}

export enum FoldersSortByColumn {
  CreatedAt = 'createdAt',
  Name = 'name',
}

export interface SearchFoldersResult {
  folders: FolderDetail[];
  total: number;
}

export interface GetSubFoldersResponse {
  total: number;
  folders: FolderDetail[];
}

export interface CreateFolderDto {
  name: string;
  parentFolderId?: string;
}

export interface MoveFoldersDto {
  folderIds: string[];
  parentFolderId: string;
}

export interface UpdateFolderDto {
  name?: string;
}

export interface RenameFolderDto {
  newName: string;
}

export interface DeleteFoldersDto {
  folderId: string;
}

export interface UnshareFolderDto {
  userIds: string[];
}

export interface GetFolderPreviewInfoResponse {
  id: string;
  ownerName: string;
}

export enum TutorialPages {
  HomeSpace = 'home-space',
  Recipes = 'recipes',
  RecipeDetail1 = 'recipe-detail-1',
  RecipeDetail2 = 'recipe-detail-2',
  WorkflowDetail = 'workflow-detail',
  WorkflowDetail1 = 'workflow-detail-1',
  WorkflowDetail2 = 'workflow-detail-2',
  Inpainting = 'inpainting',
}

export enum SubscriptionStatus {
  Pending = 'pending',
  Active = 'active',
  Inactive = 'inactive',
  Cancelled = 'cancelled',
  PastDue = 'past-due',
}

export interface RequestCancelSubscriptionItem {
  id: string;
  /** @format date-time */
  scheduledAt: string;
  /** @format date-time */
  createdAt: string;
}

export enum PaymentMethod {
  Value2C2P = '2C2P',
  CRYPTO = 'CRYPTO',
  XSOLLA = 'XSOLLA',
  STRIPE = 'STRIPE',
}

export interface WorkspaceSubscriptionItem {
  id: string;
  subscriptionPackageId: string;
  subscriptionPackageName: string;
  seatCount: number;
  billingCycle: string;
  /** @format date-time */
  startAt: string;
  /** @format date-time */
  endAt: string | null;
  monthlyCost: string;
  biYearlyCost: string;
  yearlyCost: string;
  status: SubscriptionStatus;
  requestedCancel: RequestCancelSubscriptionItem | null;
  paymentMethod: PaymentMethod;
  cloudStorage: string;
  maxMembers: number;
  showNsfw: boolean;
  group: string;
  maxModelStorage: number;
}

export enum UserDefaultFolderType {
  RecipeImages = 'recipe-images',
  RecipeUpload = 'recipe-upload',
}

export interface UserDefaultFolderItem {
  id: number;
  folderId: string;
  type: UserDefaultFolderType;
}

export interface UserDetail {
  uid: string;
  name: string;
  email: string;
  username: string;
  picture: string;
  isFullStorage: boolean;
  storageSize: number;
  availableGeneration: number;
  currentWorkspace: Workspace;
  isPrivate: boolean;
  description: string;
  /** @format date-time */
  deletedAt: string | null;
  skippedTutorials: TutorialPages[];
  availableCredits: string;
  subscriptionCredits: string;
  wowPoints: string;
  refCode: string;
  farcaster: object;
  telegram: object;
  subscription: WorkspaceSubscriptionItem;
  modelStorageSize: number;
  modelStorageSizeLimit: number;
  defaultFolders: UserDefaultFolderItem[];
}

export interface SearchUsersDto {
  search?: string;
  /** @default 0 */
  skip?: number;
  /** @default 10 */
  take?: number;
}

export interface RecipeTaskSearchHistoriesResponse {
  histories: string[];
}

export interface ClearRecipeTaskSearchHistoriesDto {
  keywords?: string[];
}

export interface UpdateUserProfileDto {
  name?: string;
  email?: string;
  avatarFileId?: string | null;
  description?: string;
  coverImageFileId?: string | null;
  coverImageY?: string | null;
  skippedTutorials?: TutorialPages[];
}

export interface RequestDeleteAccountDto {
  reason: string;
  code: string;
}

export interface ReactivateAccountDto {
  code: string;
}

export interface CheckIfWhiteListedEmailsDto {
  emails: string[];
}

export interface CheckIfWhiteListedEmailsResult {
  isWhitelisted: boolean;
}

export interface AddBillingAddressDto {
  fullname: string;
  email?: string;
  phone: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  taxId?: string;
  isDefault?: boolean;
}

export interface AddUserNotificationTokenDto {
  token: string;
}

export interface SearchAllUsersDto {
  /** @default 0 */
  skip?: number;
  /** @default 10 */
  take?: number;
  searchTerm: string;
}

export interface HealthCheckResult {
  uptime: number;
}

export interface TagImageDto {
  tags: string[];
  imageIds: string[];
}

export interface UntagImageDto {
  tagIds: number[];
  imageIds: string[];
}

export interface PickTypeClass {
  id: number;
  name: string;
}

export interface GetTagsResponse {
  tags: PickTypeClass[];
  count: number;
}

export interface CreateTagDto {
  tag: string;
}

export interface UpdateCommentDto {
  value?: string;
  formatValue: string | null;
  uidMentions: string[] | null;
}

export enum EntityType {
  IMAGE = 'IMAGE',
  INSPIRATION_IMAGE = 'INSPIRATION_IMAGE',
  ARTICLE = 'ARTICLE',
  ARTBOARD = 'ARTBOARD',
  FOLDER = 'FOLDER',
  RECIPE_TASK = 'RECIPE_TASK',
  WORKFLOW = 'WORKFLOW',
  SD_WORKFLOW = 'SD_WORKFLOW',
  STYLE = 'STYLE',
  WILDCARD = 'WILDCARD',
  EXPLORE_WORKFLOW = 'EXPLORE_WORKFLOW',
  EXPLORE_STYLE = 'EXPLORE_STYLE',
  RECIPE = 'RECIPE',
  EXPLORE_RECIPE = 'EXPLORE_RECIPE',
  EXPLORE_SD_WORKFLOW = 'EXPLORE_SD_WORKFLOW',
  CHAT_ROOM = 'CHAT_ROOM',
  SD_MODEL = 'SD_MODEL',
}

export interface GetCommentIndexesResult {
  indexParent: number;
  indexChild?: number;
}

export interface MarkCommentsAsReadDto {
  entityId: string;
  entityType: EntityType;
}

export enum NotificationType {
  MENTION = 'MENTION',
  COMMENT = 'COMMENT',
  REPLY = 'REPLY',
  SHARE_FOLDER = 'SHARE_FOLDER',
  SHARE_IMAGE = 'SHARE_IMAGE',
  SHARE_STYLE = 'SHARE_STYLE',
  SHARE_RECIPE = 'SHARE_RECIPE',
  SHARE_WILDCARD = 'SHARE_WILDCARD',
  SHARE_SD_WORKFLOW = 'SHARE_SD_WORKFLOW',
  SHARE_WORKFLOW = 'SHARE_WORKFLOW',
  SHARE_SD_MODEL = 'SHARE_SD_MODEL',
  REQUEST_IMAGE_ACCESS = 'REQUEST_IMAGE_ACCESS',
  APPROVED_REQUEST_IMAGE_ACCESS = 'APPROVED_REQUEST_IMAGE_ACCESS',
  IGNORED_REQUEST_IMAGE_ACCESS = 'IGNORED_REQUEST_IMAGE_ACCESS',
  ARTICLE_REACTION = 'ARTICLE_REACTION',
  IMAGE_REACTION = 'IMAGE_REACTION',
  INSPIRATION_IMAGE_REACTION = 'INSPIRATION_IMAGE_REACTION',
  REQUEST_ACCESS = 'REQUEST_ACCESS',
  APPROVED_REQUEST_ACCESS = 'APPROVED_REQUEST_ACCESS',
  IGNORED_REQUEST_ACCESS = 'IGNORED_REQUEST_ACCESS',
  WORKSPACE_INVITE = 'WORKSPACE_INVITE',
  ACCEPTED_WORKSPACE_INVITE = 'ACCEPTED_WORKSPACE_INVITE',
  REJECTED_WORKSPACE_INVITE = 'REJECTED_WORKSPACE_INVITE',
  WORKSPACE_ROLE_UPDATE = 'WORKSPACE_ROLE_UPDATE',
  WORKSPACE_RENEW_SUBSCRIPTION = 'WORKSPACE_RENEW_SUBSCRIPTION',
  TIP_RECEIVED = 'TIP_RECEIVED',
  RECIPE_TASK_COMPLETED = 'RECIPE_TASK_COMPLETED',
  RECIPE_TASK_FAILED = 'RECIPE_TASK_FAILED',
  CHAT_ROOM_INVITE = 'CHAT_ROOM_INVITE',
  ACCEPTED_CHAT_ROOM_INVITE = 'ACCEPTED_CHAT_ROOM_INVITE',
  REJECTED_CHAT_ROOM_INVITE = 'REJECTED_CHAT_ROOM_INVITE',
  CHAT_ROOM_DAILY_UNREAD_MESSAGES = 'CHAT_ROOM_DAILY_UNREAD_MESSAGES',
  CHAT_ROOM_TAGGED = 'CHAT_ROOM_TAGGED',
  CREDIT_PURCHASE_SUCCESS = 'CREDIT_PURCHASE_SUCCESS',
  SUBSCRIPTION_PURCHASE_SUCCESS = 'SUBSCRIPTION_PURCHASE_SUCCESS',
}

export interface Notification {
  createdAt: string;
  updatedAt: string;
  id: string;
  metadata: string | null;
  statuses: NotificationStatus[];
  type: NotificationType;
  headline: string;
  message: string[];
  isGlobal: boolean;
  accessRequests: string[];
}

export interface NotificationStatus {
  createdAt: string;
  updatedAt: string;
  id: string;
  isRead: boolean;
  notification: Notification;
  user: User;
  deletedAt: object | null;
}

export interface NotificationWithStatus {
  createdAt: string;
  updatedAt: string;
  id: string;
  metadata: string | null;
  statuses: NotificationStatus[];
  type: NotificationType;
  headline: string;
  message: string[];
  isGlobal: boolean;
  accessRequests: string[];
  status: NotificationStatus;
  imageUrl: string | null;
  senderImageUrl: string | null;
  articleCoverUrl: string | null;
}

export interface GetNotificationsResponse {
  notifications: NotificationWithStatus[];
  total: number;
}

export enum UserUsageMode {
  Pro = 'pro',
  Lite = 'lite',
}

export interface SubmitOnboardingDto {
  purposes: string[];
  platforms: string[];
  mode: UserUsageMode;
}

export interface Onboarding {
  createdAt: string;
  updatedAt: string;
  id: number;
  purposes: string[];
  platforms: string[];
}

export interface UpsertWorkspaceDto {
  name: string;
}

export interface WorkspaceUser {
  uid: string;
  name: string;
  email: string;
  picture: string;
}

export interface WorkspaceMemberItem {
  id: number;
  /** @format date-time */
  lastLoginAt: string;
  isActive: boolean;
  role: WorkspaceRole;
  user: WorkspaceUser;
  isPending: boolean;
}

export interface WorkspaceWithRole {
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
  description: string;
  photoUrl: string;
  workspacePhotoId: string;
  userWorkspace: WorkspaceMemberItem;
}

export interface GetWorkspacesResponse {
  workspaces: WorkspaceWithRole[];
  total: number;
}

export interface WorkspaceDetail {
  id: string;
  name: string;
  photoUrl: string;
  description: string;
  haveUnlimitedGeneration: boolean;
  availableCredits: string;
  subscriptionCredits: string;
  currentSubscriptionId: string | null;
  subscriptionPackageId: string | null;
  subscriptionPackageName: string | null;
  owner: User | null;
}

export enum WorkspaceMembersSortByOptions {
  NAME = 'NAME',
  LOGIN_TIME = 'LOGIN_TIME',
}

export enum SortOrder {
  DESC = 'DESC',
  ASC = 'ASC',
}

export interface GetWorkspaceMembersResult {
  members: WorkspaceMemberItem[];
  total: number;
}

export interface AddMemberToWorkspaceDto {
  emails: string[];
  role?: WorkspaceRole;
}

export interface UpdateWorkspaceMemberDto {
  memberUids: string[];
}

export interface UpdateWorkspaceDto {
  name?: string;
  description?: string;
  photoId?: string;
}

export interface LeaveWorkspacesDto {
  workspaceIds: string[];
}

export interface TransferWorkspaceOwnershipDto {
  newOwnerUid: string;
}

export interface UpdateWorkspaceMemberRoleDto {
  role: WorkspaceRole;
}

export enum WorkspaceInviteStatus {
  Accepted = 'accepted',
  Recjected = 'recjected',
  Pending = 'pending',
}

export interface UpdateWorkspaceInviteDto {
  status: WorkspaceInviteStatus;
}

export interface WorkspaceItem {
  id: string;
  name: string;
  picture?: string;
}

export interface WorkspaceInviteItem {
  id: number;
  email: string;
  role: WorkspaceRole;
  status: WorkspaceInviteStatus;
  workspace: WorkspaceItem;
}

export interface WorkspacePlanItem {
  id: string;
  availableCredits: string;
  subscriptionCredits: string;
  currentSubscription: WorkspaceSubscriptionItem;
  isTrial: boolean;
  /** @format date-time */
  trialStartedAt: string;
  /** @format date-time */
  trialEndedAt: string;
  isAccessFeatures: boolean;
  currentModelStorageSubscription: WorkspaceSubscriptionItem | null;
  usedModelStorageSize: number;
}

export interface CheckIfWorkspaceReachMemberLimitDto {
  additionalMemberCount: number;
}

export enum PaymentPlatform {
  Value2C2P = '2C2P',
  STRIPE = 'STRIPE',
}

export interface WorkspacePaymentInfoItem {
  id: string;
  platform: PaymentPlatform;
  creditCard: string;
}

export interface UserJoinEarlyAccessDto {
  email: string;
  name: string;
  answer?: string;
}

export interface EarlyAccess {
  createdAt: string;
  updatedAt: string;
  id: string;
  email: string;
  name: string;
  answer: string;
}

export interface JoinGaiaEarlyAccessDto {
  email: string;
  telegramId?: string;
}

export interface GetCurrentVersionResponse {
  frontendVersion: string;
  backendVersion: string;
}

export interface GetRecipesResponse {
  recipes: Recipe[];
}

export enum RecipeFilterMode {
  Public = 'public',
  Owned = 'owned',
  Shared = 'shared',
  Trash = 'trash',
}

export enum RecipeSortOptions {
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST',
  A_TO_Z = 'A_TO_Z',
  Z_TO_A = 'Z_TO_A',
}

export interface SearchRecipesDto {
  /** @default 0 */
  skip?: number;
  /** @default 10 */
  take?: number;
  searchTerm?: string;
  recipeIds?: string[];
  types?: RecipeType[];
  baseModel?: SDBaseModel;
  categoryId?: number;
  mode?: RecipeFilterMode;
  sortBy?: RecipeSortOptions;
  sdWorkflowId?: string;
  canUseWildcard?: boolean;
  canUseImage?: boolean;
  canUseLora?: boolean;
  onlyDiscoverable?: boolean;
  onlyShared?: boolean;
  onlyMine?: boolean;
  isPinned?: boolean;
}

export interface RecipeCategoryItem {
  id: number;
  name: string;
}

export interface RecipeRoleCapabilities {
  canView: boolean;
  canUpdate: boolean;
  canMove: boolean;
  canDelete: boolean;
  canRecover: boolean;
  canShare: boolean;
  canAddToLibrary?: boolean;
  canRemoveFromLibrary?: boolean;
}

export interface RecipeCreator {
  uid: string;
  name: string;
  picture: string;
  username?: string;
}

export interface RecipeItemMetric {
  id: number;
  favoriteCount: number;
}

export interface RecipeItemTag {
  id: number;
  name: string;
}

export interface RecipeItemWorkspace {
  id: string;
  name: string;
  picture?: string;
}

export interface RecipeItem {
  id: string;
  name: string;
  description: string;
  thumbnail: string;
  steps: RecipeStep[];
  sendTo: boolean;
  type: RecipeType;
  baseModel: SDBaseModel;
  isPublic: boolean;
  outputType?: RecipeOutputType[];
  /** @format date-time */
  discoverableAt: string | null;
  /** @format date-time */
  deletedAt: string | null;
  workspaceId: string;
  createdAt: string;
  updatedAt: string;
  pinned?: boolean;
  categories: RecipeCategoryItem[];
  capabilities?: RecipeRoleCapabilities;
  creator: RecipeCreator | null;
  metric: RecipeItemMetric | null;
  favoritedByUser: boolean;
  tags: RecipeItemTag[];
  workspace: RecipeItemWorkspace;
  userFeedback?: UserFeedback;
}

export interface GetRecipesResult {
  recipes: RecipeItem[];
  total: number;
}

export interface Pose {
  display: string;
  value: string;
}

export interface GetPosesResponse {
  poses: Pose[];
}

export interface GetRecipeCategoriesResult {
  categories: RecipeCategoryItem[];
  total: number;
}

export interface UpdateRecipeDto {
  name?: string;
  description?: string;
  thumbnailId?: string;
  categoryIds?: number[];
}

export interface DeleteRecipesDto {
  recipeIds: string[];
}

export interface RecoverRecipesDto {
  recipeIds: string[];
}

export interface TagRecipesDto {
  tagIds: number[];
  recipeIds: string[];
}

export interface UntagRecipesDto {
  tagIds: number[];
  recipeIds: string[];
}

export interface RecipePermission {
  id: number;
  user: User | null;
  workspace: Workspace | null;
  role: ResourceRole;
}

export interface UpdateRecipeDiscoveryDto {
  recipeIds: string[];
  isDiscoverable: boolean;
}

export interface AddRecipesToLibraryDto {
  recipeIds: string[];
}

export interface AddRecipesToLibraryResult {
  recipeIds: string[];
}

export interface RemoveRecipesFromLibraryDto {
  recipeIds: string[];
}

export interface RemoveRecipesFromLibraryResult {
  recipeIds: string[];
}

export interface FilterRecipeTasksDto {
  recipeIds?: string[];
  /** @example ["QUEUED","RUNNING","COMPLETED","FAILED","CANCELED"] */
  statuses?: ('QUEUED' | 'RUNNING' | 'COMPLETED' | 'FAILED' | 'CANCELED')[];
  /** @default [] */
  taskIds?: string[];
}

export interface RecipeTaskWithFullImages {
  createdAt: string;
  updatedAt: string;
  id: string;
  recipeId: string;
  recipeType: RecipeType;
  params: object;
  folderId?: string;
  creator: User | null;
  status: RecipeTaskStatus;
  priority: number;
  /** @format date-time */
  startedAt?: string;
  /** @format date-time */
  completedAt?: string;
  isDeleted?: boolean;
  /** @format date-time */
  deletedAt?: string;
  images?: Image[];
  name?: string;
  prompt?: string;
  seed?: number;
  runnerId?: string;
  error?: string;
  resultImages?: string[];
  /** @format date-time */
  discoverableAt: string;
  sharingMode: ShareByWorkspaceMode;
  outputType?: RecipeOutputType[];
  outputText?: string;
  outputTree?: object;
  turboSessionId?: string;
  executionDuration?: number;
  sdWorkflow?: SDWorkflow;
  sdWorkflowId: string | null;
  userFeedback?: UserFeedback;
  /** @default false */
  isHtml?: boolean;
  queueType: QueueType;
  fullDataImages?: ImageSimpleDetail[];
}

export interface ListRecipeTasksResponse {
  running: RecipeTask[];
  queued: RecipeTask[];
  other: RecipeTaskWithFullImages[];
}

export interface RecipeTaskPreviewInfo {
  id: string;
  name: string;
  ownerName: string;
  previewImage: string | null;
}

export interface FilterOngoingRecipeTasksDto {
  /** @default 0 */
  skip?: number;
  /** @default 10 */
  take?: number;
  type?: RecipeType;
  recipeIds?: string[];
  /** @example ["QUEUED","RUNNING","COMPLETED","FAILED","CANCELED"] */
  statuses?: ('QUEUED' | 'RUNNING' | 'COMPLETED' | 'FAILED' | 'CANCELED')[];
  /** @default [] */
  taskIds?: string[];
  /** @default [] */
  styleIds?: string[];
  sdWorkflowId?: string;
  sdModelTrainingId?: string;
}

/** @default "createdAt" */
export enum RecipeTaskSortByColumn {
  CreatedAt = 'createdAt',
  Name = 'name',
}

export interface FilterRecipeTasksWithPaginationDto {
  /** @default 0 */
  skip?: number;
  /** @default 10 */
  take?: number;
  type?: RecipeType;
  recipeIds?: string[];
  /** @example ["QUEUED","RUNNING","COMPLETED","FAILED","CANCELED"] */
  statuses?: ('QUEUED' | 'RUNNING' | 'COMPLETED' | 'FAILED' | 'CANCELED')[];
  /** @default [] */
  taskIds?: string[];
  /** @default [] */
  styleIds?: string[];
  sdWorkflowId?: string;
  sdModelTrainingId?: string;
  /** @default [] */
  modelHashes?: string[];
  /** @default "" */
  search?: string;
  /** @default false */
  saveSearch?: boolean;
  /** @default true */
  includeFullDataImages?: boolean;
  sortBy?: RecipeTaskSortByColumn;
  /** @default "DESC" */
  sortOrder?: 'ASC' | 'DESC';
}

export interface FilterRecipeTasksWithPaginationDtoForAgentScheduler {
  /** @default 0 */
  skip?: number;
  /** @default 10 */
  take?: number;
  /** @default [] */
  recipeIds?: string[];
  /** @example ["QUEUED","RUNNING","COMPLETED","FAILED","CANCELED"] */
  statuses?: ('QUEUED' | 'RUNNING' | 'COMPLETED' | 'FAILED' | 'CANCELED')[];
  sortBy?: RecipeTaskSortByColumn;
  /** @default "DESC" */
  sortOrder?: 'ASC' | 'DESC';
}

export interface RecipeTasksWithTotalResponseForAgentScheduler {
  list: RecipeTask[];
  total: number;
}

export interface ListRecipeTasksResponseForAgentScheduler {
  running: RecipeTasksWithTotalResponseForAgentScheduler;
  queued: RecipeTasksWithTotalResponseForAgentScheduler;
  completed: RecipeTasksWithTotalResponseForAgentScheduler;
  failed: RecipeTasksWithTotalResponseForAgentScheduler;
  canceled: RecipeTasksWithTotalResponseForAgentScheduler;
}

export interface FilterRecipeTasksDtoForAgentScheduler {
  /** @default [] */
  recipeIds?: string[];
  /** @example ["QUEUED","RUNNING","COMPLETED","FAILED","CANCELED"] */
  statuses?: ('QUEUED' | 'RUNNING' | 'COMPLETED' | 'FAILED' | 'CANCELED')[];
}

export interface UploadImageForRecipeTaskResult {
  id: string;
  url: string;
}

export interface UploadImageForRecipeTaskResponse {
  images: UploadImageForRecipeTaskResult[];
}

export interface CreateRecipeTaskDto {
  recipeId: string;
  params: Record<string, any>;
  folderId?: string;
  name?: string;
  status?: RecipeTaskStatus;
  basedRecipeTaskId?: string;
  chatRoomId?: string;
  imageId?: string;
}

export interface CreateFreeRecipeTaskDto {
  recipeId: string;
  params: Record<string, any>;
  name?: string;
  status?: RecipeTaskStatus;
  basedRecipeTaskId?: string;
  chatRoomId?: string;
  imageId?: string;
  captchaToken: string;
}

export interface UpdateFreeRecipeTaskDto {
  params?: object;
  status?: RecipeTaskStatus;
  captchaToken: string;
}

export interface TransferAnonymousTaskOwnerDto {
  taskIds: string[];
}

export interface ReOrderRecipeTaskDto {
  taskId: string;
  position: string;
  targetTaskId: string;
}

export interface UpdateRecipeTaskDto {
  params?: object;
  folderId?: string;
  name?: string;
  status?: RecipeTaskStatus;
  description?: string;
}

export interface DeleteRecipeTasksDto {
  recipeTaskIds: string[];
}

export interface RecoverRecipeTasksResult {
  recovered: number;
}

export interface GetRecipeTaskRunInfoResult {
  params: object | null;
  sdWorkflowId: string | null;
}

export interface GetRecipeTaskForSDResponse {
  has_task: boolean;
  task: object;
  recipe_id: string;
}

export interface ImageMetadata {
  step: number;
  /** S3 key */
  key: string;
  /** File size */
  size: number;
  mimetype: string;
  width: number;
  height: number;
  blurHash: string;
}

export interface TextMetadata {
  step: number;
  text: string;
}

export interface CompleteRecipeTaskWithMetadataDto {
  completed: boolean;
  success: boolean;
  images: ImageMetadata[];
  texts?: TextMetadata[];
  error?: string;
  noUpload?: boolean;
  started_at?: number;
  completed_at?: number;
}

export interface GetSamEmbeddingDto {
  url: string;
}

export interface GetSamEmbeddingResult {
  embedding: string;
}

export enum SDModelVariant {
  Normal = 'normal',
  Inpaint = 'inpaint',
  Vae = 'vae',
  Embedding = 'embedding',
  Lora = 'lora',
  Upscaler = 'upscaler',
  Controlnet = 'controlnet',
  ControlnetLllite = 'controlnet-lllite',
  Animatediff = 'animatediff',
  AnimatediffLora = 'animatediff-lora',
  Ipadapter = 'ipadapter',
  Clipvision = 'clipvision',
  Clip = 'clip',
  Unet = 'unet',
  Font = 'font',
}

export enum SDModelSortOptions {
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST',
  A_TO_Z = 'A_TO_Z',
  Z_TO_A = 'Z_TO_A',
}

export enum SDModelStatus {
  DRAFT = 'DRAFT',
  QUEUED = 'QUEUED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export interface SDModel {
  createdAt: string;
  updatedAt: string;
  id: string;
  sha256: string;
  name: string;
  description: string;
  thumbnailUrl: string;
  thumbnailFileId: string | null;
  thumbnailModerationRating: string;
  styles: string;
  defaultSettings: object;
  fileName: string;
  modelFileId: string | null;
  variant: SDModelVariant;
  baseModel: SDBaseModel;
  status: SDModelStatus;
  workspaceId: string;
  sharingMode: ShareByWorkspaceMode;
  /** @format date-time */
  discoverableAt: string;
  /** @format date-time */
  deletedAt: string;
}

export interface GetModelsResponse {
  models: SDModel[];
}

export interface SDModelCreator {
  uid: string;
  name: string;
  picture: string;
  username?: string;
}

export interface SDModelWorkspace {
  id: string;
  name: string;
  picture?: string;
}

export interface SDModelRoleCapabilities {
  canView: boolean;
  canUpdate: boolean;
  canMove: boolean;
  canDelete: boolean;
  canRecover: boolean;
  canShare: boolean;
  canAddToLibrary?: boolean;
  canRemoveFromLibrary?: boolean;
}

export interface SDModelMetricItem {
  id: number;
  usageCount: number;
}

export interface SDModelItem {
  id: string;
  name: string;
  description: string;
  thumbnailUrl: string;
  thumbnailModerationRating: ContentModerationRating;
  styles: string;
  fileName: string;
  variant: SDModelVariant;
  baseModel: SDBaseModel;
  isPublic: boolean;
  workspaceId: string;
  createdAt: string;
  updatedAt: string;
  pinned?: boolean;
  status: SDModelStatus;
  creator: SDModelCreator | null;
  workspace: SDModelWorkspace;
  capabilities?: SDModelRoleCapabilities;
  /** @format date-time */
  deletedAt: string | null;
  metric?: SDModelMetricItem;
}

export enum SDModelFilterMode {
  Public = 'public',
  Owned = 'owned',
  Shared = 'shared',
  Trash = 'trash',
  Draft = 'draft',
}

export interface SearchModelDto {
  /** @default 0 */
  skip?: number;
  /** @default 10 */
  take?: number;
  searchTerm?: string;
  ids?: string[];
  variants?: SDModelVariant[];
  baseModel?: SDBaseModel;
  baseModels?: SDBaseModel[];
  mode?: SDModelFilterMode;
  sortBy?: SDModelSortOptions;
  isPinned?: boolean;
  creatorUid?: string;
}

export interface SearchModelResults {
  data: SDModelItem[];
  total: number;
}

export interface CreateSDModelDto {
  name?: string;
  filename: string;
  description?: string;
  sha256?: string;
  thumbnailFileId?: string;
  modelFileId?: string;
  variant: SDModelVariant;
  baseModel: SDBaseModel;
  fileSize: number;
}

export interface UpdateSDModelDto {
  name?: string;
  description?: string;
  thumbnailFileId?: string | null;
  modelFileId?: string | null;
}

export interface AddModelTrainingDto {
  trainingId: string;
  sdModelTrainingFileId: string;
}

export interface DeleteSDModelsDto {
  modelIds: string[];
}

export interface RecoverSDModelsDto {
  modelIds: string[];
}

export interface SDModelPermission {
  id: number;
  user: User | null;
  workspace: Workspace | null;
  role: ResourceRole;
}

export interface UpdateModelDiscoveryDto {
  modelIds: string[];
  isDiscoverable: boolean;
}

export interface AddSDModelsToLibraryDto {
  modelIds: string[];
}

export interface AddSDModelsToLibraryResult {
  modelIds: string[];
}

export interface RemoveSDModelsFromLibraryDto {
  modelIds: string[];
}

export interface RemoveSDModelsFromLibraryResult {
  modelIds: string[];
}

export interface CreateSDModelCommentDto {
  value: string;
  formatValue: string | null;
  uidMentions: string[] | null;
  parentCommentId?: string | null;
  x?: string;
  y?: string;
  nodeId?: number;
}

export interface ModelUploadFileInfo {
  filename: string;
  fileSize: number;
}

export interface InitializeMultipartModelUploadDto {
  files: ModelUploadFileInfo[];
}

export interface ModelUploadFile {
  id: string;
  name: string;
  size: number;
  mimetype: string;
  metadata: object;
  url: string | null;
  ownerUid: string;
  /** @format date-time */
  createdAt: string;
  uploaded: boolean;
}

export interface InitializeMultipartModelUploadResult {
  key: string;
  filename: string;
  uploadId: string;
  uploadUrls: string[];
  file: ModelUploadFile;
}

export interface CompleteMultipartModelUploadDto {
  key: string;
  uploadId: string;
  parts: CompletedPart[];
}

export interface AbortMultipartModelUploadDto {
  key: string;
  uploadId: string;
  fileId: string;
}

export interface DownloadedFile {
  file: string;
  downloaded: boolean;
  reason: string;
  sha: string;
  match: boolean;
}

export interface UploadCompletedMessageBody {
  removed_files: string[];
  downloaded_files: DownloadedFile[];
  total_size: number;
  started_at: number;
  completed_at: number;
}

export enum SDModelTrainingStatus {
  Draft = 'draft',
  Queued = 'queued',
  Training = 'training',
  Completed = 'completed',
  Failed = 'failed',
}

export enum SDModelTrainingSortOptions {
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST',
  A_TO_Z = 'A_TO_Z',
  Z_TO_A = 'Z_TO_A',
}

export enum SDModelTrainingFilterMode {
  Owned = 'owned',
  Trash = 'trash',
}

export interface SearchModelTrainingDto {
  /** @default 0 */
  skip?: number;
  /** @default 10 */
  take?: number;
  searchTerm?: string;
  ids?: string[];
  statuses?: SDModelTrainingStatus;
  sortBy?: SDModelTrainingSortOptions;
  mode?: SDModelTrainingFilterMode;
  baseModels?: SDBaseModel[];
}

export interface SDModelTrainingImage {
  fileId: string;
  caption?: string;
}

export interface SDModelTrainingOutputFile {
  key: string;
  size: number;
  url?: string;
}

export interface SDModelTrainingItem {
  id: string;
  name: string;
  description: string | null;
  baseModel: SDBaseModel;
  thumbnailFileId: string | null;
  thumbnailUrl: string | null;
  thumbnailModerationRating: ContentModerationRating;
  steps: number;
  epochs?: number;
  repeats?: number;
  batchSize?: number;
  advancedSteps?: boolean;
  autoCaption: boolean;
  triggerWords: string;
  dataset: SDModelTrainingImage[];
  status: SDModelTrainingStatus;
  startedAt: string | null;
  completedAt: string | null;
  progress: number;
  outputFiles: SDModelTrainingOutputFile[];
  error: string | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  creator: SDModelCreator;
  workspace: SDModelWorkspace;
}

export interface SearchModelTrainingResults {
  data: SDModelTrainingItem[];
  total: number;
}

export interface SDModelTraining {
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
  description: string | null;
  baseModel: SDBaseModel;
  thumbnailFileId: string | null;
  thumbnailModerationRating: string;
  steps: number;
  epochs: number;
  repeats: number;
  batchSize: number;
  advancedSteps: boolean;
  autoCaption: boolean;
  triggerWords: string;
  dataset: SDModelTrainingImage[];
  workspaceId: string;
  status: SDModelTrainingStatus;
  runnerId: string | null;
  /** @format date-time */
  startedAt: string | null;
  /** @format date-time */
  completedAt: string | null;
  progress: number;
  outputFileIds: string[];
  error: string | null;
  /** @format date-time */
  deletedAt: string;
}

export interface UpdateSDModelTrainingDto {
  name: string;
  description?: string;
  steps: number;
  epochs?: number;
  repeats?: number;
  batchSize?: number;
  advancedSteps?: boolean;
  autoCaption?: boolean;
  triggerWords: string;
  baseModel: SDBaseModel;
  thumbnailFileId?: string | null;
  dataset: SDModelTrainingImage[];
}

export interface EstimateTrainingCreditsDto {
  baseModel: SDBaseModel;
  steps: number;
  epochs: number;
  repeats: number;
  batchSize: number;
  datasetSize: number;
  advancedSteps?: boolean;
  autoCaptionSize?: number;
}

export interface EstimateTrainingCreditsResponse {
  credits: number;
  trainingTime: number;
}

export interface UpdateSDModelTrainingProgressDto {
  task_id: string;
  status: 'draft' | 'queued' | 'training' | 'completed' | 'failed';
  step: number;
  total_steps: number;
  output: SDModelTrainingOutputFile[];
  error?: string;
  started_at: number;
  completed_at?: number;
}

export interface GenerateUploadUrlDto {
  task_id: string;
}

export interface CreateSDModelTrainingRecipeTaskDto {
  recipeId: string;
  params: Record<string, any>;
  folderId?: string;
  name?: string;
  status?: RecipeTaskStatus;
  basedRecipeTaskId?: string;
  chatRoomId?: string;
  imageId?: string;
  trainingId: string;
}

export interface DeleteSDModelTrainingsDto {
  trainingIds: string[];
}

export interface RecoverSDModelTrainingsDto {
  trainingIds: string[];
}

export interface ShortUrl {
  short: string;
  long: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
}

export interface CreateShortUrlDto {
  longUrl: string;
}

export interface RecentFolderItem {
  id: number;
  /** @format date-time */
  accessedAt: string;
  folder: FolderDetail;
  folderView?: SubfolderView;
}

export interface RecentImageItem {
  id: number;
  /** @format date-time */
  accessedAt: string;
  image: ImageSimpleDetail;
}

export interface WorkflowCreator {
  uid: string;
  name: string;
  picture: string;
  username?: string;
}

export interface RoleCapabilities {
  canView: boolean;
  canUpdate: boolean;
  canMove: boolean;
  canDelete: boolean;
  canRecover: boolean;
  canShare: boolean;
}

export interface WorkflowItemMetric {
  id: number;
  favoriteCount: number;
}

export interface WorkflowImage {
  id: string;
  url: string;
  width: number;
  height: number;
  blurHash: string;
  moderationRating?: ContentModerationRating;
}

export interface WorkflowWorkspace {
  id: string;
  name: string;
  picture: string;
}

export interface WorkflowTagItem {
  id: number;
  name: string;
}

export interface WorkflowItem {
  id: string;
  name: string;
  status: RecipeTaskStatus;
  favoritedByUser: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  creator: WorkflowCreator;
  capabilities: RoleCapabilities;
  metric: WorkflowItemMetric | null;
  image: WorkflowImage | null;
  /** @format date-time */
  discoverableAt: string | null;
  workspace?: WorkflowWorkspace;
  pinned: boolean;
  /** @format date-time */
  deletedAt: string | null;
  tags: WorkflowTagItem[];
  outputTree: Record<string, any>;
  userFeedback?: UserFeedback;
}

export interface RecentWorkflowItem {
  id: number;
  /** @format date-time */
  accessedAt: string;
  workflow: WorkflowItem;
}

export interface RecentRecipeItem {
  id: number;
  /** @format date-time */
  accessedAt: string;
  recipe: RecipeItem;
}

export interface SDStyleOwner {
  uid: string;
  name: string;
  email: string;
  picture: string;
  username?: string;
}

export interface SDStyleTag {
  id: number;
  name: string;
}

export interface SDStyleWorkspace {
  id: string;
  name: string;
  picture?: string;
}

export interface StyleImage {
  url: string;
  weight: number;
}

export interface SDStyleRoleCapabilities {
  canView: boolean;
  canUpdate: boolean;
  canMove: boolean;
  canDelete: boolean;
  canRecover: boolean;
  canShare: boolean;
  canAddToLibrary?: boolean;
  canRemoveFromLibrary?: boolean;
}

export interface SDStyleDetailMetric {
  id: number;
  favoriteCount: number;
}

export interface SDStyleDetail {
  id: string;
  name?: string;
  thumbnailUrl: string | null;
  thumbnailWidth: number | null;
  thumbnailHeight: number | null;
  thumbnailModerationRating: ContentModerationRating;
  isDraft: boolean;
  description?: string;
  /** @format date-time */
  discoverableAt: string | null;
  /** @format date-time */
  deletedAt: string | null;
  sharingMode: ShareByWorkspaceMode;
  creator: SDStyleOwner;
  tags: SDStyleTag[];
  workspace: SDStyleWorkspace;
  workspaceId: string;
  images: StyleImage[];
  pinned: boolean;
  capabilities?: SDStyleRoleCapabilities;
  favoritedByUser: boolean;
  metric: SDStyleDetailMetric | null;
  /** @format date-time */
  createdAt: string;
}

export interface RecentStyleItem {
  id: number;
  /** @format date-time */
  accessedAt: string;
  style: SDStyleDetail;
}

export interface WildcardOwner {
  uid: string;
  name: string;
  email: string;
  picture: string;
  username?: string;
}

export interface WildcardWorkspace {
  id: string;
  name: string;
  picture?: string;
}

export interface WildcardRoleCapabilities {
  canView: boolean;
  canUpdate: boolean;
  canMove: boolean;
  canDelete: boolean;
  canRecover: boolean;
  canShare: boolean;
  canAddToLibrary?: boolean;
  canRemoveFromLibrary?: boolean;
}

export interface WildcardDetail {
  id: string;
  name?: string;
  wildcards: string[];
  /** @format date-time */
  deletedAt: string | null;
  sharingMode: ShareByWorkspaceMode;
  creator: WildcardOwner;
  workspace: WildcardWorkspace;
  workspaceId: string;
  capabilities?: WildcardRoleCapabilities;
  /** @format date-time */
  createdAt: string;
  pinned: boolean;
  isPublic: boolean;
}

export interface RecentWildcardItem {
  id: number;
  /** @format date-time */
  accessedAt: string;
  wildcard: WildcardDetail;
}

export interface SDWorkflowOwner {
  uid: string;
  name: string;
  email: string;
  picture: string;
  username?: string;
}

export interface SDWorkflowRoleCapabilities {
  canView: boolean;
  canUpdate: boolean;
  canMove: boolean;
  canDelete: boolean;
  canRecover: boolean;
  canShare: boolean;
  canOverridePublishedRecipe?: boolean;
}

export interface SDWorkflowWorkspace {
  id: string;
  name: string;
  picture?: string;
}

export interface SDWorkflowMetricItem {
  id: number;
  favoriteCount: number;
}

export interface SDWorkflowDetail {
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string;
  description?: string;
  thumbnailFileId: string | null;
  thumbnailModerationRating: string;
  thumbnailUrl: string;
  workflow: Record<string, any>;
  prompt: Record<string, any>;
  steps: RecipeStep[];
  isDraft: boolean;
  workspaceId: string;
  sharingMode: ShareByWorkspaceMode;
  /** @format date-time */
  discoverableAt: string;
  /** @format date-time */
  deletedAt: string;
  isLatest: boolean;
  lastRecipeId: string;
  creator: SDWorkflowOwner;
  capabilities?: SDWorkflowRoleCapabilities;
  workspace: SDWorkflowWorkspace;
  tags?: Tag[];
  pinned?: boolean;
  favoritedByUser?: boolean;
  metric?: SDWorkflowMetricItem;
  userFeedback?: UserFeedback;
}

export interface RecentSDWorkflowItem {
  id: number;
  /** @format date-time */
  accessedAt: string;
  sdWorkflow: SDWorkflowDetail;
}

export interface RecentModelItem {
  id: number;
  /** @format date-time */
  accessedAt: string;
  model: SDModelItem;
}

export interface RecentAccessItem {
  id: number;
  /** @format date-time */
  accessedAt: string;
  workflow?: WorkflowItem;
  recipe?: RecipeItem;
  image?: ImageSimpleDetail;
  folder?: FolderDetail;
  folderView?: SubfolderView;
  style?: SDStyleDetail;
  wildcard?: WildcardDetail;
}

export interface GlobalSearchFolderDetail {
  id: string;
  name: string;
  creator: FolderCreator;
}

export interface GlobalSearchFolderResult {
  total: number;
  folders: GlobalSearchFolderDetail[];
}

export interface GlobalSearchImageResult {
  total: number;
  images: ImageSimpleDetail[];
}

export interface GlobalSearchResult {
  folderResult: GlobalSearchFolderResult;
  imageResult: GlobalSearchImageResult;
}

export enum InspirationBoardSearchEntity {
  ALL = 'ALL',
  IMAGE = 'IMAGE',
  ARTICLE = 'ARTICLE',
  WORKFLOW = 'WORKFLOW',
  CREATOR_PROFILE = 'CREATOR_PROFILE',
  STYLE = 'STYLE',
  RECIPE = 'RECIPE',
  SD_WORKFLOW = 'SD_WORKFLOW',
}

export enum ArticleStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export enum ArticleCategory {
  WORKFLOW = 'WORKFLOW',
  NEWS = 'NEWS',
  TRAININGGUIDE = 'TRAINING GUIDE',
  CASESTUDY = 'CASE STUDY',
  ANNOUNCEMENT = 'ANNOUNCEMENT',
}

export interface ArticleMetric {
  createdAt: string;
  updatedAt: string;
  id: number;
  articleId: string;
  likeCount: number;
  dislikeCount: number;
  cryCount: number;
  laughCount: number;
  heartCount: number;
  totalReaction: number;
  commentCount: number;
  viewCount: number;
  favoriteCount: number;
  shareCount: number;
}

export interface ArticleFile {
  fileId: string;
  articleId: string;
  /** @default false */
  isCoverImage?: boolean;
}

export interface ArticleReaction {
  createdAt: string;
  updatedAt: string;
  id: number;
  reaction: Reaction;
  articleId: string;
  userUid: string;
}

export interface ArticleDetail {
  createdAt: string;
  updatedAt: string;
  id: string;
  title: string;
  description: string;
  coverImageY: string;
  status: ArticleStatus;
  category: ArticleCategory;
  creatorUid: string;
  coverImageUrl: string | null;
  metric: ArticleMetric;
  creator: User;
  articleFile: ArticleFile[] | null;
  tags: Tag[] | null;
  userReactions?: ArticleReaction[];
  favoritedByUser?: boolean;
}

export interface SearchInspirationBoardArticleResult {
  total: number;
  articles: ArticleDetail[];
}

export interface ExploreWorkflowItem {
  id: string;
  name: string;
  favoritedByUser: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  creator: WorkflowCreator;
  metric: WorkflowItemMetric | null;
  image: WorkflowImage | null;
  /** @format date-time */
  discoverableAt: string | null;
}

export interface GetExploreWorkflowsResult {
  workflows: ExploreWorkflowItem[];
  total: number;
}

export interface ExploreSDStyleOwner {
  uid: string;
  name: string;
  picture: string;
  username?: string;
}

export interface ExploreSDStyleDetailTag {
  id: number;
  name: string;
}

export interface ExploreSDStyleCapabilities {
  canAddToLibrary?: boolean;
  canRemoveFromLibrary?: boolean;
}

export interface ExploreSDStyleDetail {
  id: string;
  name: string;
  thumbnailUrl: string | null;
  thumbnailWidth: number | null;
  thumbnailHeight: number | null;
  thumbnailModerationRating: ContentModerationRating;
  isDraft: boolean;
  description: string;
  /** @format date-time */
  discoverableAt: string | null;
  /** @format date-time */
  deletedAt: string | null;
  creator: ExploreSDStyleOwner;
  tags: ExploreSDStyleDetailTag[];
  images: StyleImage[];
  favoritedByUser?: boolean;
  metric: SDStyleDetailMetric | null;
  capabilities: ExploreSDStyleCapabilities;
}

export interface GetExploreSDStylesResponse {
  styles: ExploreSDStyleDetail[];
  total: number;
}

export interface ProfileMetric {
  id: number;
  favoriteCount: number;
  downloadCount: number;
  followerCount: number;
  followingCount: number;
}

export interface BasicFollowerInfo {
  name: string;
  picture: string;
}

export interface FollowerInfo {
  followers: BasicFollowerInfo[];
  otherCount: number;
}

export interface CreatorProfileItem {
  uid: string;
  name: string;
  username: string;
  picture: string;
  isPrivate: boolean;
  bio: string;
  metric: ProfileMetric | null;
  coverImageUrl: string | null;
  coverImageY: string;
  isFollowed: boolean;
  followerInfo: FollowerInfo;
}

export interface GetCreatorProfilesResult {
  profiles: CreatorProfileItem[];
  total: number;
}

export interface ExploreRecipeCapabilities {
  canAddToLibrary?: boolean;
  canRemoveFromLibrary?: boolean;
}

export interface ExploreRecipeItemTag {
  id: number;
  name: string;
}

export interface ExploreRecipeItem {
  id: string;
  name: string;
  description: string;
  thumbnail: string;
  thumbnailModerationRating: ContentModerationRating;
  type: RecipeType;
  isPublic: boolean;
  /** @format date-time */
  discoverableAt: string | null;
  workspaceId: string;
  createdAt: string;
  updatedAt: string;
  creator: RecipeCreator | null;
  capabilities: ExploreRecipeCapabilities;
  tags: ExploreRecipeItemTag[];
  metric: RecipeItemMetric | null;
  favoritedByUser: boolean;
}

export interface GetExploreRecipesResult {
  recipes: ExploreRecipeItem[];
  total: number;
}

export interface ExploreSDWorkflowMetric {
  id: number;
  favoriteCount: number;
}

export interface ExploreSDWorkflowItem {
  id: string;
  name: string;
  description: string;
  thumbnailUrl: string;
  thumbnailModerationRating: ContentModerationRating;
  creator: SDWorkflowOwner;
  /** @format date-time */
  discoverableAt: string;
  /** @format date-time */
  createdAt: string;
  favoritedByUser: boolean;
  metric: ExploreSDWorkflowMetric;
}

export interface GetExploreSDWorkflowsResult {
  total: number;
  sdWorkflows: ExploreSDWorkflowItem[];
}

export interface SearchInspirationBoardResult {
  imageResult: GlobalSearchImageResult;
  articleResult: SearchInspirationBoardArticleResult;
  workflowResult: GetExploreWorkflowsResult;
  styleResult: GetExploreSDStylesResponse;
  creatorProfileResult: GetCreatorProfilesResult;
  recipeResult: GetExploreRecipesResult;
  sdWorkflowResult: GetExploreSDWorkflowsResult;
}

export interface Metadata {
  key: string;
  value: string;
}

export interface AlphaImageDetail {
  createdAt: string;
  updatedAt: string;
  id: string;
  name: string | null;
  width: number;
  height: number;
  blurHash: string | null;
  /** @example {} */
  owner: User;
  recipeTaskId?: string;
  recipeId?: string;
  downloadCount: number;
  /** @default "" */
  fullMetadata?: string;
  url: string;
  metadata: Metadata[];
}

export enum CommentsSortBy {
  NEWEST = 'NEWEST',
  MOSTRELEVANT = 'MOST RELEVANT',
}

export interface AddReferralDto {
  referrerUid: string;
  referreeUid: string;
}

export interface Referral {
  referrerUid: string;
  referreeUid: string;
  /** @format date-time */
  createdAt: string;
}

export enum SharingPlatform {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  LINKEDIN = 'LINKEDIN',
  LINK_COPY = 'LINK_COPY',
}

export interface IncreaseGenerationBySharingDto {
  platform: SharingPlatform;
  imageId: string;
}

export interface Feedback {
  createdAt: string;
  updatedAt: string;
  id: string;
  response: string;
  style: string;
  recipeTaskId: string;
  userUid: string;
}

export enum FeedbackResponse {
  HAPPY = 'HAPPY',
  NEUTRAL = 'NEUTRAL',
  SAD = 'SAD',
}

export interface RateDto {
  recipeTaskId: string;
  style: string;
  response: FeedbackResponse;
}

/** @default "NONE" */
export enum FileAssociatedResource {
  USER_AVATAR = 'USER_AVATAR',
  USER_COVER_IMAGE = 'USER_COVER_IMAGE',
  WORKSPACE = 'WORKSPACE',
  ARTICLE_COVER_IMAGE = 'ARTICLE_COVER_IMAGE',
  ARTICLE_FILE = 'ARTICLE_FILE',
  STYLE = 'STYLE',
  SD_WORKFLOW = 'SD_WORKFLOW',
  CHAT_ROOM_THUMBNAIL = 'CHAT_ROOM_THUMBNAIL',
  SD_MODEL = 'SD_MODEL',
  SD_MODEL_TRAINING = 'SD_MODEL_TRAINING',
  PROMPT_LIBRARY = 'PROMPT_LIBRARY',
  NONE = 'NONE',
}

export interface InitializeMultipartUploadDto {
  files: UploadFileInfo[];
  associatedResource: FileAssociatedResource;
  chunkSize?: number;
}

export interface UploadFile {
  id: string;
  name: string;
  size: number;
  mimetype: string;
  metadata: object;
  url: string | null;
  ownerUid: string;
  /** @format date-time */
  createdAt: string;
  uploaded: boolean;
}

export interface InitializeMultipartUploadResult {
  key: string;
  filename: string;
  uploadId: string;
  uploadUrls: string[];
  file: UploadFile;
}

export interface UpdateSettingDto {
  value: any;
}

export interface QueuePromptDto {
  prompt: Record<string, any>;
  number: number;
}

export interface UploadImageResponse {
  url: string;
  filename: string;
  subfolder: string;
}

export interface ComfyRoomConnectedUser {
  uid: string;
  clientId: string;
  name: string;
  picture: string;
}

export interface GetArticlesResult {
  articles: ArticleDetail[];
  total: number;
}

export interface CreateArticleDto {
  title: string;
  description: string;
  status: ArticleStatus;
  category: ArticleCategory;
  tagIds?: number[];
  coverImageId?: string;
  coverImageY?: string;
  imageIds?: string[];
}

export interface Article {
  createdAt: string;
  updatedAt: string;
  id: string;
  title: string;
  description: string;
  coverImageY: string;
  status: ArticleStatus;
  category: ArticleCategory;
  creatorUid: string;
}

export interface UpdateArticleDto {
  title?: string;
  description?: string;
  status?: ArticleStatus;
  category?: ArticleCategory;
  tagIds?: number[];
  coverImageId?: string;
  coverImageY?: string;
  imageIds?: string[];
}

export interface ToggleArticleReactionDto {
  reaction: Reaction;
}

export interface CreateArticleCommentDto {
  value: string;
  formatValue: string | null;
  uidMentions: string[] | null;
  parentCommentId?: string | null;
  x?: string;
  y?: string;
  nodeId?: number;
}

export interface IncreaseArticlesShareCountDto {
  articleIds: number[];
}

export interface ArticlePreviewInfo {
  id: string;
  title: string;
  coverImageUrl: string | null;
}

export interface AlphaSubscription {
  id: number;
  userUid: string;
  /** @format date-time */
  createdAt: string;
}

export interface ArtboardDetail {
  createdAt: string;
  updatedAt: string;
  id: string;
  title: string;
  isLocked: boolean;
  creatorUid: string;
  projectId: string;
  resultImageId: string;
  resultImageUrl: string | null;
  isFavorite: boolean;
}

export interface CreateArtboardDto {
  title: string;
  projectId: string;
}

export interface Artboard {
  createdAt: string;
  updatedAt: string;
  id: string;
  title: string;
  isLocked: boolean;
  creatorUid: string;
  projectId: string;
  resultImageId: string;
}

export interface UpdateArtboardDto {
  title?: string;
  resultImageId?: string;
  isLocked?: boolean;
}

export interface CreateArtboardRecipeTasksDto {
  recipeTaskIds: string[];
}

export interface ArtboardRecipeTask {
  recipeTaskId: string;
  artboardId: string;
}

export interface GetArtboardCommentMentionableResponse {
  users: User[];
  total: number;
}

export interface CreateArtboardCommentDto {
  value: string;
  formatValue: string | null;
  uidMentions: string[] | null;
  parentCommentId?: string | null;
  x?: string;
  y?: string;
  nodeId?: number;
}

export interface MissUniverseContestantDetail {
  id: number;
  image: ExploreImageFullDetail;
  countryCode: string;
  voteCount: number;
  votedByUser: boolean;
}

export interface GetMissUniverseContestantsResult {
  contestants: MissUniverseContestantDetail[];
  total: number;
}

export interface ImageVote {
  createdAt: string;
  updatedAt: string;
  id: number;
  missUniverseContestantId: number;
  userUid: string;
}

export interface NationVoteDetail {
  countryCode: string;
  voteCount: number;
}

export interface CreateContestantDto {
  imageId: string;
}

export interface MissUniverseContestant {
  createdAt: string;
  updatedAt: string;
  id: number;
  imageId: string;
  countryCode: string;
}

export enum SDStyleFilterMode {
  Owned = 'owned',
  Shared = 'shared',
  Draft = 'draft',
  Trash = 'trash',
}

export enum SDStyleSortOption {
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST',
  A_TO_Z = 'A_TO_Z',
  Z_TO_A = 'Z_TO_A',
}

export interface SearchSDStyleDto {
  /** @default 0 */
  skip?: number;
  /** @default 10 */
  take?: number;
  searchTerm?: string;
  ids?: string[];
  mode?: SDStyleFilterMode;
  sortBy?: SDStyleSortOption;
  onlyDiscoverable?: boolean;
  onlyShared?: boolean;
  isPinned?: boolean;
}

export interface SearchSDStyleResponse {
  data: SDStyleDetail[];
  total: number;
}

export interface SDStyleTagsResponse {
  tags: string[];
}

export interface UpdateSDStyleDto {
  name?: string;
  thumbnailId?: string | null;
  thumbnailWidth?: number;
  thumbnailHeight?: number;
  images: StyleImage[];
  isDraft?: boolean;
  tags?: string[];
  description?: string;
}

export interface RenameSDStyleDto {
  name?: string;
}

export interface SDStyle {
  createdAt: string;
  updatedAt: string;
  id: string;
  name?: string;
  thumbnailUrl: string;
  thumbnailWidth: number;
  thumbnailHeight: number;
  thumbnailModerationRating: string;
  images: StyleImage[];
  isDraft: boolean;
  workspaceId: string;
  sharingMode: ShareByWorkspaceMode;
  /** @format date-time */
  discoverableAt: string;
  /** @format date-time */
  deletedAt: string;
  tags?: string[];
  description?: string;
}

export interface AddSDStyleImagesDto {
  fileIds: string[];
  weights: number[];
}

export interface SDStylePermission {
  id: number;
  user: User | null;
  workspace: Workspace | null;
  role: ResourceRole;
  isGuest?: boolean;
}

export interface AddStylesToLibraryDto {
  sdStyleIds: string[];
}

export interface AddStylesToLibraryResult {
  sdStyleIds: string[];
}

export interface RemoveStylesFromLibraryDto {
  sdStyleIds: string[];
}

export interface RemoveStylesFromLibraryResult {
  sdStyleIds: string[];
}

export interface TagSDStyleDto {
  sdStyleIds: string[];
  tagIds: number[];
}

export interface UntagSDStyleDto {
  sdStyleIds: string[];
  tagIds: number[];
}

export interface DeleteSDStylesDto {
  styleIds: string[];
}

export interface RecoverDeletedSDStylesDto {
  styleIds: string[];
}

export interface RecoverSDStylesResult {
  recovered: number;
}

export interface UpdateSDStyleDiscoveryDto {
  styleIds: string[];
  isDiscoverable: boolean;
}

export interface CreateSDStyleCommentDto {
  value: string;
  formatValue: string | null;
  uidMentions: string[] | null;
  parentCommentId?: string | null;
  x?: string;
  y?: string;
  nodeId?: number;
}

export enum SDWorkflowFilterMode {
  Owned = 'owned',
  Shared = 'shared',
  Draft = 'draft',
  Trash = 'trash',
}

export enum SDWorkflowSortOption {
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST',
  A_TO_Z = 'A_TO_Z',
  Z_TO_A = 'Z_TO_A',
}

export interface SearchSDWorkflowDto {
  /** @default 0 */
  skip?: number;
  /** @default 10 */
  take?: number;
  searchTerm?: string;
  ids?: string[];
  mode?: SDWorkflowFilterMode;
  sortBy?: SDWorkflowSortOption;
  includeDetails?: boolean;
  onlyDiscoverable?: boolean;
  onlyShared?: boolean;
  isPinned?: boolean;
}

export interface SearchSDWorkflowResponse {
  workflows: SDWorkflowDetail[];
  total: number;
}

export interface UpdateSDWorkflowDto {
  name?: string;
  description?: string;
  workflow?: Record<string, any>;
  prompt?: Record<string, any>;
  thumbnailFileId?: string | null;
  steps?: RecipeStep[];
  isDraft?: boolean;
}

export interface RenameSDWorkflowDto {
  name?: string;
}

export interface DeleteSDWorkflowsDto {
  workflowIds: string[];
}

export interface RecoverDeletedSDWorkflowsDto {
  workflowIds: string[];
}

export interface RecoverSDWorkflowsResult {
  recovered: number;
}

export interface CreateComfyUIRecipeTaskDto {
  recipeId: string;
  params: Record<string, any>;
  folderId?: string;
  name?: string;
  status?: RecipeTaskStatus;
  basedRecipeTaskId?: string;
  chatRoomId?: string;
  imageId?: string;
  sdWorkflowId: string | null;
}

export interface PublishRecipeDto {
  name: string;
  description: string;
  thumbnailUrl: string;
  steps: RecipeStep[];
  override: boolean;
  categoryIds?: number[];
}

export interface SDWorkflowPermission {
  id: number;
  user: User | null;
  workspace: Workspace | null;
  role: ResourceRole;
}

export interface UpdateSDWorkflowDiscoveryDto {
  workflowIds: string[];
  isDiscoverable: boolean;
}

export interface CreateSDWorkflowCommentDto {
  value: string;
  formatValue: string | null;
  uidMentions: string[] | null;
  parentCommentId?: string | null;
  nodeId: number;
}

export interface TagSDWorkflowDto {
  sdWorkflowIds: string[];
  tagIds: number[];
}

export interface UntagSDWorkflowDto {
  sdWorkflowIds: string[];
  tagIds: number[];
}

export enum WorkflowViewOptions {
  MY_IMAGES = 'MY_IMAGES',
  SHARED_WITH_ME = 'SHARED_WITH_ME',
  TRASH = 'TRASH',
}

export enum WorkflowStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  DRAFT = 'DRAFT',
  RUNNING = 'RUNNING',
  QUEUED = 'QUEUED',
}

export enum WorkflowSortOptions {
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST',
  NEWEST_DELETED = 'NEWEST_DELETED',
}

export interface GetWorkflowsResult {
  workflows: WorkflowItem[];
  total: number;
}

export interface WorkflowFolder {
  id: string;
}

export interface WorkflowDetail {
  id: string;
  name: string;
  status: RecipeTaskStatus;
  favoritedByUser: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  creator: WorkflowCreator;
  capabilities: RoleCapabilities;
  metric: WorkflowItemMetric | null;
  image: WorkflowImage | null;
  /** @format date-time */
  discoverableAt: string | null;
  workspace?: WorkflowWorkspace;
  pinned: boolean;
  /** @format date-time */
  deletedAt: string | null;
  tags: WorkflowTagItem[];
  outputTree: Record<string, any>;
  userFeedback?: UserFeedback;
  params: Record<string, any>[];
  fullDataImages: ImageSimpleDetail[];
  folder: WorkflowFolder;
  description: string;
}

export interface UpdateWorkflowByIdDto {
  name?: string;
}

export interface PermissionUserItem {
  uid: string;
  name: string;
  email: string;
  picture: string;
}

export interface PermissionWorkspaceItem {
  id: string;
  name: string;
  photoUrl: string;
}

export interface WorkflowPermissionDetail {
  id: number;
  user: PermissionUserItem | null;
  workspace: PermissionWorkspaceItem | null;
  role: ResourceRole;
  isGuest?: boolean;
}

export interface GetWorkflowPermissionResult {
  permissions: WorkflowPermissionDetail[];
  sharingMode: ShareByWorkspaceMode;
  exploreAccessRole: ResourceRole;
  /** @format date-time */
  discoverableAt: string;
}

export interface ShareWorkflowDto {
  shareUsers: ShareUser[];
}

export interface UpdateWorkflowPermissionDto {
  role: SharingRole;
}

export interface UpdateWorkflowsDiscoveryDto {
  workflowIds: string[];
  isDiscoverable: boolean;
  role: SharingRole;
}

export interface TagWorkflowsDto {
  workflowIds: string[];
  tagIds: number[];
}

export interface UnTagWorkflowsDto {
  workflowIds: string[];
  tagIds: number[];
}

export interface CreateWorkflowCommentDto {
  value: string;
  formatValue: string | null;
  uidMentions: string[] | null;
  parentCommentId?: string | null;
  x?: string;
  y?: string;
  nodeId?: number;
}

export interface ExploreWorkflowTagItem {
  id: number;
  name: string;
}

export interface ExploreWorkflowDetail {
  id: string;
  name: string;
  favoritedByUser: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  creator: WorkflowCreator;
  metric: WorkflowItemMetric | null;
  image: WorkflowImage | null;
  /** @format date-time */
  discoverableAt: string | null;
  params: Record<string, any>[];
  fullDataImages: ExploreImageSimpleDetail[];
  exploreAccessRole: ResourceRole;
  tags: ExploreWorkflowTagItem[];
  description: string;
  outputTree: Record<string, any>;
}

export enum PinnedItemType {
  IMAGE = 'IMAGE',
  RECIPE = 'RECIPE',
  FOLDER = 'FOLDER',
  WORKFLOW = 'WORKFLOW',
  STYLE = 'STYLE',
  WILDCARD = 'WILDCARD',
  SD_WORKFLOW = 'SD_WORKFLOW',
  MODEL = 'MODEL',
}

export interface PinnedItems {
  id: number;
  /** @format date-time */
  createdAt: string;
  folder?: FolderDetail;
  folderView?: SubfolderView;
  image?: ImageSimpleDetail;
  recipe?: RecipeItem;
  workflow?: WorkflowItem;
  style?: SDStyleDetail;
  wildcard?: WildcardDetail;
  sdWorkflow?: SDWorkflowDetail;
  sdModel?: SDModelItem;
}

export interface PinItemsDto {
  itemIds: string[];
  type: PinnedItemType;
}

export interface UnpinItemsDto {
  itemIds: string[];
  type: PinnedItemType;
}

export enum CreatorProfileSortOptions {
  MOST_FAVORITED = 'MOST_FAVORITED',
  A_TO_Z = 'A_TO_Z',
  Z_TO_A = 'Z_TO_A',
  NEWEST = 'NEWEST',
}

export interface RequestAccessDto {
  entityId: string;
  entityType: EntityType;
}

export enum TurboSessionStatus {
  Pending = 'pending',
  Active = 'active',
  Closed = 'closed',
  Timeout = 'timeout',
}

export interface TurboSession {
  createdAt: string;
  updatedAt: string;
  id: string;
  status: TurboSessionStatus;
  creatorUid: string;
  connectionId: string;
  activatedAt?: string;
  closedAt?: string;
}

export interface CreateTurboTaskDto {
  recipeId: string;
  params: Record<string, any>;
  folderId?: string;
  name?: string;
  status?: RecipeTaskStatus;
  basedRecipeTaskId?: string;
  chatRoomId?: string;
  imageId?: string;
  sessionId: string;
  saveTask: boolean;
  sdWorkflowId?: string;
}

export enum PreviewEntityType {
  IMAGE = 'IMAGE',
  FOLDER = 'FOLDER',
  ARTICLE = 'ARTICLE',
  STYLE = 'STYLE',
  WORKFLOW = 'WORKFLOW',
  WILDCARD = 'WILDCARD',
  RECIPE = 'RECIPE',
  SD_WORKFLOW = 'SD_WORKFLOW',
  CHAT_ROOM = 'CHAT_ROOM',
  SD_MODEL = 'SD_MODEL',
}

export interface PreviewInfo {
  id: string;
  name: string;
  imageUrl?: string;
  creatorName?: string;
  description?: string;
}

export interface SolanaWallet {
  createdAt: string;
  updatedAt: string;
  id: string;
  name?: string;
  provider?: string;
}

export interface GetOwnedWalletsResponse {
  wallets: SolanaWallet[];
}

export interface ConnectWalletRequest {
  address: string;
}

export interface ConnectWalletResponse {
  address: string;
  message: string;
  timestamp: string;
}

export interface ConfirmWalletRequest {
  address: string;
  signature: string;
  timestamp: string;
  provider?: string;
}

export interface ConfirmWalletResponse {
  success: boolean;
}

export interface FollowerDetail {
  uid: string;
  name: string;
  username: string;
  picture: string;
  isFollowed: boolean;
}

export interface GetUserFollowersResult {
  followers: FollowerDetail[];
  total: number;
}

export interface GetUserFollowingsResult {
  followers: FollowerDetail[];
  total: number;
}

export interface ToggleFollowUserDto {
  followingUid: string;
}

export interface RequestRefundDto {
  invoiceId: string;
  description: string;
}

export interface CreditPackageItem {
  id: string;
  name: string;
  usdValue: string;
  creditAmount: string;
  initialAmount: string;
  bonusPercentage: string;
}

export enum ProductType {
  Credit = 'credit',
  Subscription = 'subscription',
  SubscriptionSeat = 'subscription-seat',
  ChangePaymentMethod = 'change-payment-method',
}

export enum CryptoType {
  USDC = 'USDC',
  USDCE = 'USDC.e',
  USDT = 'USDT',
  ETH = 'ETH',
  WETH = 'WETH',
  SIPHER = 'SIPHER',
  MATIC = 'MATIC',
}

export enum Chain {
  Value1 = 1,
  Value80002 = 80002,
  Value137 = 137,
  Value11155111 = 11155111,
  Value11155420 = 11155420,
  Value10 = 10,
  Value3397901 = 3397901,
  Value33979 = 33979,
}

export enum SubscriptionType {
  Renew = 'renew',
  Upgrade = 'upgrade',
  Downgrade = 'downgrade',
  AddSeat = 'add-seat',
}

export enum BillingCycle {
  Monthly = 'monthly',
  BiYearly = 'bi-yearly',
  Yearly = 'yearly',
}

export interface PreCryptoTransactionRequest {
  address: string;
  productType: ProductType;
  productId: string;
  currency: CryptoType;
  chain: Chain;
  workspaceId?: string;
  subscriptionType?: SubscriptionType;
  billingCycle?: BillingCycle;
  seatCount?: number;
}

export interface PreCryptoTransactionResponse {
  vaultAddress: string;
  valueUSD: string;
  value: string;
  tokenAddress: string;
  preTransactionId: string;
  symbol: string;
  decimal: string;
}

export interface PurchasePackageByCryptoDto {
  productType: ProductType;
  productId: string;
  currency: CryptoType;
  address: string;
  sendAtBlock: string;
  chain: Chain;
  txHash: string;
  preTransactionId: string;
  workspaceId?: string;
  subscriptionType?: SubscriptionType;
  billingCycle?: BillingCycle;
  seatCount?: number;
  addSeatEmails?: string[];
}

/** @default "VND" */
export enum CurrencyCode {
  USD = 'USD',
  VND = 'VND',
}

export interface PurchaseCreditPackageDto {
  creditPackageId: string;
  currencyCode: CurrencyCode;
  frontendReturnUrl?: string;
  /** @default "XSOLLA" */
  paymentMethod?: string;
}

export interface PurchaseCreditPackageResult {
  url: string;
  clientSecret?: string;
}

export enum SubscriptionGroup {
  Regular = 'regular',
  ModelStorage = 'model_storage',
}

export interface SubscriptionPackageItem {
  id: string;
  name: string;
  monthlyCost: string;
  yearlyCost: string;
  biYearlyCost: string;
  maxMembers: number;
  cloudStorage: string;
  maxTasksInQueue: number;
  extraCredits: number;
  privateOutput: boolean;
  supportBackup: boolean;
  proAllRecipes: boolean;
  maxModelStorage: number;
  group: string;
}

export interface SubscribeDto {
  workspaceId: string;
  subscriptionPackageId: string;
  subscriptionType: SubscriptionType;
  currencyCode: CurrencyCode;
  billingCycle: BillingCycle;
  seatCount?: number;
  frontendReturnUrl?: string;
  /** @default "XSOLLA" */
  paymentMethod?: string;
}

export interface PurchaseSubscriptionPackageResult {
  url: string;
  clientSecret?: string;
}

export interface CancelSubscriptionDto {
  workspaceId: string;
  subscriptionPackageId: string;
}

export interface CalculateSubscriptionAmountResult {
  amount: string;
  amountPerSeat?: string;
  seatCount?: number;
  currencyCode: string;
}

export interface DisableAutoRenewalDto {
  workspaceId: string;
}

export interface UpdatePaymentMethodDto {
  paymentMethod: PaymentMethod;
}

export interface UpdatePaymentMethodResult {
  url: string;
  clientSecret?: string;
}

export interface CreateEnterpriseContactDto {
  name: string;
  companyName: string;
  goal: string;
  challenge: string;
  comChannel: string;
}

export enum WildcardFilterMode {
  Owned = 'owned',
  Shared = 'shared',
  Trash = 'trash',
}

export enum WildcardSortOption {
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST',
  A_TO_Z = 'A_TO_Z',
  Z_TO_A = 'Z_TO_A',
}

export interface SearchWildcardDto {
  /** @default 0 */
  skip?: number;
  /** @default 10 */
  take?: number;
  searchTerm?: string;
  ids?: string[];
  mode?: WildcardFilterMode;
  sortBy?: WildcardSortOption;
  /** @default false */
  includePublic?: boolean;
  /** @default false */
  isPinned?: boolean;
}

export interface SearchWildcardResponse {
  wildcards: WildcardDetail[];
  total: number;
}

export interface UpdateWildcardDto {
  name?: string;
  wildcards?: string[];
  /** @default "merge" */
  mode?: string;
}

export interface CreateWildcardDto {
  list: UpdateWildcardDto[];
}

export interface Wildcard {
  createdAt: string;
  updatedAt: string;
  id: string;
  name?: string;
  wildcards?: string[];
  workspaceId: string;
  sharingMode: ShareByWorkspaceMode;
  /** @format date-time */
  deletedAt: string;
}

export interface WildcardPermission {
  id: number;
  user: User | null;
  workspace: Workspace | null;
  role: ResourceRole;
}

export interface AddWildcardsToLibraryDto {
  wildcardIds: string[];
}

export interface AddWildcardsToLibraryResult {
  wildcardIds: string[];
}

export interface RemoveWildcardsFromLibraryDto {
  wildcardIds: string[];
}

export interface RemoveWildcardsFromLibraryResult {
  wildcardIds: string[];
}

export interface DeleteWildcardsDto {
  wildcardIds: string[];
}

export interface InvoiceItem {
  id: string;
  supplier: object;
  customer: object;
  payment: Record<string, any>;
  product: object;
}

export interface OrderItem {
  id: string;
  productDetail: Record<string, any>;
  productQuantity: number;
  paymentMethod: PaymentMethod;
  totalAmount: string;
  /** @format date-time */
  createdAt: string;
  invoice: InvoiceItem | null;
}

export interface GetOrderHistoriesResponse {
  orders: OrderItem[];
  total: number;
}

export enum CreatedByOptions {
  Gaia = 'gaia',
  Community = 'community',
}

export interface CreateRecipeCommentDto {
  value: string;
  formatValue: string | null;
  uidMentions: string[] | null;
  parentCommentId?: string | null;
}

export interface SDWorkflowTagItem {
  id: number;
  name: string;
}

export interface ExploreSDWorkflowDetail {
  id: string;
  name: string;
  description: string;
  thumbnailUrl: string;
  thumbnailModerationRating: ContentModerationRating;
  creator: SDWorkflowOwner;
  /** @format date-time */
  discoverableAt: string;
  /** @format date-time */
  createdAt: string;
  favoritedByUser: boolean;
  metric: ExploreSDWorkflowMetric;
  steps: Record<string, any>;
  tags: SDWorkflowTagItem[];
}

export enum BackupRequestStatus {
  Processing = 'processing',
  Available = 'available',
  Failed = 'failed',
}

export interface BackupItem {
  id: number;
  downloadUrl: string | null;
  /** @format date-time */
  expireAt: string | null;
  status: BackupRequestStatus;
}

export interface CreateFeedbackDto {
  entityType: EntityType;
  entityId: string;
  rating: FeedbackRating;
  feedback: string;
}

export interface UpdateFeedbackDto {
  entityType: EntityType;
  rating: FeedbackRating;
}

export interface LeaderboardUserInfo {
  uid: string;
  name: string;
  picture: string;
  followerCount: number;
  username?: string;
}

export interface UserRanking {
  id: number;
  rank: number;
  score: number;
  metric: Record<string, any>;
  user: LeaderboardUserInfo;
}

export interface LeaderboardItem {
  id: string;
  name: string;
  description: string;
  ranking: UserRanking[];
  /** @format date-time */
  latestSnapshotTimestamp: string;
}

export interface HideExploreImagesDto {
  imageIds: string[];
  isHidden: boolean;
}

export interface GetInspirationBoardImagesQuery {
  /** @default 0 */
  skip?: number;
  /** @default 10 */
  take?: number;
  searchTerm?: string;
  onlyMetadata?: boolean;
  onlyMine?: boolean;
  onlyFavorite?: boolean;
  onlyCurrentWorkspace?: boolean;
  generatedBy?: FilterGeneratedByOptions;
  tagIds?: number[];
  sortBy?: SortOptions;
  creatorUid?: string;
  sdWorkflowId?: string;
  recipeIds?: string[];
  smart?: boolean;
}

export enum ModerationUserSortOptions {
  CreatedTime = 'created-time',
  PointBalance = 'point-balance',
  Username = 'username',
  Name = 'name',
}

export interface WorkspaceBalance {
  id: string;
  name: string;
  availableCredits: string;
  subscriptionCredits: string;
}

export interface ModerationUserItem {
  uid: string;
  name: string;
  username: string;
  email: string;
  pointBalance: string;
  /** @format date-time */
  createdAt: string;
  picture?: string;
  workspaceBalances?: WorkspaceBalance[];
}

export interface ModerationGetUsersResult {
  users: ModerationUserItem[];
  total: number;
}

export enum GaiaEarlyAccessSortOptions {
  CreatedTime = 'created-time',
}

export interface GaiaEarlyAccessItem {
  id: number;
  email: string;
  telegramId?: string;
  /** @format date-time */
  createdAt: string;
}

export interface GetGaiaEarlyAccessResult {
  submissions: GaiaEarlyAccessItem[];
  total: number;
}

export enum TransactionStatus {
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
  Cancel = 'cancel',
  Expired = 'expired',
  Refunded = 'refunded',
}

export enum TransactionSortByOptions {
  CreatedAt = 'createdAt',
  UserEmail = 'userEmail',
  Amount = 'amount',
  Quantity = 'quantity',
}

export interface UserBasicInfo {
  uid: string;
  name: string;
  email: string;
  picture: string;
}

export interface ProductDetail {
  id: string;
  name: string;
}

export enum ProductionType {
  Credit = 'credit',
  Subscription = 'subscription',
  SubscriptionSeat = 'subscription-seat',
  ChangePaymentMethod = 'change-payment-method',
}

export interface TransactionItem {
  id: string;
  paymentMethod: PaymentMethod;
  amount: string;
  usdAmount: string;
  status: TransactionStatus;
  user?: UserBasicInfo;
  quantity: number;
  billingCycle: BillingCycle | null;
  subscriptionType: SubscriptionType | null;
  productDetail: ProductDetail;
  productType: ProductionType;
  createdAt: string;
  paymentChannelCode?: string;
  chain: Chain | null;
  address?: string;
  vaultAddress?: string;
}

export interface GetTransactionsResult {
  transactions: TransactionItem[];
  total: number;
}

export interface GetTransactionsDto {
  /** @default 0 */
  skip?: number;
  /** @default 10 */
  take?: number;
  /** @format date-time */
  fromDate?: string;
  /** @format date-time */
  toDate?: string;
  productType?: ProductType;
  status?: TransactionStatus;
  paymentMethod?: PaymentMethod;
  sortOrder?: SortOrder;
  sortBy?: TransactionSortByOptions;
  searchTerm?: string;
}

export enum WhitelistEmailSortOptions {
  CreatedAt = 'createdAt',
}

export interface WhitelistEmail {
  email: string;
  hasSentWelcomeMail: boolean;
  /** @format date-time */
  createdAt: string;
}

export interface GetWhiteListEmailsResult {
  whitelistEmails: WhitelistEmail[];
  total: number;
}

export interface AddWhitelistUserDto {
  email: string;
  telegramId?: string;
}

export interface SendWhitelistEmailDto {
  emails: string[];
}

export interface EnterpriseContactItem {
  id: string;
  name: string;
  companyName: string;
  challenge: string;
  goal: string;
  comChannel: string;
  /** @format date-time */
  createdAt: string;
}

export interface GetEnterpriseContactsResult {
  contacts: EnterpriseContactItem[];
  total: number;
}

export interface TrackDto {
  entityType: EntityType;
  entityId: string;
}

export enum QuestStatus {
  Pending = 'pending',
  Completed = 'completed',
  Claimable = 'claimable',
  Locked = 'locked',
}

export enum QuestFrequency {
  OneTime = 'one-time',
  Repeatable = 'repeatable',
  Recurring = 'recurring',
}

export enum QuestType {
  DailyCheckin = 'daily-checkin',
  Referral = 'referral',
  Create = 'create',
  Share = 'share',
  Unknown = 'unknown',
}

export enum QuestTarget {
  Image = 'image',
  Macro = 'macro',
  SdWorkflow = 'sd-workflow',
  SdStyle = 'sd-style',
  Social = 'social',
  Explore = 'explore',
  None = 'none',
}

export interface QuestStreakMilestones {
  count: number;
  point: string;
}

export interface QuestItem {
  id: number;
  name: string;
  shortDescription: string;
  description: string;
  /** @format date-time */
  startAt: string;
  /** @format date-time */
  endAt: string;
  rewardPoint: string;
  frequency: QuestFrequency;
  status: QuestStatus;
  prerequisiteQuests?: QuestItem[];
  type: QuestType;
  target: QuestTarget;
  claimableCount: number;
  claimedCount: number;
  streakMilestones?: QuestStreakMilestones[];
  streak: number;
}

export interface GetUserQuestsResult {
  total: number;
  quests: QuestItem[];
}

export interface QuestSeasonItem {
  id: number;
  name: string;
  description: string;
  /** @format date-time */
  endAt: string;
}

export interface IncreaseSocialQuestCLaimableDto {
  imageId: string;
  platform: SharingPlatform;
}

export interface ReferredUser {
  uid: string;
  picture: string;
  name: string;
}

export interface GetUserReferralsResult {
  users: ReferredUser[];
  total: number;
}

export interface AddReferralV2Dto {
  refCode: string;
}

export enum PointAction {
  Increase = 'increase',
  Decrease = 'decrease',
}

export interface WowPointHistoryItem {
  id: number;
  amount: string;
  action: PointAction;
  metadata: Record<string, any>;
  /** @format date-time */
  createdAt: string;
}

export interface TipDto {
  amount: number;
  recipientUid: string;
  metadata?: Record<string, any>;
}

export interface RecipientInfo {
  uid: string;
  name: string;
}

export interface TipResult {
  amount: number;
  recipient: RecipientInfo;
}

export interface SimpleAgentCommand {
  prompt: string;
  data: CreateRecipeTaskDto;
}

export interface QuotedImageOwner {
  uid: string;
  name: string;
  picture: string;
}

export interface QuotedImageDto {
  url: string;
  owner: QuotedImageOwner;
}

export interface CreateChatMessageDto {
  content: string;
  formattedContent: string;
  roomId: string;
  quotedMessageId?: string;
  quotedRecipeTaskId?: string;
  mentionedUids?: string[];
  quotedImages?: QuotedImageDto[];
}

export interface UpdateChatMessageDto {
  messageId: string;
  content?: string;
  formattedContent?: string;
  quotedMessageId?: string;
  quotedRecipeTaskId?: string;
  mentionedUids?: string[];
}

export interface Sender {
  uid: string;
  name: string;
  picture: string;
}

export interface ChatMentionedUser {
  uid: string;
  name: string;
}

export interface RecipeTaskItem {
  id: string;
  name: string | null;
  seed: number;
  status: RecipeTaskStatus;
  params: Record<string, any>;
  creator: UserBasicInfo;
  outputType: RecipeOutputType;
  outputText: string | null;
  recipeId: string;
  recipeName?: string;
  recipeType: RecipeType;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  startedAt: string | null;
  fullDataImages: ImageSimpleDetail[];
  favoritedByUser?: boolean;
}

export enum MessageReactionType {
  Like = 'like',
  Cry = 'cry',
  Heart = 'heart',
  Laugh = 'laugh',
}

export interface MessageReaction {
  userUid: string;
  userName: string;
  type: MessageReactionType;
}

export interface QuotedImage {
  url: string;
  owner: UserBasicInfo;
}

export interface ChatMessage {
  id: string;
  roomId?: string;
  sender: Sender;
  content: string;
  formattedContent?: string;
  /** @default false */
  isEdited: boolean;
  mentionedUsers?: ChatMentionedUser[];
  /** @format date-time */
  createdAt: string;
  quotedMessage?: ChatMessage;
  quotedRecipeTask?: RecipeTaskItem;
  reactions?: MessageReaction[];
  /** @default [] */
  quotedImages: QuotedImage[];
}

export interface MessageDeletedEventData {
  messageId: string;
  roomId: string;
}

export interface ChatRoomItem {
  id: string;
  name: string;
  description: string;
  memberLimit: number;
  isDeleted: boolean;
  thumbnailUrl: string;
  isMember: boolean;
  isPrivate: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  creator?: UserBasicInfo;
  memberCount?: number;
  joinCode: string;
  pinned?: boolean;
  userRole?: string;
  latestMessage?: ChatMessage;
  unreadCount?: number;
}

export interface GetChatRoomsResult {
  rooms: ChatRoomItem[];
  total: number;
}

export interface SearchChatRoomsDto {
  /** @default 0 */
  skip?: number;
  /** @default 10 */
  take?: number;
  searchTerm?: string;
  isPrivate?: boolean;
  roomIds?: any[][];
}

export enum ChatRoomRole {
  Owner = 'owner',
  Admin = 'admin',
  Moderator = 'moderator',
  Member = 'member',
}

export interface AddRoomMemberDto {
  userUid: string;
  role: ChatRoomRole;
}

export interface CreateChatRoomDto {
  name: string;
  /** @default "" */
  description?: string;
  isPrivate?: boolean;
  thumbnailId?: string;
  roomMembers?: AddRoomMemberDto[];
}

export interface UpdateChatRoomDto {
  name?: string;
  description?: string;
  isPrivate?: boolean;
  thumbnailId?: string;
}

export interface ChatRoomMemberItem {
  id: string;
  user: UserBasicInfo;
  isOnline: boolean;
  role: ChatRoomRole;
}

export interface GetRoomMembersResult {
  roomMembers: ChatRoomMemberItem[];
  total: number;
}

export interface JoinChatRoomDto {
  roomId: string;
}

export interface InviteRoomMembersDto {
  members: AddRoomMemberDto[];
}

export enum ChatRoomInviteStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export interface UpdateRoomInviteDto {
  status: ChatRoomInviteStatus;
}

export interface UpdateRoomMemberDto {
  role: ChatRoomRole;
}

export enum RoomRecipeType {
  ByGaia = 'by-gaia',
  ByCreator = 'by-creator',
  ByCommunity = 'by-community',
}

export interface AddRoomRecipesDto {
  recipeIds: string[];
  addAll?: boolean;
}

export enum PromptSortOptions {
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST',
  A_TO_Z = 'A_TO_Z',
  Z_TO_A = 'Z_TO_A',
}

export interface PromptItem {
  id: string;
  name: string;
  thumbnailId?: string;
  thumbnailUrl?: string;
  prompt: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export interface GetPromptsResult {
  prompts: PromptItem[];
  total: number;
}

export interface CreatePromptDto {
  name?: string;
  description?: string;
  thumbnailId?: string;
  prompt: string;
}

export interface UpdatePromptDto {
  name?: string;
  description?: string;
  thumbnailId?: string;
  prompt?: string;
}

export interface AddMessageReactionDto {
  reactionType: MessageReactionType;
}

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Ather Gallery API
 * @version 1.0.0
 * @contact
 *
 * Powered by Ather Labs
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Solana Auth
     * @name SolanaAuthControllerGenerateAuthMessage
     * @request POST:/api/auth/solana/initiate
     */
    solanaAuthControllerGenerateAuthMessage: (data: SolanaAuthDto, params: RequestParams = {}) =>
      this.request<SolanaAuthResponse, any>({
        path: `/api/auth/solana/initiate`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Solana Auth
     * @name SolanaAuthControllerValidateAuthSignature
     * @request POST:/api/auth/solana/validate
     */
    solanaAuthControllerValidateAuthSignature: (
      data: SolanaValidateAuthDto,
      params: RequestParams = {},
    ) =>
      this.request<SolanaValidateAuthResponse, any>({
        path: `/api/auth/solana/validate`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerGetEmailVerifiedStatus
     * @request GET:/api/auth/check-email-verified/{email}
     */
    authControllerGetEmailVerifiedStatus: (email: string, params: RequestParams = {}) =>
      this.request<GetEmailVerifiedStatusResponseDto, any>({
        path: `/api/auth/check-email-verified/${email}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSendSignUpEmailConfirmation
     * @request POST:/api/auth/sign-up/send-email
     */
    authControllerSendSignUpEmailConfirmation: (
      data: SignUpConfirmationRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<SendCodeResponseDto, any>({
        path: `/api/auth/sign-up/send-email`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerVerifySignUpEmailConfirmation
     * @request POST:/api/auth/sign-up/verify-email
     */
    authControllerVerifySignUpEmailConfirmation: (
      data: VerifySignUpConfirmationRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<VerifyCodeResponseDto, any>({
        path: `/api/auth/sign-up/verify-email`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSendSignInEmailPasscode
     * @request POST:/api/auth/sign-in/send-passcode
     */
    authControllerSendSignInEmailPasscode: (
      data: SignInPasscodeRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<SendCodeResponseDto, any>({
        path: `/api/auth/sign-in/send-passcode`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerVerifySignInEmailPasscode
     * @request POST:/api/auth/sign-in/verify-passcode
     */
    authControllerVerifySignInEmailPasscode: (
      data: VerifySignInPasscodeRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<VerifySignInPasscodeResponseDto, any>({
        path: `/api/auth/sign-in/verify-passcode`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerResetPassword
     * @request POST:/api/auth/reset-password/send-code
     */
    authControllerResetPassword: (data: ResetPasswordRequestDto, params: RequestParams = {}) =>
      this.request<SendCodeResponseDto, any>({
        path: `/api/auth/reset-password/send-code`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerVerifyResetPassword
     * @request POST:/api/auth/reset-password/verify-code
     */
    authControllerVerifyResetPassword: (
      data: VerifyResetPasswordRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<VerifyCodeResponseDto, any>({
        path: `/api/auth/reset-password/verify-code`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSendLinkWalletEmail
     * @request POST:/api/auth/link-wallet/send-code
     */
    authControllerSendLinkWalletEmail: (data: LinkWalletRequestDto, params: RequestParams = {}) =>
      this.request<SendCodeResponseDto, any>({
        path: `/api/auth/link-wallet/send-code`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerVerifyLinkWallet
     * @request POST:/api/auth/link-wallet/verify-code
     */
    authControllerVerifyLinkWallet: (
      data: VerifyLinkWalletRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<VerifyCodeResponseDto, any>({
        path: `/api/auth/link-wallet/verify-code`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerSendLinkFarcasterEmail
     * @request POST:/api/auth/link-farcaster/send-code
     */
    authControllerSendLinkFarcasterEmail: (
      data: LinkFarcasterRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<SendCodeResponseDto, any>({
        path: `/api/auth/link-farcaster/send-code`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerVerifyLinkFarcaster
     * @request POST:/api/auth/link-farcaster/verify-code
     */
    authControllerVerifyLinkFarcaster: (
      data: VerifyLinkFarcasterRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<VerifyCodeResponseDto, any>({
        path: `/api/auth/link-farcaster/verify-code`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerRequestChangeEmail
     * @request POST:/api/auth/change-email/send-code
     * @secure
     */
    authControllerRequestChangeEmail: (data: ChangeEmailRequestDto, params: RequestParams = {}) =>
      this.request<SendCodeResponseDto, any>({
        path: `/api/auth/change-email/send-code`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerConfirmChangeEmail
     * @request POST:/api/auth/change-email/verify-code
     * @secure
     */
    authControllerConfirmChangeEmail: (
      data: ConfirmChangeEmailCodeRequestDto,
      params: RequestParams = {},
    ) =>
      this.request<VerifyCodeResponseDto, any>({
        path: `/api/auth/change-email/verify-code`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerVerifyNewEmailCode
     * @request POST:/api/auth/change-email/verify-new-email/{code}
     */
    authControllerVerifyNewEmailCode: (code: string, params: RequestParams = {}) =>
      this.request<VerifyCodeResponseDto, any>({
        path: `/api/auth/change-email/verify-new-email/${code}`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerVerifySecureCode
     * @request POST:/api/auth/secure-account/verify-code
     */
    authControllerVerifySecureCode: (data: VerifySecureCode, params: RequestParams = {}) =>
      this.request<VerifyCodeResponseDto, any>({
        path: `/api/auth/secure-account/verify-code`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Farcaster Auth
     * @name FarcasterAuthControllerGetConnectedUser
     * @request GET:/api/auth/farcaster/{farcasterId}
     */
    farcasterAuthControllerGetConnectedUser: (farcasterId: number, params: RequestParams = {}) =>
      this.request<GetConnectedUserResponse, any>({
        path: `/api/auth/farcaster/${farcasterId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Farcaster Auth
     * @name FarcasterAuthControllerAuthorize
     * @request POST:/api/auth/farcaster/authorize
     */
    farcasterAuthControllerAuthorize: (
      data: FarcasterAuthorizeRequest,
      params: RequestParams = {},
    ) =>
      this.request<FarcasterAuthorizeResponse, any>({
        path: `/api/auth/farcaster/authorize`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EVM Auth
     * @name EvmAuthControllerGenerateAuthMessage
     * @request POST:/api/auth/evm/initiate
     */
    evmAuthControllerGenerateAuthMessage: (data: EvmAuthDto, params: RequestParams = {}) =>
      this.request<EvmAuthResponse, any>({
        path: `/api/auth/evm/initiate`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EVM Auth
     * @name EvmAuthControllerValidateAuthSignature
     * @request POST:/api/auth/evm/validate
     */
    evmAuthControllerValidateAuthSignature: (
      data: EvmValidateAuthDto,
      params: RequestParams = {},
    ) =>
      this.request<EvmValidateAuthResponse, any>({
        path: `/api/auth/evm/validate`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Telegram Auth
     * @name TelegramAuthControllerAuthorize
     * @request POST:/api/auth/telegram/authorize
     */
    telegramAuthControllerAuthorize: (data: TelegramAuthorizeRequest, params: RequestParams = {}) =>
      this.request<TelegramAuthorizeResponse, any>({
        path: `/api/auth/telegram/authorize`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Telegram Auth
     * @name TelegramAuthControllerLinkAccount
     * @request POST:/api/auth/telegram/link-account
     * @secure
     */
    telegramAuthControllerLinkAccount: (
      data: TelegramAuthorizeRequest,
      params: RequestParams = {},
    ) =>
      this.request<TelegramAuthorizeResponse, any>({
        path: `/api/auth/telegram/link-account`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerGetInspirationBoardImages
     * @request GET:/api/images/inspiration-board
     * @secure
     */
    imageControllerGetInspirationBoardImages: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        searchTerm?: string;
        onlyMetadata?: boolean;
        onlyMine?: boolean;
        onlyFavorite?: boolean;
        onlyCurrentWorkspace?: boolean;
        generatedBy?: FilterGeneratedByOptions;
        tagIds?: number[];
        sortBy?: SortOptions;
        creatorUid?: string;
        sdWorkflowId?: string;
        recipeIds?: string[];
        smart?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetInspirationBoardImagesResult, any>({
        path: `/api/images/inspiration-board`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerGetInspirationBoardImage
     * @request GET:/api/images/inspiration-board/{imageId}
     * @secure
     */
    imageControllerGetInspirationBoardImage: (imageId: string, params: RequestParams = {}) =>
      this.request<ExploreImageFullDetail, any>({
        path: `/api/images/inspiration-board/${imageId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerGetInspirationBoardSimilarImages
     * @request GET:/api/images/inspiration-board/{imageId}/similiar
     * @secure
     */
    imageControllerGetInspirationBoardSimilarImages: (
      imageId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetInspirationBoardSimilarImagesResult, any>({
        path: `/api/images/inspiration-board/${imageId}/similiar`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerCreateInspirationBoardImageComment
     * @request POST:/api/images/inspiration-board/{imageId}/comments
     * @secure
     */
    imageControllerCreateInspirationBoardImageComment: (
      imageId: string,
      data: CreateInspirationBoardImageCommentDto,
      params: RequestParams = {},
    ) =>
      this.request<Comment, any>({
        path: `/api/images/inspiration-board/${imageId}/comments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerGetInspirationBoardImageComments
     * @request GET:/api/images/inspiration-board/{imageId}/comments
     * @secure
     */
    imageControllerGetInspirationBoardImageComments: (
      imageId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        parentCommentId?: string;
        commentId?: string;
        notificationId?: string;
        order?: CommentsOrderOption;
        nodeIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCommentsResult, any>({
        path: `/api/images/inspiration-board/${imageId}/comments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerGetInspirationBoardImageMentionable
     * @request GET:/api/images/inspiration-board/{imageId}/comments/mentionable
     * @secure
     */
    imageControllerGetInspirationBoardImageMentionable: (
      imageId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetMentionableUsersResult, any>({
        path: `/api/images/inspiration-board/${imageId}/comments/mentionable`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerToggleInspirationBoardImageFavorite
     * @request POST:/api/images/inspiration-board/{imageId}/favorite
     * @secure
     */
    imageControllerToggleInspirationBoardImageFavorite: (
      imageId: string,
      params: RequestParams = {},
    ) =>
      this.request<FavoriteResult, any>({
        path: `/api/images/inspiration-board/${imageId}/favorite`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerSearchImages
     * @request POST:/api/images/search
     * @secure
     */
    imageControllerSearchImages: (data: SearchImagesOptions, params: RequestParams = {}) =>
      this.request<SearchImagesResponse, any>({
        path: `/api/images/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerPublicSearchImages
     * @request POST:/api/images/search/public
     * @secure
     */
    imageControllerPublicSearchImages: (data: SearchImagesOptions, params: RequestParams = {}) =>
      this.request<SearchImagesResponse, any>({
        path: `/api/images/search/public`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerGetSimilarImages
     * @request GET:/api/images/{imageId}/similar
     * @secure
     */
    imageControllerGetSimilarImages: (
      imageId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetSimilarImagesResult, any>({
        path: `/api/images/${imageId}/similar`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerMoveImagesToFolder
     * @request POST:/api/images/move
     * @secure
     */
    imageControllerMoveImagesToFolder: (data: MoveImagesToFolderDto, params: RequestParams = {}) =>
      this.request<MoveImagesToFolderResponse, any>({
        path: `/api/images/move`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerFavorImage
     * @request POST:/api/images/{imageId}/favorite
     * @secure
     */
    imageControllerFavorImage: (imageId: string, params: RequestParams = {}) =>
      this.request<FavoriteResult, any>({
        path: `/api/images/${imageId}/favorite`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerSoftDeleteImage
     * @request POST:/api/images/soft-delete
     * @secure
     */
    imageControllerSoftDeleteImage: (data: DeleteImagesDto, params: RequestParams = {}) =>
      this.request<SoftDeleteImageResponse, any>({
        path: `/api/images/soft-delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerFullyDeleteImage
     * @request POST:/api/images/fully-delete
     * @secure
     */
    imageControllerFullyDeleteImage: (data: DeleteImagesDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/images/fully-delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerFullyDeleteImages
     * @request DELETE:/api/images/fully-delete/all
     * @secure
     */
    imageControllerFullyDeleteImages: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/images/fully-delete/all`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerRecoverImages
     * @request POST:/api/images/recover
     * @secure
     */
    imageControllerRecoverImages: (data: RecoverImagesDto, params: RequestParams = {}) =>
      this.request<RecoverImagesResponse, any>({
        path: `/api/images/recover`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerUpdateImageNote
     * @request PUT:/api/images/{imageId}/note
     * @secure
     */
    imageControllerUpdateImageNote: (
      imageId: string,
      data: {
        note: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/images/${imageId}/note`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerGetImageDetail
     * @request GET:/api/images/{imageId}
     * @secure
     */
    imageControllerGetImageDetail: (imageId: string, params: RequestParams = {}) =>
      this.request<ImageFullDetail, any>({
        path: `/api/images/${imageId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerDownloadImage
     * @request POST:/api/images/download/count
     * @secure
     */
    imageControllerDownloadImage: (data: IncreaseImagesDownloadDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/images/download/count`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerIncreaseImageShareCount
     * @request POST:/api/images/share/count
     * @secure
     */
    imageControllerIncreaseImageShareCount: (
      data: IncreaseImagesShareDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/images/share/count`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerRenameImage
     * @request POST:/api/images/{imageId}/rename
     * @secure
     */
    imageControllerRenameImage: (
      imageId: string,
      data: RenameImageDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/images/${imageId}/rename`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerGetImageSharedUsers
     * @request GET:/api/images/{imageId}/share
     * @secure
     */
    imageControllerGetImageSharedUsers: (
      imageId: string,
      query: {
        /** @default 0 */
        skip: number;
        /** @default 10 */
        take: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ImagePermission[], any>({
        path: `/api/images/${imageId}/share`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerShareImage
     * @request POST:/api/images/{imageId}/share
     * @secure
     */
    imageControllerShareImage: (
      imageId: string,
      data: ShareToUsersDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/images/${imageId}/share`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerUnshareImage
     * @request DELETE:/api/images/{imageId}/share
     * @secure
     */
    imageControllerUnshareImage: (imageId: string, data: UnshareDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/images/${imageId}/share`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerSwitchImageSharingMode
     * @request POST:/api/images/{imageId}/share/mode
     * @secure
     */
    imageControllerSwitchImageSharingMode: (
      imageId: string,
      data: SwitchWorkspaceSharingModeDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/images/${imageId}/share/mode`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerUpdateWorkspaceSharingRole
     * @request PUT:/api/images/{imageId}/share-by-workspace
     * @secure
     */
    imageControllerUpdateWorkspaceSharingRole: (
      imageId: string,
      data: UpdateSharingRoleDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/images/${imageId}/share-by-workspace`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerGetPreviewImageInfo
     * @request GET:/api/images/{imageId}/preview-info
     * @secure
     */
    imageControllerGetPreviewImageInfo: (imageId: string, params: RequestParams = {}) =>
      this.request<Image, any>({
        path: `/api/images/${imageId}/preview-info`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerGetPreviewImage
     * @request GET:/api/images/{imageId}/preview
     * @secure
     */
    imageControllerGetPreviewImage: (imageId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/images/${imageId}/preview`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerUpdateImageSharingRole
     * @request PATCH:/api/images/permission/{permissionId}/role
     * @secure
     */
    imageControllerUpdateImageSharingRole: (
      permissionId: number,
      data: UpdateSharingRoleDto,
      params: RequestParams = {},
    ) =>
      this.request<ImagePermission, any>({
        path: `/api/images/permission/${permissionId}/role`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerRequestImageAccess
     * @request POST:/api/images/{imageId}/access-request
     * @secure
     */
    imageControllerRequestImageAccess: (imageId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/images/${imageId}/access-request`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerUpdateImageAccessRequest
     * @request POST:/api/images/access-request/{accessRequestId}
     * @secure
     */
    imageControllerUpdateImageAccessRequest: (
      accessRequestId: string,
      data: GrantAccessDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/images/access-request/${accessRequestId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerInitializeMultipartUpload
     * @request POST:/api/images/upload/multipart
     * @secure
     */
    imageControllerInitializeMultipartUpload: (
      data: InitializeImageMultipartUploadDto,
      params: RequestParams = {},
    ) =>
      this.request<InitializeImageMultipartUploadResult[], any>({
        path: `/api/images/upload/multipart`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerCompleteMultipartUpload
     * @request POST:/api/images/upload/multipart/complete
     * @secure
     */
    imageControllerCompleteMultipartUpload: (
      data: CompleteMultipartUploadDto[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/images/upload/multipart/complete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerAbortMultipartUpload
     * @request POST:/api/images/upload/multipart/abort
     * @secure
     */
    imageControllerAbortMultipartUpload: (
      data: AbortMultipartUploadDto[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/images/upload/multipart/abort`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerToggleImageReaction
     * @request POST:/api/images/{imageId}/reaction
     * @secure
     */
    imageControllerToggleImageReaction: (
      imageId: string,
      data: ToggleImageReactionDto,
      params: RequestParams = {},
    ) =>
      this.request<ToggleReactionResult, any>({
        path: `/api/images/${imageId}/reaction`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerToggleImagesDiscovery
     * @request POST:/api/images/discovery
     * @secure
     */
    imageControllerToggleImagesDiscovery: (
      data: ToggleImageDiscoveryDto,
      params: RequestParams = {},
    ) =>
      this.request<number, any>({
        path: `/api/images/discovery`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerCopyImages
     * @request POST:/api/images/copy
     * @secure
     */
    imageControllerCopyImages: (data: CopyImagesDto, params: RequestParams = {}) =>
      this.request<CopyImagesResult, any>({
        path: `/api/images/copy`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Images
     * @name ImageControllerCommentImage
     * @request POST:/api/images/comments/{imageId}
     * @secure
     */
    imageControllerCommentImage: (
      imageId: string,
      data: CommentImageDto,
      params: RequestParams = {},
    ) =>
      this.request<Comment, any>({
        path: `/api/images/comments/${imageId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerGetFolders
     * @request GET:/api/folders
     * @secure
     */
    folderControllerGetFolders: (
      query?: {
        /** @default false */
        onlyUploadable?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetFoldersReponse, any>({
        path: `/api/folders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerCreateFolder
     * @request POST:/api/folders
     * @secure
     */
    folderControllerCreateFolder: (data: CreateFolderDto, params: RequestParams = {}) =>
      this.request<Folder, any>({
        path: `/api/folders`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerGetUserRootFolder
     * @request GET:/api/folders/root
     * @secure
     */
    folderControllerGetUserRootFolder: (params: RequestParams = {}) =>
      this.request<FolderDetail, any>({
        path: `/api/folders/root`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerSearchFolders
     * @request GET:/api/folders/search
     * @secure
     */
    folderControllerSearchFolders: (
      query: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        search: string;
        sortBy?: FoldersSortByColumn;
        /** @default "DESC" */
        sortOrder?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchFoldersResult, any>({
        path: `/api/folders/search`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerGetSubFolders
     * @request GET:/api/folders/sub-folders
     * @secure
     */
    folderControllerGetSubFolders: (
      query: {
        folderId?: string;
        search?: string;
        sortBy?: FoldersSortByColumn;
        isPinned?: boolean;
        ownerUids?: string[];
        /** @default false */
        canUpload?: boolean;
        /** @default "DESC" */
        sortOrder?: string;
        /** @default 0 */
        skip: number;
        /** @default 10 */
        take: number;
        view?: SubfolderView;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetSubFoldersResponse, any>({
        path: `/api/folders/sub-folders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerGetFolderInfo
     * @request GET:/api/folders/{folderId}
     * @secure
     */
    folderControllerGetFolderInfo: (folderId: string, params: RequestParams = {}) =>
      this.request<FolderDetail, any>({
        path: `/api/folders/${folderId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerUpdateFolder
     * @request PUT:/api/folders/{folderId}
     * @secure
     */
    folderControllerUpdateFolder: (
      folderId: string,
      data: UpdateFolderDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/folders/${folderId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerMoveFolder
     * @request PUT:/api/folders/move
     * @secure
     */
    folderControllerMoveFolder: (data: MoveFoldersDto, params: RequestParams = {}) =>
      this.request<Folder, any>({
        path: `/api/folders/move`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerRenameFolder
     * @request PUT:/api/folders/{folderId}/rename
     * @secure
     */
    folderControllerRenameFolder: (
      folderId: string,
      data: RenameFolderDto,
      params: RequestParams = {},
    ) =>
      this.request<Folder, any>({
        path: `/api/folders/${folderId}/rename`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerSoftDeleteFolder
     * @request POST:/api/folders/soft-delete
     * @secure
     */
    folderControllerSoftDeleteFolder: (data: DeleteFoldersDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/folders/soft-delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerShareFolder
     * @request POST:/api/folders/{folderId}/share
     * @secure
     */
    folderControllerShareFolder: (
      folderId: string,
      data: ShareToUsersDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/folders/${folderId}/share`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerUnshareFolder
     * @request DELETE:/api/folders/{folderId}/share
     * @secure
     */
    folderControllerUnshareFolder: (
      folderId: string,
      data: UnshareFolderDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/folders/${folderId}/share`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerShareFolderByWorkspace
     * @request POST:/api/folders/{folderId}/share-by-workspace
     * @secure
     */
    folderControllerShareFolderByWorkspace: (
      folderId: string,
      data: SwitchWorkspaceSharingModeDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/folders/${folderId}/share-by-workspace`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerSwitchShareFolderByWorkspaceMode
     * @request PUT:/api/folders/{folderId}/share-by-workspace
     * @secure
     */
    folderControllerSwitchShareFolderByWorkspaceMode: (
      folderId: string,
      data: UpdateSharingRoleDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/folders/${folderId}/share-by-workspace`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerRecoverDeletedFolders
     * @request POST:/api/folders/{folderId}/recover
     * @secure
     */
    folderControllerRecoverDeletedFolders: (folderId: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/folders/${folderId}/recover`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerFullyDeleteFolder
     * @request POST:/api/folders/fully-delete
     * @secure
     */
    folderControllerFullyDeleteFolder: (data: DeleteFoldersDto, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/folders/fully-delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerFullyDeleteAllFolders
     * @request DELETE:/api/folders/fully-delete/all
     * @secure
     */
    folderControllerFullyDeleteAllFolders: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/folders/fully-delete/all`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerRemoveAccessibleMember
     * @request DELETE:/api/folders/accessible-members/{removeUserId}
     * @secure
     */
    folderControllerRemoveAccessibleMember: (removeUserId: string, params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/folders/accessible-members/${removeUserId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerUpdateFolderPermissionRole
     * @request PATCH:/api/folders/permission/{permissionId}/role
     * @secure
     */
    folderControllerUpdateFolderPermissionRole: (
      permissionId: number,
      data: UpdateSharingRoleDto,
      params: RequestParams = {},
    ) =>
      this.request<FolderPermission, any>({
        path: `/api/folders/permission/${permissionId}/role`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerGetFolderPreviewInfo
     * @request GET:/api/folders/{folderId}/preview-info
     * @secure
     */
    folderControllerGetFolderPreviewInfo: (folderId: string, params: RequestParams = {}) =>
      this.request<GetFolderPreviewInfoResponse, any>({
        path: `/api/folders/${folderId}/preview-info`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Folders
     * @name FolderControllerLeaveFolder
     * @request POST:/api/folders/{folderId}/leave
     * @secure
     */
    folderControllerLeaveFolder: (folderId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/folders/${folderId}/leave`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerGetUserSelfInfo
     * @request GET:/api/users/me
     * @secure
     */
    userControllerGetUserSelfInfo: (params: RequestParams = {}) =>
      this.request<UserDetail, any>({
        path: `/api/users/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerUpdateUserProfile
     * @request PATCH:/api/users/me
     * @secure
     */
    userControllerUpdateUserProfile: (data: UpdateUserProfileDto, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/api/users/me`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerSearchUser
     * @request POST:/api/users/searches
     * @secure
     */
    userControllerSearchUser: (data: SearchUsersDto, params: RequestParams = {}) =>
      this.request<User[], any>({
        path: `/api/users/searches`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerGetCompletedWalkthrough
     * @request GET:/api/users/completedWalkthrough
     * @secure
     */
    userControllerGetCompletedWalkthrough: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/users/completedWalkthrough`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerCompletedWalkthrough
     * @request POST:/api/users/completedWalkthrough
     * @secure
     */
    userControllerCompletedWalkthrough: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/users/completedWalkthrough`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerResetWalkthrough
     * @request POST:/api/users/resetWalkthrough
     * @secure
     */
    userControllerResetWalkthrough: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/users/resetWalkthrough`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerGetRecipeTaskSearchHistories
     * @request POST:/api/users/recipe-task/search-histories
     * @secure
     */
    userControllerGetRecipeTaskSearchHistories: (params: RequestParams = {}) =>
      this.request<RecipeTaskSearchHistoriesResponse, any>({
        path: `/api/users/recipe-task/search-histories`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerClearRecipeTaskSearchHistories
     * @request POST:/api/users/recipe-task/search-histories/clear
     * @secure
     */
    userControllerClearRecipeTaskSearchHistories: (
      data: ClearRecipeTaskSearchHistoriesDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/users/recipe-task/search-histories/clear`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerRequestDeleteAccount
     * @request POST:/api/users/me/deletion
     * @secure
     */
    userControllerRequestDeleteAccount: (
      data: RequestDeleteAccountDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/users/me/deletion`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerSendAccountDeletionCode
     * @request POST:/api/users/me/deletion/code
     * @secure
     */
    userControllerSendAccountDeletionCode: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/users/me/deletion/code`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerReactivateAccount
     * @request POST:/api/users/me/reactivation
     * @secure
     */
    userControllerReactivateAccount: (data: ReactivateAccountDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/users/me/reactivation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerSendAccountReactivationCode
     * @request POST:/api/users/me/reactivation/code
     * @secure
     */
    userControllerSendAccountReactivationCode: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/users/me/reactivation/code`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerSignOutFromAllDevices
     * @request POST:/api/users/sign-out/all
     * @secure
     */
    userControllerSignOutFromAllDevices: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users/sign-out/all`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerCheckIfWhitelisted
     * @request POST:/api/users/whitelist/check
     * @secure
     */
    userControllerCheckIfWhitelisted: (
      data: CheckIfWhiteListedEmailsDto,
      params: RequestParams = {},
    ) =>
      this.request<CheckIfWhiteListedEmailsResult, any>({
        path: `/api/users/whitelist/check`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerUnlinkFarcaster
     * @request DELETE:/api/users/farcaster
     * @secure
     */
    userControllerUnlinkFarcaster: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users/farcaster`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerUnlinkTelegram
     * @request DELETE:/api/users/telegram
     * @secure
     */
    userControllerUnlinkTelegram: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users/telegram`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerGetUserSelfBillingAddresses
     * @request GET:/api/users/billing-addresses/me
     * @secure
     */
    userControllerGetUserSelfBillingAddresses: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/users/billing-addresses/me`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerAddBillingAddress
     * @request POST:/api/users/billing-addresses
     * @secure
     */
    userControllerAddBillingAddress: (data: AddBillingAddressDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users/billing-addresses`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerUpdateBillingAddress
     * @request PATCH:/api/users/billing-addresses/{addressId}
     * @secure
     */
    userControllerUpdateBillingAddress: (
      addressId: string,
      data: AddBillingAddressDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/users/billing-addresses/${addressId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerDeleteBillingAddress
     * @request DELETE:/api/users/billing-addresses/{addressId}
     * @secure
     */
    userControllerDeleteBillingAddress: (addressId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users/billing-addresses/${addressId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerAddNotificationToken
     * @request POST:/api/users/me/notification-token
     * @secure
     */
    userControllerAddNotificationToken: (
      data: AddUserNotificationTokenDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/users/me/notification-token`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UserControllerSearchAllUsers
     * @request POST:/api/users/searches/all
     * @secure
     */
    userControllerSearchAllUsers: (data: SearchAllUsersDto, params: RequestParams = {}) =>
      this.request<User[], any>({
        path: `/api/users/searches/all`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description The app is alive
     *
     * @tags Health
     * @name Liveness
     * @request GET:/api/health
     */
    liveness: (params: RequestParams = {}) =>
      this.request<HealthCheckResult, any>({
        path: `/api/health`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description The app is ready to serve
     *
     * @tags Health
     * @name Readiness
     * @request GET:/api/health/ready
     */
    readiness: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/health/ready`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Response the requester IP address
     *
     * @tags Health
     * @name Ping
     * @request GET:/api/health/ping
     */
    ping: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/health/ping`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tags
     * @name TagsControllerTagImage
     * @request POST:/api/tags/tag-images
     * @secure
     */
    tagsControllerTagImage: (data: TagImageDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/tags/tag-images`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tags
     * @name TagsControllerUntagImage
     * @request POST:/api/tags/untag-images
     * @secure
     */
    tagsControllerUntagImage: (data: UntagImageDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/tags/untag-images`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tags
     * @name TagsControllerGetTags
     * @request GET:/api/tags
     * @secure
     */
    tagsControllerGetTags: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        tagIds?: number[];
        folderId?: string;
        searchQuery?: string;
        isInUse?: boolean;
        isDiscoverable?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetTagsResponse, any>({
        path: `/api/tags`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tags
     * @name TagsControllerCreateTag
     * @request POST:/api/tags
     * @secure
     */
    tagsControllerCreateTag: (data: CreateTagDto, params: RequestParams = {}) =>
      this.request<Tag, any>({
        path: `/api/tags`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comment
     * @name CommentControllerGetMentionable
     * @request GET:/api/comment/{imageId}/mentionable
     * @secure
     */
    commentControllerGetMentionable: (
      imageId: string,
      query?: {
        search?: string;
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserMentionable[], any>({
        path: `/api/comment/${imageId}/mentionable`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comment
     * @name CommentControllerUpdateComment
     * @request PUT:/api/comment/{commentId}
     * @secure
     */
    commentControllerUpdateComment: (
      commentId: string,
      data: UpdateCommentDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/comment/${commentId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comment
     * @name CommentControllerDeleteComment
     * @request DELETE:/api/comment/{commentId}
     * @secure
     */
    commentControllerDeleteComment: (commentId: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/comment/${commentId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comment
     * @name CommentControllerGetCommentIndexes
     * @request GET:/api/comment/index
     * @secure
     */
    commentControllerGetCommentIndexes: (
      query: {
        entityId: string;
        entityType: EntityType;
        notificationId: string;
        order?: CommentsOrderOption;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCommentIndexesResult, any>({
        path: `/api/comment/index`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comment
     * @name CommentControllerGetCommentsImage
     * @request GET:/api/comment/{imageId}
     * @secure
     */
    commentControllerGetCommentsImage: (
      imageId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        parentCommentId?: string;
        commentId?: string;
        notificationId?: string;
        order?: CommentsOrderOption;
        nodeIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCommentsResult, any>({
        path: `/api/comment/${imageId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comment
     * @name CommentControllerLikeComment
     * @request POST:/api/comment/{commentId}/like
     * @secure
     */
    commentControllerLikeComment: (commentId: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/comment/${commentId}/like`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags comment
     * @name CommentControllerMarkCommentsAsRead
     * @request POST:/api/comment/mark-as-read
     * @secure
     */
    commentControllerMarkCommentsAsRead: (
      data: MarkCommentsAsReadDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/comment/mark-as-read`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationControllerGetNotifications
     * @request GET:/api/notifications
     * @secure
     */
    notificationControllerGetNotifications: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        /** @default false */
        onlyUnread?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetNotificationsResponse, any>({
        path: `/api/notifications`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationControllerGetNewNotificationsCount
     * @request GET:/api/notifications/new
     * @secure
     */
    notificationControllerGetNewNotificationsCount: (params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/notifications/new`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationControllerClearNewNotificationsCount
     * @request POST:/api/notifications/new
     * @secure
     */
    notificationControllerClearNewNotificationsCount: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/notifications/new`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationControllerReadNotifications
     * @request POST:/api/notifications/read
     * @secure
     */
    notificationControllerReadNotifications: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/notifications/read`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationControllerReadNotification
     * @request POST:/api/notifications/read/{notificationId}
     * @secure
     */
    notificationControllerReadNotification: (notificationId: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/notifications/read/${notificationId}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationControllerDeleteNotifications
     * @request DELETE:/api/notifications/delete
     * @secure
     */
    notificationControllerDeleteNotifications: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/notifications/delete`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Notifications
     * @name NotificationControllerDeleteNotification
     * @request DELETE:/api/notifications/delete/{notificationId}
     * @secure
     */
    notificationControllerDeleteNotification: (
      notificationId: string,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/notifications/delete/${notificationId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags onboarding
     * @name OnboardingControllerSubmit
     * @request POST:/api/onboarding
     * @secure
     */
    onboardingControllerSubmit: (data: SubmitOnboardingDto, params: RequestParams = {}) =>
      this.request<Onboarding, any>({
        path: `/api/onboarding`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags onboarding
     * @name OnboardingControllerCheckCompleted
     * @request GET:/api/onboarding
     * @secure
     */
    onboardingControllerCheckCompleted: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/onboarding`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerCreateWorkspace
     * @request POST:/api/workspaces
     * @secure
     */
    workspaceControllerCreateWorkspace: (data: UpsertWorkspaceDto, params: RequestParams = {}) =>
      this.request<Workspace, any>({
        path: `/api/workspaces`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerGetWorkspaces
     * @request GET:/api/workspaces
     * @secure
     */
    workspaceControllerGetWorkspaces: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetWorkspacesResponse, any>({
        path: `/api/workspaces`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerGetWorkspace
     * @request GET:/api/workspaces/{workspaceId}
     * @secure
     */
    workspaceControllerGetWorkspace: (workspaceId: string, params: RequestParams = {}) =>
      this.request<WorkspaceDetail, any>({
        path: `/api/workspaces/${workspaceId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerUpdateWorkspac
     * @request PATCH:/api/workspaces/{workspaceId}
     * @secure
     */
    workspaceControllerUpdateWorkspac: (
      workspaceId: string,
      data: UpdateWorkspaceDto,
      params: RequestParams = {},
    ) =>
      this.request<Workspace, any>({
        path: `/api/workspaces/${workspaceId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerDeleteWorkspace
     * @request DELETE:/api/workspaces/{workspaceId}
     * @secure
     */
    workspaceControllerDeleteWorkspace: (workspaceId: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/workspaces/${workspaceId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerGetWorkspaceMembers
     * @request GET:/api/workspaces/{workspaceId}/members
     * @secure
     */
    workspaceControllerGetWorkspaceMembers: (
      workspaceId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        searchTerm?: string;
        roles?: WorkspaceRole[];
        emails?: string[];
        sortBy?: WorkspaceMembersSortByOptions;
        sortOrder?: SortOrder;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetWorkspaceMembersResult, any>({
        path: `/api/workspaces/${workspaceId}/members`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerAddMember
     * @request POST:/api/workspaces/{workspaceId}/members
     * @secure
     */
    workspaceControllerAddMember: (
      workspaceId: string,
      data: AddMemberToWorkspaceDto,
      params: RequestParams = {},
    ) =>
      this.request<UserWorkspace, any>({
        path: `/api/workspaces/${workspaceId}/members`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerRemoveMember
     * @request DELETE:/api/workspaces/{workspaceId}/members
     * @secure
     */
    workspaceControllerRemoveMember: (
      workspaceId: string,
      data: UpdateWorkspaceMemberDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/workspaces/${workspaceId}/members`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerLeaveWorkspace
     * @request POST:/api/workspaces/{workspaceId}/leave
     * @secure
     */
    workspaceControllerLeaveWorkspace: (workspaceId: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/workspaces/${workspaceId}/leave`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerLeaveWorkspaces
     * @request POST:/api/workspaces/leave
     * @secure
     */
    workspaceControllerLeaveWorkspaces: (data: LeaveWorkspacesDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/workspaces/leave`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerTransferWorkspace
     * @request POST:/api/workspaces/{workspaceId}/transfer
     * @secure
     */
    workspaceControllerTransferWorkspace: (
      workspaceId: string,
      data: TransferWorkspaceOwnershipDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/workspaces/${workspaceId}/transfer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerSwitchWorkspace
     * @request POST:/api/workspaces/{workspaceId}/switch
     * @secure
     */
    workspaceControllerSwitchWorkspace: (workspaceId: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/workspaces/${workspaceId}/switch`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerUpdateWorkspaceMemberRole
     * @request POST:/api/workspaces/user-workspace/{userWorkspaceId}
     * @secure
     */
    workspaceControllerUpdateWorkspaceMemberRole: (
      userWorkspaceId: number,
      data: UpdateWorkspaceMemberRoleDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/workspaces/user-workspace/${userWorkspaceId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerUpdateWorkspaceInvite
     * @request PATCH:/api/workspaces/invite/{inviteId}
     * @secure
     */
    workspaceControllerUpdateWorkspaceInvite: (
      inviteId: number,
      data: UpdateWorkspaceInviteDto,
      params: RequestParams = {},
    ) =>
      this.request<WorkspaceInviteItem, any>({
        path: `/api/workspaces/invite/${inviteId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerGetWorkspacePlan
     * @request GET:/api/workspaces/{workspaceId}/plan
     * @secure
     */
    workspaceControllerGetWorkspacePlan: (workspaceId: string, params: RequestParams = {}) =>
      this.request<WorkspacePlanItem, any>({
        path: `/api/workspaces/${workspaceId}/plan`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerCheckIfWorkspaceReachMemberLimit
     * @request POST:/api/workspaces/{workspaceId}/member-limit
     * @secure
     */
    workspaceControllerCheckIfWorkspaceReachMemberLimit: (
      workspaceId: string,
      data: CheckIfWorkspaceReachMemberLimitDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/workspaces/${workspaceId}/member-limit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name WorkspaceControllerGetPaymentInfo
     * @request GET:/api/workspaces/{workspaceId}/payment-info
     * @secure
     */
    workspaceControllerGetPaymentInfo: (workspaceId: string, params: RequestParams = {}) =>
      this.request<WorkspacePaymentInfoItem[], any>({
        path: `/api/workspaces/${workspaceId}/payment-info`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Early Access Registration
     * @name EarlyAccessControllerJoin
     * @request POST:/api/early-access/join
     */
    earlyAccessControllerJoin: (data: UserJoinEarlyAccessDto, params: RequestParams = {}) =>
      this.request<EarlyAccess, any>({
        path: `/api/early-access/join`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Early Access Registration
     * @name EarlyAccessControllerJoinGaiaEarlyAccess
     * @request POST:/api/early-access/join-gaia
     */
    earlyAccessControllerJoinGaiaEarlyAccess: (
      data: JoinGaiaEarlyAccessDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/early-access/join-gaia`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Early Access Registration
     * @name EarlyAccessControllerGetGaiaEarlyAccessCount
     * @request POST:/api/early-access/join-gaia/count
     */
    earlyAccessControllerGetGaiaEarlyAccessCount: (params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/early-access/join-gaia/count`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Early Access Registration
     * @name EarlyAccessControllerSendInvitationEmail
     * @request POST:/api/early-access/send-invitation-email
     * @secure
     */
    earlyAccessControllerSendInvitationEmail: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/early-access/send-invitation-email`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Versioning
     * @name VersioningControllerGetAppCurrentVersions
     * @request GET:/api/versions
     * @secure
     */
    versioningControllerGetAppCurrentVersions: (params: RequestParams = {}) =>
      this.request<GetCurrentVersionResponse, any>({
        path: `/api/versions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerList
     * @summary User gets all support recipes
     * @request GET:/api/recipe/list
     * @secure
     */
    recipeControllerList: (
      query: {
        searchTerm: string;
        types?: RecipeType[];
        baseModel?: SDBaseModel;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetRecipesResponse, any>({
        path: `/api/recipe/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerSearchRecipes
     * @request POST:/api/recipe
     * @secure
     */
    recipeControllerSearchRecipes: (data: SearchRecipesDto, params: RequestParams = {}) =>
      this.request<GetRecipesResult, any>({
        path: `/api/recipe`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerPose
     * @summary User gets all poses
     * @request GET:/api/recipe/pose
     * @secure
     */
    recipeControllerPose: (params: RequestParams = {}) =>
      this.request<GetPosesResponse, any>({
        path: `/api/recipe/pose`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerGetRecipeCategories
     * @request GET:/api/recipe/categories
     * @secure
     */
    recipeControllerGetRecipeCategories: (params: RequestParams = {}) =>
      this.request<GetRecipeCategoriesResult, any>({
        path: `/api/recipe/categories`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerGetById
     * @summary User gets specific recipe by id
     * @request GET:/api/recipe/{recipeId}
     * @secure
     */
    recipeControllerGetById: (recipeId: string, params: RequestParams = {}) =>
      this.request<RecipeItem, any>({
        path: `/api/recipe/${recipeId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerUpdateRecipe
     * @request PATCH:/api/recipe/{recipeId}
     * @secure
     */
    recipeControllerUpdateRecipe: (
      recipeId: string,
      data: UpdateRecipeDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/recipe/${recipeId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerSoftDeleteRecipes
     * @request DELETE:/api/recipe/soft-delete
     * @secure
     */
    recipeControllerSoftDeleteRecipes: (data: DeleteRecipesDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/recipe/soft-delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerFullyDeleteRecipes
     * @request DELETE:/api/recipe/fully-delete
     * @secure
     */
    recipeControllerFullyDeleteRecipes: (data: DeleteRecipesDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/recipe/fully-delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerFullyDeleteAllRecipes
     * @request DELETE:/api/recipe/fully-delete/all
     * @secure
     */
    recipeControllerFullyDeleteAllRecipes: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/recipe/fully-delete/all`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerRecoverRecipes
     * @request POST:/api/recipe/recover
     * @secure
     */
    recipeControllerRecoverRecipes: (data: RecoverRecipesDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/recipe/recover`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerTagRecipes
     * @request POST:/api/recipe/tags
     * @secure
     */
    recipeControllerTagRecipes: (data: TagRecipesDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/recipe/tags`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerUntagRecipes
     * @request DELETE:/api/recipe/tags
     * @secure
     */
    recipeControllerUntagRecipes: (data: UntagRecipesDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/recipe/tags`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerToggleFavoriteRecipe
     * @request POST:/api/recipe/{recipeId}/favorites
     * @secure
     */
    recipeControllerToggleFavoriteRecipe: (recipeId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/recipe/${recipeId}/favorites`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerGetRecipeSharedUsers
     * @request GET:/api/recipe/{recipeId}/permissions
     * @secure
     */
    recipeControllerGetRecipeSharedUsers: (
      recipeId: string,
      query: {
        /** @default 0 */
        skip: number;
        /** @default 10 */
        take: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<RecipePermission[], any>({
        path: `/api/recipe/${recipeId}/permissions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerShareRecipe
     * @request POST:/api/recipe/{recipeId}/share
     * @secure
     */
    recipeControllerShareRecipe: (
      recipeId: string,
      data: ShareToUsersDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/recipe/${recipeId}/share`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerUnshareRecipe
     * @request DELETE:/api/recipe/{recipeId}/share
     * @secure
     */
    recipeControllerUnshareRecipe: (
      recipeId: string,
      data: UnshareDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/recipe/${recipeId}/share`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerSwitchSharingMode
     * @request POST:/api/recipe/{recipeId}/share/mode
     * @secure
     */
    recipeControllerSwitchSharingMode: (
      recipeId: string,
      data: SwitchWorkspaceSharingModeDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/recipe/${recipeId}/share/mode`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerUpdateWorkspaceSharingRole
     * @request PUT:/api/recipe/{recipeId}/share-by-workspace
     * @secure
     */
    recipeControllerUpdateWorkspaceSharingRole: (
      recipeId: string,
      data: UpdateSharingRoleDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/recipe/${recipeId}/share-by-workspace`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerUpdateSharingRole
     * @request PATCH:/api/recipe/permission/{permissionId}/role
     * @secure
     */
    recipeControllerUpdateSharingRole: (
      permissionId: number,
      data: UpdateSharingRoleDto,
      params: RequestParams = {},
    ) =>
      this.request<RecipePermission, any>({
        path: `/api/recipe/permission/${permissionId}/role`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerUpdateRecipeDiscovery
     * @request POST:/api/recipe/discovery
     * @secure
     */
    recipeControllerUpdateRecipeDiscovery: (
      data: UpdateRecipeDiscoveryDto,
      params: RequestParams = {},
    ) =>
      this.request<number, any>({
        path: `/api/recipe/discovery`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerAddRecipesToLibrary
     * @request POST:/api/recipe/add-to-library
     * @secure
     */
    recipeControllerAddRecipesToLibrary: (
      data: AddRecipesToLibraryDto,
      params: RequestParams = {},
    ) =>
      this.request<AddRecipesToLibraryResult, any>({
        path: `/api/recipe/add-to-library`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe
     * @name RecipeControllerRemoveRecipesFromLibrary
     * @request POST:/api/recipe/remove-from-library
     * @secure
     */
    recipeControllerRemoveRecipesFromLibrary: (
      data: RemoveRecipesFromLibraryDto,
      params: RequestParams = {},
    ) =>
      this.request<RemoveRecipesFromLibraryResult, any>({
        path: `/api/recipe/remove-from-library`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerGetCurrentTasks
     * @summary User gets current list of tasks
     * @request GET:/api/recipe/tasks/list
     * @secure
     */
    recipeTaskControllerGetCurrentTasks: (data: FilterRecipeTasksDto, params: RequestParams = {}) =>
      this.request<ListRecipeTasksResponse, any>({
        path: `/api/recipe/tasks/list`,
        method: 'GET',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerGetCurrentTasksPost
     * @summary User gets current list of tasks
     * @request POST:/api/recipe/tasks/list
     * @secure
     */
    recipeTaskControllerGetCurrentTasksPost: (
      data: FilterRecipeTasksDto,
      params: RequestParams = {},
    ) =>
      this.request<ListRecipeTasksResponse, any>({
        path: `/api/recipe/tasks/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerGetById
     * @summary User gets task by taskId
     * @request GET:/api/recipe/tasks/{taskId}
     * @secure
     */
    recipeTaskControllerGetById: (taskId: string, params: RequestParams = {}) =>
      this.request<RecipeTask, any>({
        path: `/api/recipe/tasks/${taskId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerUpdateTask
     * @summary User updates task
     * @request PUT:/api/recipe/tasks/{taskId}
     * @secure
     */
    recipeTaskControllerUpdateTask: (
      taskId: string,
      data: UpdateRecipeTaskDto,
      params: RequestParams = {},
    ) =>
      this.request<RecipeTask, any>({
        path: `/api/recipe/tasks/${taskId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerDeleteTask
     * @summary User deletes a task
     * @request DELETE:/api/recipe/tasks/{taskId}
     * @secure
     */
    recipeTaskControllerDeleteTask: (taskId: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/recipe/tasks/${taskId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerGetRecipeTaskPreviewInfo
     * @summary User gets task by taskId
     * @request GET:/api/recipe/tasks/{taskId}/preview-info
     * @secure
     */
    recipeTaskControllerGetRecipeTaskPreviewInfo: (taskId: string, params: RequestParams = {}) =>
      this.request<RecipeTaskPreviewInfo, any>({
        path: `/api/recipe/tasks/${taskId}/preview-info`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerGetOngoingTasksPost
     * @summary User gets on going tasks
     * @request POST:/api/recipe/tasks/list/ongoing
     * @secure
     */
    recipeTaskControllerGetOngoingTasksPost: (
      data: FilterOngoingRecipeTasksDto,
      params: RequestParams = {},
    ) =>
      this.request<ListRecipeTasksResponse, any>({
        path: `/api/recipe/tasks/list/ongoing`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerGetHistoryTasksPost
     * @summary User gets history tasks
     * @request POST:/api/recipe/tasks/list/history
     * @secure
     */
    recipeTaskControllerGetHistoryTasksPost: (
      data: FilterRecipeTasksWithPaginationDto,
      params: RequestParams = {},
    ) =>
      this.request<ListRecipeTasksResponse, any>({
        path: `/api/recipe/tasks/list/history`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerGetTasksPost
     * @summary User gets tasks for board view
     * @request POST:/api/recipe/tasks/agent-scheduler/list/board
     * @secure
     */
    recipeTaskControllerGetTasksPost: (
      data: FilterRecipeTasksWithPaginationDtoForAgentScheduler,
      params: RequestParams = {},
    ) =>
      this.request<ListRecipeTasksResponseForAgentScheduler, any>({
        path: `/api/recipe/tasks/agent-scheduler/list/board`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerGetOngoingTasksPostForAgentScheduler
     * @summary User gets ongoing tasks for list view
     * @request POST:/api/recipe/tasks/agent-scheduler/list/ongoing
     * @secure
     */
    recipeTaskControllerGetOngoingTasksPostForAgentScheduler: (
      data: FilterRecipeTasksDtoForAgentScheduler,
      params: RequestParams = {},
    ) =>
      this.request<ListRecipeTasksResponse, any>({
        path: `/api/recipe/tasks/agent-scheduler/list/ongoing`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerGetHistoryTasksPostForAgentScheduler
     * @summary User gets history tasks for list view
     * @request POST:/api/recipe/tasks/agent-scheduler/list/history
     * @secure
     */
    recipeTaskControllerGetHistoryTasksPostForAgentScheduler: (
      data: FilterRecipeTasksWithPaginationDtoForAgentScheduler,
      params: RequestParams = {},
    ) =>
      this.request<ListRecipeTasksResponse, any>({
        path: `/api/recipe/tasks/agent-scheduler/list/history`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerUpload
     * @summary User uploads an image for recipe task
     * @request POST:/api/recipe/tasks/upload
     * @secure
     */
    recipeTaskControllerUpload: (
      data: {
        files?: File[];
      },
      params: RequestParams = {},
    ) =>
      this.request<UploadImageForRecipeTaskResponse, any>({
        path: `/api/recipe/tasks/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerAddTask
     * @summary User creates a task
     * @request POST:/api/recipe/tasks
     * @secure
     */
    recipeTaskControllerAddTask: (data: CreateRecipeTaskDto, params: RequestParams = {}) =>
      this.request<RecipeTask, any>({
        path: `/api/recipe/tasks`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerDeleteTasks
     * @summary User soft deletes multiple tasks
     * @request DELETE:/api/recipe/tasks
     * @secure
     */
    recipeTaskControllerDeleteTasks: (data: DeleteRecipeTasksDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/recipe/tasks`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerAddFreeTask
     * @summary User creates a task for free (for alpha version)
     * @request POST:/api/recipe/tasks/free-task
     * @secure
     */
    recipeTaskControllerAddFreeTask: (data: CreateFreeRecipeTaskDto, params: RequestParams = {}) =>
      this.request<RecipeTask, any>({
        path: `/api/recipe/tasks/free-task`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerUpdateFreeTask
     * @summary User creates a task for free (for alpha version)
     * @request PATCH:/api/recipe/tasks/free-task/{taskId}
     * @secure
     */
    recipeTaskControllerUpdateFreeTask: (
      taskId: string,
      data: UpdateFreeRecipeTaskDto,
      params: RequestParams = {},
    ) =>
      this.request<RecipeTask, any>({
        path: `/api/recipe/tasks/free-task/${taskId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerUploadImagesForFreeTask
     * @summary User uploads an image for free recipe task
     * @request POST:/api/recipe/tasks/free-task/upload
     * @secure
     */
    recipeTaskControllerUploadImagesForFreeTask: (
      data: {
        captchaToken?: string;
        files?: File[];
      },
      params: RequestParams = {},
    ) =>
      this.request<UploadImageForRecipeTaskResponse, any>({
        path: `/api/recipe/tasks/free-task/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerTransferAnonymousTaskOwner
     * @summary Transfer tasks created by anonymous user to another user
     * @request POST:/api/recipe/tasks/free-task/ownership
     * @secure
     */
    recipeTaskControllerTransferAnonymousTaskOwner: (
      data: TransferAnonymousTaskOwnerDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/recipe/tasks/free-task/ownership`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerReorderTask
     * @summary User reorders tasks
     * @request PUT:/api/recipe/tasks/reorder
     * @secure
     */
    recipeTaskControllerReorderTask: (data: ReOrderRecipeTaskDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/recipe/tasks/reorder`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerRestartTask
     * @summary User restarts a failed task
     * @request PUT:/api/recipe/tasks/{taskId}/restart
     * @secure
     */
    recipeTaskControllerRestartTask: (taskId: string, params: RequestParams = {}) =>
      this.request<RecipeTask, any>({
        path: `/api/recipe/tasks/${taskId}/restart`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerCancel
     * @summary User cancels a queued task
     * @request PUT:/api/recipe/tasks/{taskId}/cancel
     * @secure
     */
    recipeTaskControllerCancel: (taskId: string, params: RequestParams = {}) =>
      this.request<RecipeTask, any>({
        path: `/api/recipe/tasks/${taskId}/cancel`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerFullyDeleteTasks
     * @summary User fully deletes multiple tasks
     * @request POST:/api/recipe/tasks/fully-deletion
     * @secure
     */
    recipeTaskControllerFullyDeleteTasks: (
      data: DeleteRecipeTasksDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/recipe/tasks/fully-deletion`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerRecoverTasks
     * @summary User recover deleted tasks
     * @request POST:/api/recipe/tasks/recover
     * @secure
     */
    recipeTaskControllerRecoverTasks: (data: DeleteRecipeTasksDto, params: RequestParams = {}) =>
      this.request<RecoverRecipeTasksResult, any>({
        path: `/api/recipe/tasks/recover`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerGetRecipeTaskRunInfo
     * @summary Get information to rerun the task
     * @request GET:/api/recipe/tasks/{taskId}/run-info
     * @secure
     */
    recipeTaskControllerGetRecipeTaskRunInfo: (
      taskId: string,
      query?: {
        imageId?: string;
        captchaToken?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetRecipeTaskRunInfoResult, any>({
        path: `/api/recipe/tasks/${taskId}/run-info`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe Tasks
     * @name RecipeTaskControllerToggleFavoriteRecipeTask
     * @request POST:/api/recipe/tasks/{recipeTaskId}/favorite
     * @secure
     */
    recipeTaskControllerToggleFavoriteRecipeTask: (
      recipeTaskId: string,
      params: RequestParams = {},
    ) =>
      this.request<FavoriteResult, any>({
        path: `/api/recipe/tasks/${recipeTaskId}/favorite`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe SD Tasks
     * @name RecipeSdTaskControllerGetOneInQueue
     * @summary Stable Diffusion instance gets one task from queue
     * @request GET:/api/recipe/sd-tasks/one-in-queue
     * @secure
     */
    recipeSdTaskControllerGetOneInQueue: (params: RequestParams = {}) =>
      this.request<GetRecipeTaskForSDResponse, any>({
        path: `/api/recipe/sd-tasks/one-in-queue`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe SD Tasks
     * @name RecipeSdTaskControllerFinish
     * @summary Stable Diffusion instance completed task with metadata
     * @request POST:/api/recipe/sd-tasks/finish/{taskId}
     * @secure
     */
    recipeSdTaskControllerFinish: (
      taskId: string,
      data: CompleteRecipeTaskWithMetadataDto,
      params: RequestParams = {},
    ) =>
      this.request<RecipeTask, any>({
        path: `/api/recipe/sd-tasks/finish/${taskId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recipe SD Tasks
     * @name RecipeSdTaskControllerGetSamEmbedding
     * @summary Stable Diffusion instance completed task with metadata
     * @request POST:/api/recipe/sd-tasks/sam-embedding
     * @secure
     */
    recipeSdTaskControllerGetSamEmbedding: (data: GetSamEmbeddingDto, params: RequestParams = {}) =>
      this.request<GetSamEmbeddingResult, any>({
        path: `/api/recipe/sd-tasks/sam-embedding`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerList
     * @summary User gets all support models
     * @request GET:/api/sd-models/list
     * @secure
     */
    sdModelControllerList: (
      query?: {
        ids?: string[];
        variant?: SDModelVariant;
        baseModel?: SDBaseModel;
        sortBy?: SDModelSortOptions;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetModelsResponse, any>({
        path: `/api/sd-models/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerGetModelById
     * @request GET:/api/sd-models/{modelId}
     * @secure
     */
    sdModelControllerGetModelById: (modelId: string, params: RequestParams = {}) =>
      this.request<SDModelItem, any>({
        path: `/api/sd-models/${modelId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerUpdateModel
     * @request PUT:/api/sd-models/{modelId}
     * @secure
     */
    sdModelControllerUpdateModel: (
      modelId: string,
      data: UpdateSDModelDto,
      params: RequestParams = {},
    ) =>
      this.request<SDModel, any>({
        path: `/api/sd-models/${modelId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerSearchModels
     * @request POST:/api/sd-models/search
     * @secure
     */
    sdModelControllerSearchModels: (data: SearchModelDto, params: RequestParams = {}) =>
      this.request<SearchModelResults, any>({
        path: `/api/sd-models/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerCreateModel
     * @request POST:/api/sd-models
     * @secure
     */
    sdModelControllerCreateModel: (data: CreateSDModelDto, params: RequestParams = {}) =>
      this.request<SDModel, any>({
        path: `/api/sd-models`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerAddModelFromTrainingOutput
     * @request POST:/api/sd-models/training-output
     * @secure
     */
    sdModelControllerAddModelFromTrainingOutput: (
      data: AddModelTrainingDto,
      params: RequestParams = {},
    ) =>
      this.request<SDModel, any>({
        path: `/api/sd-models/training-output`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerSoftDeleteModels
     * @request DELETE:/api/sd-models/soft-delete
     * @secure
     */
    sdModelControllerSoftDeleteModels: (data: DeleteSDModelsDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/sd-models/soft-delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerFullyDeleteModels
     * @request DELETE:/api/sd-models/fully-delete
     * @secure
     */
    sdModelControllerFullyDeleteModels: (data: DeleteSDModelsDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/sd-models/fully-delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerFullyDeleteAllModels
     * @request DELETE:/api/sd-models/fully-delete/all
     * @secure
     */
    sdModelControllerFullyDeleteAllModels: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/sd-models/fully-delete/all`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerRecoverModels
     * @request POST:/api/sd-models/recover
     * @secure
     */
    sdModelControllerRecoverModels: (data: RecoverSDModelsDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/sd-models/recover`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerGetSdModelSharedUsers
     * @request GET:/api/sd-models/{modelId}/permissions
     * @secure
     */
    sdModelControllerGetSdModelSharedUsers: (
      modelId: string,
      query: {
        /** @default 0 */
        skip: number;
        /** @default 10 */
        take: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<SDModelPermission[], any>({
        path: `/api/sd-models/${modelId}/permissions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerShareSdModel
     * @request POST:/api/sd-models/{modelId}/share
     * @secure
     */
    sdModelControllerShareSdModel: (
      modelId: string,
      data: ShareToUsersDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/sd-models/${modelId}/share`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerUnshareSdModel
     * @request DELETE:/api/sd-models/{modelId}/share
     * @secure
     */
    sdModelControllerUnshareSdModel: (
      modelId: string,
      data: UnshareDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/sd-models/${modelId}/share`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerSwitchSharingMode
     * @request POST:/api/sd-models/{modelId}/share/mode
     * @secure
     */
    sdModelControllerSwitchSharingMode: (
      modelId: string,
      data: SwitchWorkspaceSharingModeDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/sd-models/${modelId}/share/mode`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerUpdateWorkspaceSharingRole
     * @request PUT:/api/sd-models/{modelId}/share-by-workspace
     * @secure
     */
    sdModelControllerUpdateWorkspaceSharingRole: (
      modelId: string,
      data: UpdateSharingRoleDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/sd-models/${modelId}/share-by-workspace`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerUpdateSharingRole
     * @request PATCH:/api/sd-models/permission/{permissionId}/role
     * @secure
     */
    sdModelControllerUpdateSharingRole: (
      permissionId: number,
      data: UpdateSharingRoleDto,
      params: RequestParams = {},
    ) =>
      this.request<SDModelPermission, any>({
        path: `/api/sd-models/permission/${permissionId}/role`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerUpdateSdwModelDiscovery
     * @request POST:/api/sd-models/discovery
     * @secure
     */
    sdModelControllerUpdateSdwModelDiscovery: (
      data: UpdateModelDiscoveryDto,
      params: RequestParams = {},
    ) =>
      this.request<number, any>({
        path: `/api/sd-models/discovery`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerAddSdModelToLibrary
     * @request POST:/api/sd-models/{modelId}/add-to-library
     * @secure
     */
    sdModelControllerAddSdModelToLibrary: (modelId: string, params: RequestParams = {}) =>
      this.request<SDModelRoleCapabilities, any>({
        path: `/api/sd-models/${modelId}/add-to-library`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerRemoveSdModelFromLibrary
     * @request POST:/api/sd-models/{modelId}/remove-from-library
     * @secure
     */
    sdModelControllerRemoveSdModelFromLibrary: (modelId: string, params: RequestParams = {}) =>
      this.request<SDModelRoleCapabilities, any>({
        path: `/api/sd-models/${modelId}/remove-from-library`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerAddSdModelsToLibrary
     * @request POST:/api/sd-models/add-to-library
     * @secure
     */
    sdModelControllerAddSdModelsToLibrary: (
      data: AddSDModelsToLibraryDto,
      params: RequestParams = {},
    ) =>
      this.request<AddSDModelsToLibraryResult, any>({
        path: `/api/sd-models/add-to-library`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerRemoveSdModelsFromLibrary
     * @request POST:/api/sd-models/remove-from-library
     * @secure
     */
    sdModelControllerRemoveSdModelsFromLibrary: (
      data: RemoveSDModelsFromLibraryDto,
      params: RequestParams = {},
    ) =>
      this.request<RemoveSDModelsFromLibraryResult, any>({
        path: `/api/sd-models/remove-from-library`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerCreateSdModelComment
     * @request POST:/api/sd-models/{modelId}/comments
     * @secure
     */
    sdModelControllerCreateSdModelComment: (
      modelId: string,
      data: CreateSDModelCommentDto,
      params: RequestParams = {},
    ) =>
      this.request<Comment, any>({
        path: `/api/sd-models/${modelId}/comments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerGetSdModelComments
     * @request GET:/api/sd-models/{modelId}/comments
     * @secure
     */
    sdModelControllerGetSdModelComments: (
      modelId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        parentCommentId?: string;
        commentId?: string;
        notificationId?: string;
        order?: CommentsOrderOption;
        nodeIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCommentsResult, any>({
        path: `/api/sd-models/${modelId}/comments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Models
     * @name SdModelControllerGetSdModelMentionableUsers
     * @request GET:/api/sd-models/{modelId}/comments/mentionable
     * @secure
     */
    sdModelControllerGetSdModelMentionableUsers: (
      modelId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetMentionableUsersResult, any>({
        path: `/api/sd-models/${modelId}/comments/mentionable`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SD Model Upload
     * @name SdModelUploadControllerInitializeMultipartUpload
     * @request POST:/api/sd-models/upload/initialize
     * @secure
     */
    sdModelUploadControllerInitializeMultipartUpload: (
      data: InitializeMultipartModelUploadDto,
      params: RequestParams = {},
    ) =>
      this.request<InitializeMultipartModelUploadResult[], any>({
        path: `/api/sd-models/upload/initialize`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SD Model Upload
     * @name SdModelUploadControllerCompleteMultipartUpload
     * @request POST:/api/sd-models/upload/complete
     * @secure
     */
    sdModelUploadControllerCompleteMultipartUpload: (
      data: CompleteMultipartModelUploadDto[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/sd-models/upload/complete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SD Model Upload
     * @name SdModelUploadControllerAbortMultipartUpload
     * @request POST:/api/sd-models/upload/abort
     * @secure
     */
    sdModelUploadControllerAbortMultipartUpload: (
      data: AbortMultipartModelUploadDto[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/sd-models/upload/abort`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SD Model Upload
     * @name SdModelUploadControllerUploadCallback
     * @request POST:/api/sd-models/upload/callback
     * @secure
     */
    sdModelUploadControllerUploadCallback: (
      data: UploadCompletedMessageBody,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/sd-models/upload/callback`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Model Training
     * @name SdModelTrainingControllerSearch
     * @request POST:/api/sd-models/training/search
     * @secure
     */
    sdModelTrainingControllerSearch: (data: SearchModelTrainingDto, params: RequestParams = {}) =>
      this.request<SearchModelTrainingResults, any>({
        path: `/api/sd-models/training/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Model Training
     * @name SdModelTrainingControllerCreateDraft
     * @request POST:/api/sd-models/training/draft
     * @secure
     */
    sdModelTrainingControllerCreateDraft: (params: RequestParams = {}) =>
      this.request<SDModelTraining, any>({
        path: `/api/sd-models/training/draft`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Model Training
     * @name SdModelTrainingControllerGetTraining
     * @request GET:/api/sd-models/training/{trainId}
     * @secure
     */
    sdModelTrainingControllerGetTraining: (trainId: string, params: RequestParams = {}) =>
      this.request<SDModelTrainingItem, any>({
        path: `/api/sd-models/training/${trainId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Model Training
     * @name SdModelTrainingControllerUpdateDraft
     * @request PUT:/api/sd-models/training/{trainId}
     * @secure
     */
    sdModelTrainingControllerUpdateDraft: (
      trainId: string,
      data: UpdateSDModelTrainingDto,
      params: RequestParams = {},
    ) =>
      this.request<SDModelTraining, any>({
        path: `/api/sd-models/training/${trainId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Model Training
     * @name SdModelTrainingControllerEstimateCredits
     * @request POST:/api/sd-models/training/estimate-credits
     * @secure
     */
    sdModelTrainingControllerEstimateCredits: (
      data: EstimateTrainingCreditsDto,
      params: RequestParams = {},
    ) =>
      this.request<EstimateTrainingCreditsResponse, any>({
        path: `/api/sd-models/training/estimate-credits`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Model Training
     * @name SdModelTrainingControllerStartTraining
     * @request POST:/api/sd-models/training/{trainId}/start
     * @secure
     */
    sdModelTrainingControllerStartTraining: (trainId: string, params: RequestParams = {}) =>
      this.request<SDModelTraining, any>({
        path: `/api/sd-models/training/${trainId}/start`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Model Training
     * @name SdModelTrainingControllerDuplicateTraining
     * @request POST:/api/sd-models/training/{trainId}/duplicate
     * @secure
     */
    sdModelTrainingControllerDuplicateTraining: (trainId: string, params: RequestParams = {}) =>
      this.request<SDModelTraining, any>({
        path: `/api/sd-models/training/${trainId}/duplicate`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Model Training
     * @name SdModelTrainingControllerUpdateProgress
     * @request POST:/api/sd-models/training/callback
     * @secure
     */
    sdModelTrainingControllerUpdateProgress: (
      data: UpdateSDModelTrainingProgressDto,
      params: RequestParams = {},
    ) =>
      this.request<SDModelTraining, any>({
        path: `/api/sd-models/training/callback`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Model Training
     * @name SdModelTrainingControllerGenerateUploadUrl
     * @request POST:/api/sd-models/training/upload-url
     * @secure
     */
    sdModelTrainingControllerGenerateUploadUrl: (
      data: GenerateUploadUrlDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/sd-models/training/upload-url`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Model Training
     * @name SdModelTrainingControllerAddTask
     * @request POST:/api/sd-models/training/task
     * @secure
     */
    sdModelTrainingControllerAddTask: (
      data: CreateSDModelTrainingRecipeTaskDto,
      params: RequestParams = {},
    ) =>
      this.request<RecipeTask, any>({
        path: `/api/sd-models/training/task`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Model Training
     * @name SdModelTrainingControllerSoftDeleteModels
     * @request DELETE:/api/sd-models/training/soft-delete
     * @secure
     */
    sdModelTrainingControllerSoftDeleteModels: (
      data: DeleteSDModelTrainingsDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/sd-models/training/soft-delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Model Training
     * @name SdModelTrainingControllerFullyDeleteModels
     * @request DELETE:/api/sd-models/training/fully-delete
     * @secure
     */
    sdModelTrainingControllerFullyDeleteModels: (
      data: DeleteSDModelTrainingsDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/sd-models/training/fully-delete`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Model Training
     * @name SdModelTrainingControllerFullyDeleteAllModels
     * @request DELETE:/api/sd-models/training/fully-delete/all
     * @secure
     */
    sdModelTrainingControllerFullyDeleteAllModels: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/sd-models/training/fully-delete/all`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stable Diffusion Model Training
     * @name SdModelTrainingControllerRecoverModels
     * @request POST:/api/sd-models/training/recover
     * @secure
     */
    sdModelTrainingControllerRecoverModels: (
      data: RecoverSDModelTrainingsDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/sd-models/training/recover`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Short URL
     * @name ShortUrlControllerGetShortUrl
     * @request GET:/api/short-url/{shortUrl}
     * @secure
     */
    shortUrlControllerGetShortUrl: (shortUrl: string, params: RequestParams = {}) =>
      this.request<ShortUrl, any>({
        path: `/api/short-url/${shortUrl}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Short URL
     * @name ShortUrlControllerCreateShortUrl
     * @request POST:/api/short-url
     * @secure
     */
    shortUrlControllerCreateShortUrl: (data: CreateShortUrlDto, params: RequestParams = {}) =>
      this.request<ShortUrl, any>({
        path: `/api/short-url`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recent Access
     * @name RecentAccessControllerGetRecentFolder
     * @request GET:/api/recent-access/folders
     * @secure
     */
    recentAccessControllerGetRecentFolder: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        /** @format date-time */
        fromDate?: string;
        /** @format date-time */
        toDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RecentFolderItem[], any>({
        path: `/api/recent-access/folders`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recent Access
     * @name RecentAccessControllerGetRecentImages
     * @request GET:/api/recent-access/images
     * @secure
     */
    recentAccessControllerGetRecentImages: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        /** @format date-time */
        fromDate?: string;
        /** @format date-time */
        toDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RecentImageItem[], any>({
        path: `/api/recent-access/images`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recent Access
     * @name RecentAccessControllerGetRecentWorkflows
     * @request GET:/api/recent-access/workflows
     * @secure
     */
    recentAccessControllerGetRecentWorkflows: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        /** @format date-time */
        fromDate?: string;
        /** @format date-time */
        toDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RecentWorkflowItem[], any>({
        path: `/api/recent-access/workflows`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recent Access
     * @name RecentAccessControllerGetRecentRecipe
     * @request GET:/api/recent-access/recipes
     * @secure
     */
    recentAccessControllerGetRecentRecipe: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        /** @format date-time */
        fromDate?: string;
        /** @format date-time */
        toDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RecentRecipeItem[], any>({
        path: `/api/recent-access/recipes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recent Access
     * @name RecentAccessControllerGetRecentStyles
     * @request GET:/api/recent-access/styles
     * @secure
     */
    recentAccessControllerGetRecentStyles: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        /** @format date-time */
        fromDate?: string;
        /** @format date-time */
        toDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RecentStyleItem[], any>({
        path: `/api/recent-access/styles`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recent Access
     * @name RecentAccessControllerGetRecentWildcards
     * @request GET:/api/recent-access/wildcards
     * @secure
     */
    recentAccessControllerGetRecentWildcards: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        /** @format date-time */
        fromDate?: string;
        /** @format date-time */
        toDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RecentWildcardItem[], any>({
        path: `/api/recent-access/wildcards`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recent Access
     * @name RecentAccessControllerGetRecentSdWorkflows
     * @request GET:/api/recent-access/sd-workflows
     * @secure
     */
    recentAccessControllerGetRecentSdWorkflows: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        /** @format date-time */
        fromDate?: string;
        /** @format date-time */
        toDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RecentSDWorkflowItem[], any>({
        path: `/api/recent-access/sd-workflows`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recent Access
     * @name RecentAccessControllerGetRecentModels
     * @request GET:/api/recent-access/models
     * @secure
     */
    recentAccessControllerGetRecentModels: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        /** @format date-time */
        fromDate?: string;
        /** @format date-time */
        toDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RecentModelItem[], any>({
        path: `/api/recent-access/models`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Recent Access
     * @name RecentAccessControllerGetRecentAccessItems
     * @request GET:/api/recent-access
     * @secure
     */
    recentAccessControllerGetRecentAccessItems: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        /** @format date-time */
        fromDate?: string;
        /** @format date-time */
        toDate?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RecentAccessItem[], any>({
        path: `/api/recent-access`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global search
     * @name SearchControllerGlobalSearch
     * @request GET:/api/search
     * @secure
     */
    searchControllerGlobalSearch: (
      query: {
        searchTerm: string;
        /** @default 0 */
        skip?: number;
        /** @default 3 */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GlobalSearchResult, any>({
        path: `/api/search`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Global search
     * @name SearchControllerSearchInspirationBoard
     * @request GET:/api/search/inspiration-board
     * @secure
     */
    searchControllerSearchInspirationBoard: (
      query: {
        searchTerm: string;
        /** @default 0 */
        skip?: number;
        /** @default 3 */
        take?: number;
        entityType: InspirationBoardSearchEntity;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchInspirationBoardResult, any>({
        path: `/api/search/inspiration-board`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alpha Image
     * @name AlphaImageControllerGetImageDetail
     * @request GET:/api/alpha/images/{imageId}
     * @secure
     */
    alphaImageControllerGetImageDetail: (imageId: string, params: RequestParams = {}) =>
      this.request<AlphaImageDetail, any>({
        path: `/api/alpha/images/${imageId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alpha Image
     * @name AlphaImageControllerGetImageComments
     * @request GET:/api/alpha/images/{imageId}/comments
     * @secure
     */
    alphaImageControllerGetImageComments: (
      imageId: string,
      query?: {
        parentCommentId?: string | null;
        sortBy?: CommentsSortBy;
        /** @default 0 */
        skip?: number;
        /** @default 5 */
        take?: number;
        notificationId?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCommentsResult, any>({
        path: `/api/alpha/images/${imageId}/comments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alpha Image
     * @name AlphaImageControllerCreateImageComment
     * @request POST:/api/alpha/images/{imageId}/comments
     * @secure
     */
    alphaImageControllerCreateImageComment: (
      imageId: string,
      data: CommentImageDto,
      params: RequestParams = {},
    ) =>
      this.request<Comment, any>({
        path: `/api/alpha/images/${imageId}/comments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alpha Image
     * @name AlphaImageControllerGetImageMentionable
     * @request GET:/api/alpha/images/{imageId}/mentionable
     * @secure
     */
    alphaImageControllerGetImageMentionable: (imageId: string, params: RequestParams = {}) =>
      this.request<User[], any>({
        path: `/api/alpha/images/${imageId}/mentionable`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alpha Image
     * @name AlphaImageControllerLikeComment
     * @request POST:/api/alpha/images/{commentId}/like
     * @secure
     */
    alphaImageControllerLikeComment: (commentId: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/alpha/images/${commentId}/like`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Referral
     * @name ReferralControllerAddReferral
     * @request POST:/api/alpha/referral
     * @secure
     */
    referralControllerAddReferral: (data: AddReferralDto, params: RequestParams = {}) =>
      this.request<Referral, any>({
        path: `/api/alpha/referral`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Generation
     * @name GenerationTrackerControllerIncreaseGenerationBySharing
     * @request POST:/api/alpha/generation/sharing
     * @secure
     */
    generationTrackerControllerIncreaseGenerationBySharing: (
      data: IncreaseGenerationBySharingDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/alpha/generation/sharing`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name FeedbackControllerGetFeedback
     * @request GET:/api/alpha/feedback
     * @secure
     */
    feedbackControllerGetFeedback: (params: RequestParams = {}) =>
      this.request<Feedback[], any>({
        path: `/api/alpha/feedback`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name FeedbackControllerRate
     * @request POST:/api/alpha/feedback
     * @secure
     */
    feedbackControllerRate: (data: RateDto, params: RequestParams = {}) =>
      this.request<Feedback, any>({
        path: `/api/alpha/feedback`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags File Upload
     * @name UploadControllerInitializeMultipartUpload
     * @request POST:/api/upload/initialize
     * @secure
     */
    uploadControllerInitializeMultipartUpload: (
      data: InitializeMultipartUploadDto,
      params: RequestParams = {},
    ) =>
      this.request<InitializeMultipartUploadResult[], any>({
        path: `/api/upload/initialize`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags File Upload
     * @name UploadControllerCompleteMultipartUpload
     * @request POST:/api/upload/complete
     * @secure
     */
    uploadControllerCompleteMultipartUpload: (
      data: CompleteMultipartUploadDto[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/upload/complete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags File Upload
     * @name UploadControllerAbortMultipartUpload
     * @request POST:/api/upload/abort
     * @secure
     */
    uploadControllerAbortMultipartUpload: (
      data: AbortMultipartUploadDto[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/upload/abort`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerGetEmbeddings
     * @summary List embedding models
     * @request GET:/api/comfyui/embeddings
     * @secure
     */
    comfyUiControllerGetEmbeddings: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/api/comfyui/embeddings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerGetNodeInfos
     * @summary List all nodes
     * @request GET:/api/comfyui/object_info
     * @secure
     */
    comfyUiControllerGetNodeInfos: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/comfyui/object_info`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerGetNodeInfosLastUpdate
     * @summary Get last update of object infos
     * @request GET:/api/comfyui/object_info_update
     * @secure
     */
    comfyUiControllerGetNodeInfosLastUpdate: (params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/comfyui/object_info_update`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerGetModels
     * @summary Get model object info (non-cached & different for each user)
     * @request GET:/api/comfyui/models
     * @secure
     */
    comfyUiControllerGetModels: (params: RequestParams = {}) =>
      this.request<SDModel[], any>({
        path: `/api/comfyui/models`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerGetSytemStats
     * @request GET:/api/comfyui/system_stats
     * @secure
     */
    comfyUiControllerGetSytemStats: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/comfyui/system_stats`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerGetUsers
     * @request GET:/api/comfyui/users
     * @secure
     */
    comfyUiControllerGetUsers: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/comfyui/users`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerGetSettings
     * @request GET:/api/comfyui/settings
     * @secure
     */
    comfyUiControllerGetSettings: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/comfyui/settings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerReplaceSettings
     * @request POST:/api/comfyui/settings
     * @secure
     */
    comfyUiControllerReplaceSettings: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/comfyui/settings`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerGetSetting
     * @request GET:/api/comfyui/settings/{key}
     * @secure
     */
    comfyUiControllerGetSetting: (key: string, params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/comfyui/settings/${key}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerUpdateSetting
     * @request POST:/api/comfyui/settings/{key}
     * @secure
     */
    comfyUiControllerUpdateSetting: (
      key: string,
      data: UpdateSettingDto,
      params: RequestParams = {},
    ) =>
      this.request<Record<string, any>, any>({
        path: `/api/comfyui/settings/${key}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerGetUserData
     * @request GET:/api/comfyui/userdata/{key}
     * @secure
     */
    comfyUiControllerGetUserData: (key: string, params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/comfyui/userdata/${key}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerSaveUserData
     * @request POST:/api/comfyui/userdata/{key}
     * @secure
     */
    comfyUiControllerSaveUserData: (key: string, params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/comfyui/userdata/${key}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerGetRgthreeConfig
     * @request GET:/api/comfyui/rgthree/api/config
     * @secure
     */
    comfyUiControllerGetRgthreeConfig: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/comfyui/rgthree/api/config`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerSaveRgthreeConfig
     * @request POST:/api/comfyui/rgthree/api/config
     * @secure
     */
    comfyUiControllerSaveRgthreeConfig: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/comfyui/rgthree/api/config`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerGetQueueStatus
     * @request GET:/api/comfyui/prompt
     * @secure
     */
    comfyUiControllerGetQueueStatus: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/comfyui/prompt`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerQueuePrompt
     * @summary Queue task
     * @request POST:/api/comfyui/prompt
     * @secure
     */
    comfyUiControllerQueuePrompt: (data: QueuePromptDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/comfyui/prompt`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerInterruptRunningTasks
     * @request POST:/api/comfyui/interrupt
     * @secure
     */
    comfyUiControllerInterruptRunningTasks: (params: RequestParams = {}) =>
      this.request<number, any>({
        path: `/api/comfyui/interrupt`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerClearPendingTasks
     * @request POST:/api/comfyui/clear-pending-tasks
     * @secure
     */
    comfyUiControllerClearPendingTasks: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/comfyui/clear-pending-tasks`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerGetQueue
     * @request GET:/api/comfyui/queue
     * @secure
     */
    comfyUiControllerGetQueue: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/comfyui/queue`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerGetHistory
     * @request GET:/api/comfyui/history
     * @secure
     */
    comfyUiControllerGetHistory: (params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/api/comfyui/history`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerUploadImage
     * @summary Upload image
     * @request POST:/api/comfyui/upload/image
     * @secure
     */
    comfyUiControllerUploadImage: (
      data: {
        /** @format binary */
        image?: File;
        subfolder?: string | null;
        temp?: boolean | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<UploadImageResponse, any>({
        path: `/api/comfyui/upload/image`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerUploadMask
     * @summary Upload mask image
     * @request POST:/api/comfyui/upload/mask
     * @secure
     */
    comfyUiControllerUploadMask: (
      data: {
        /** @format binary */
        image?: File;
        /** @format binary */
        mask?: File;
        subfolder?: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<UploadImageResponse, any>({
        path: `/api/comfyui/upload/mask`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerViewImage
     * @request GET:/api/comfyui/view
     * @secure
     */
    comfyUiControllerViewImage: (
      query: {
        subfolder: string;
        filename: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, any>, any>({
        path: `/api/comfyui/view`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerPysssssAutoComplete
     * @request GET:/api/comfyui/pysssss/autocomplete
     * @secure
     */
    comfyUiControllerPysssssAutoComplete: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/comfyui/pysssss/autocomplete`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI
     * @name ComfyUiControllerGetConnectedUsers
     * @request GET:/api/comfyui/{workflowId}/connected-users
     * @secure
     */
    comfyUiControllerGetConnectedUsers: (workflowId: string, params: RequestParams = {}) =>
      this.request<ComfyRoomConnectedUser[], any>({
        path: `/api/comfyui/${workflowId}/connected-users`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name ArticleControllerGetArticleById
     * @request GET:/api/articles/{articleId}
     * @secure
     */
    articleControllerGetArticleById: (articleId: string, params: RequestParams = {}) =>
      this.request<ArticleDetail, any>({
        path: `/api/articles/${articleId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name ArticleControllerUpdateArticle
     * @request PATCH:/api/articles/{articleId}
     * @secure
     */
    articleControllerUpdateArticle: (
      articleId: string,
      data: UpdateArticleDto,
      params: RequestParams = {},
    ) =>
      this.request<Article, any>({
        path: `/api/articles/${articleId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name ArticleControllerDeleteArticle
     * @request DELETE:/api/articles/{articleId}
     * @secure
     */
    articleControllerDeleteArticle: (articleId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/articles/${articleId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name ArticleControllerGetArticles
     * @request GET:/api/articles
     * @secure
     */
    articleControllerGetArticles: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        searchTerm?: string;
        tagIds?: number[];
        /** @default false */
        onlyMine?: boolean;
        category?: ArticleCategory[];
        /** @default false */
        onlyFavorite?: boolean;
        sortBy?: SortOptions;
        creatorUid?: string;
        statuses?: ArticleStatus[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetArticlesResult, any>({
        path: `/api/articles`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name ArticleControllerCreateArticle
     * @request POST:/api/articles
     * @secure
     */
    articleControllerCreateArticle: (data: CreateArticleDto, params: RequestParams = {}) =>
      this.request<Article, any>({
        path: `/api/articles`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name ArticleControllerToggleArticleReaction
     * @request POST:/api/articles/{articleId}/reaction
     * @secure
     */
    articleControllerToggleArticleReaction: (
      articleId: string,
      data: ToggleArticleReactionDto,
      params: RequestParams = {},
    ) =>
      this.request<ToggleReactionResult, any>({
        path: `/api/articles/${articleId}/reaction`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name ArticleControllerIncreaseArticleView
     * @request POST:/api/articles/{articleId}/view
     * @secure
     */
    articleControllerIncreaseArticleView: (articleId: string, params: RequestParams = {}) =>
      this.request<ToggleReactionResult, any>({
        path: `/api/articles/${articleId}/view`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name ArticleControllerCreateArticleComment
     * @request POST:/api/articles/{articleId}/comments
     * @secure
     */
    articleControllerCreateArticleComment: (
      articleId: string,
      data: CreateArticleCommentDto,
      params: RequestParams = {},
    ) =>
      this.request<Comment, any>({
        path: `/api/articles/${articleId}/comments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name ArticleControllerGetArticleComment
     * @request GET:/api/articles/{articleId}/comments
     * @secure
     */
    articleControllerGetArticleComment: (
      articleId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        parentCommentId?: string;
        commentId?: string;
        notificationId?: string;
        order?: CommentsOrderOption;
        nodeIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCommentsResult, any>({
        path: `/api/articles/${articleId}/comments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name ArticleControllerGetArticleMentionable
     * @request GET:/api/articles/{articleId}/mentionable
     * @secure
     */
    articleControllerGetArticleMentionable: (
      articleId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetMentionableUsersResult, any>({
        path: `/api/articles/${articleId}/mentionable`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name ArticleControllerToggleFavoriteArticle
     * @request POST:/api/articles/{articleId}/favorite
     * @secure
     */
    articleControllerToggleFavoriteArticle: (articleId: string, params: RequestParams = {}) =>
      this.request<FavoriteResult, any>({
        path: `/api/articles/${articleId}/favorite`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name ArticleControllerIncreaseArticleShareCount
     * @request POST:/api/articles/share/count
     * @secure
     */
    articleControllerIncreaseArticleShareCount: (
      data: IncreaseArticlesShareCountDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/articles/share/count`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Article
     * @name ArticleControllerGetArticlePreviewInfo
     * @request GET:/api/articles/{articleId}/preview-info
     * @secure
     */
    articleControllerGetArticlePreviewInfo: (articleId: string, params: RequestParams = {}) =>
      this.request<ArticlePreviewInfo, any>({
        path: `/api/articles/${articleId}/preview-info`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alpha Subscription
     * @name SubscriptionControllerSubscribe
     * @request POST:/api/alpha-subscription
     * @secure
     */
    subscriptionControllerSubscribe: (params: RequestParams = {}) =>
      this.request<AlphaSubscription, any>({
        path: `/api/alpha-subscription`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Artboard
     * @name ArtboardControllerGetArtboardById
     * @request GET:/api/artboards/{artboardId}
     * @secure
     */
    artboardControllerGetArtboardById: (artboardId: string, params: RequestParams = {}) =>
      this.request<ArtboardDetail, any>({
        path: `/api/artboards/${artboardId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Artboard
     * @name ArtboardControllerUpdateArtboard
     * @request PATCH:/api/artboards/{artboardId}
     * @secure
     */
    artboardControllerUpdateArtboard: (
      artboardId: string,
      data: UpdateArtboardDto,
      params: RequestParams = {},
    ) =>
      this.request<Artboard, any>({
        path: `/api/artboards/${artboardId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Artboard
     * @name ArtboardControllerGetArtboardHistory
     * @request GET:/api/artboards/{artboardId}/history
     * @secure
     */
    artboardControllerGetArtboardHistory: (
      artboardId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ArtboardDetail, any>({
        path: `/api/artboards/${artboardId}/history`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Artboard
     * @name ArtboardControllerCreateArtboard
     * @request POST:/api/artboards
     * @secure
     */
    artboardControllerCreateArtboard: (data: CreateArtboardDto, params: RequestParams = {}) =>
      this.request<Artboard, any>({
        path: `/api/artboards`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Artboard
     * @name ArtboardControllerCreateArtboardRecipeTasks
     * @request POST:/api/artboards/{artboardId}/recipe-tasks
     * @secure
     */
    artboardControllerCreateArtboardRecipeTasks: (
      artboardId: string,
      data: CreateArtboardRecipeTasksDto,
      params: RequestParams = {},
    ) =>
      this.request<ArtboardRecipeTask[], any>({
        path: `/api/artboards/${artboardId}/recipe-tasks`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Artboard
     * @name ArtboardControllerToggleFavoriteArtboard
     * @request POST:/api/artboards/{artboardId}/favorites
     * @secure
     */
    artboardControllerToggleFavoriteArtboard: (artboardId: string, params: RequestParams = {}) =>
      this.request<FavoriteResult, any>({
        path: `/api/artboards/${artboardId}/favorites`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Artboard
     * @name ArtboardControllerGetArtboardComment
     * @request GET:/api/artboards/{artboardId}/comments
     * @secure
     */
    artboardControllerGetArtboardComment: (
      artboardId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        parentCommentId?: string;
        commentId?: string;
        notificationId?: string;
        order?: CommentsOrderOption;
        nodeIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCommentsResult, any>({
        path: `/api/artboards/${artboardId}/comments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Artboard
     * @name ArtboardControllerCreateArtboardComment
     * @request POST:/api/artboards/{artboardId}/comments
     * @secure
     */
    artboardControllerCreateArtboardComment: (
      artboardId: string,
      data: CreateArtboardCommentDto,
      params: RequestParams = {},
    ) =>
      this.request<Artboard, any>({
        path: `/api/artboards/${artboardId}/comments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Artboard
     * @name ArtboardControllerGetArtboardCommentMentionable
     * @request GET:/api/artboards/{artboardId}/comments/mentionable
     * @secure
     */
    artboardControllerGetArtboardCommentMentionable: (
      artboardId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetArtboardCommentMentionableResponse, any>({
        path: `/api/artboards/${artboardId}/comments/mentionable`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Miss Universe
     * @name MissUniverseControllerGetMissUniverseContestantById
     * @request GET:/api/miss-universe/{contestantId}
     * @secure
     */
    missUniverseControllerGetMissUniverseContestantById: (
      contestantId: number,
      params: RequestParams = {},
    ) =>
      this.request<MissUniverseContestantDetail, any>({
        path: `/api/miss-universe/${contestantId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Miss Universe
     * @name MissUniverseControllerGetMissUniverseContestants
     * @request GET:/api/miss-universe
     * @secure
     */
    missUniverseControllerGetMissUniverseContestants: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        imageIds?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetMissUniverseContestantsResult, any>({
        path: `/api/miss-universe`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Miss Universe
     * @name MissUniverseControllerCreateMissUniverseContestant
     * @request POST:/api/miss-universe
     * @secure
     */
    missUniverseControllerCreateMissUniverseContestant: (
      data: CreateContestantDto,
      params: RequestParams = {},
    ) =>
      this.request<MissUniverseContestant, any>({
        path: `/api/miss-universe`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Miss Universe
     * @name MissUniverseControllerGetUserMissUniverseContestant
     * @request GET:/api/miss-universe/participation/me
     * @secure
     */
    missUniverseControllerGetUserMissUniverseContestant: (params: RequestParams = {}) =>
      this.request<MissUniverseContestantDetail, any>({
        path: `/api/miss-universe/participation/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Miss Universe
     * @name MissUniverseControllerGetUserVotes
     * @request GET:/api/miss-universe/votes/me
     * @secure
     */
    missUniverseControllerGetUserVotes: (params: RequestParams = {}) =>
      this.request<ImageVote[], any>({
        path: `/api/miss-universe/votes/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Miss Universe
     * @name MissUniverseControllerGetTopVotedNations
     * @request GET:/api/miss-universe/votes/top-nations
     * @secure
     */
    missUniverseControllerGetTopVotedNations: (params: RequestParams = {}) =>
      this.request<NationVoteDetail[], any>({
        path: `/api/miss-universe/votes/top-nations`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Miss Universe
     * @name MissUniverseControllerVoteMissUniverseContestant
     * @request POST:/api/miss-universe/votes/{contestantId}
     * @secure
     */
    missUniverseControllerVoteMissUniverseContestant: (
      contestantId: number,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/miss-universe/votes/${contestantId}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Miss Universe
     * @name MissUniverseControllerCheckUserParticipation
     * @request POST:/api/miss-universe/participation
     * @secure
     */
    missUniverseControllerCheckUserParticipation: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/miss-universe/participation`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerList
     * @request POST:/api/sd-styles/list
     * @secure
     */
    sdStyleControllerList: (data: SearchSDStyleDto, params: RequestParams = {}) =>
      this.request<SearchSDStyleResponse, any>({
        path: `/api/sd-styles/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerGetStyleTags
     * @request GET:/api/sd-styles/tags
     * @secure
     */
    sdStyleControllerGetStyleTags: (params: RequestParams = {}) =>
      this.request<SDStyleTagsResponse, any>({
        path: `/api/sd-styles/tags`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerTagSdStyles
     * @request POST:/api/sd-styles/tags
     * @secure
     */
    sdStyleControllerTagSdStyles: (data: TagSDStyleDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/sd-styles/tags`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerUntagSdStyles
     * @request DELETE:/api/sd-styles/tags
     * @secure
     */
    sdStyleControllerUntagSdStyles: (data: UntagSDStyleDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/sd-styles/tags`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerGetStyle
     * @request GET:/api/sd-styles/{styleId}
     * @secure
     */
    sdStyleControllerGetStyle: (styleId: string, params: RequestParams = {}) =>
      this.request<SDStyleDetail, any>({
        path: `/api/sd-styles/${styleId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerSaveStyle
     * @request PUT:/api/sd-styles/{styleId}
     * @secure
     */
    sdStyleControllerSaveStyle: (
      styleId: string,
      data: UpdateSDStyleDto,
      params: RequestParams = {},
    ) =>
      this.request<SDStyle, any>({
        path: `/api/sd-styles/${styleId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerCreateDraft
     * @request POST:/api/sd-styles
     * @secure
     */
    sdStyleControllerCreateDraft: (data: UpdateSDStyleDto, params: RequestParams = {}) =>
      this.request<SDStyleDetail, any>({
        path: `/api/sd-styles`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerDuplicateStyle
     * @request POST:/api/sd-styles/{styleId}/duplicate
     * @secure
     */
    sdStyleControllerDuplicateStyle: (styleId: string, params: RequestParams = {}) =>
      this.request<SDStyleDetail, any>({
        path: `/api/sd-styles/${styleId}/duplicate`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerRenameStyle
     * @request POST:/api/sd-styles/{styleId}/rename
     * @secure
     */
    sdStyleControllerRenameStyle: (
      styleId: string,
      data: RenameSDStyleDto,
      params: RequestParams = {},
    ) =>
      this.request<SDStyle, any>({
        path: `/api/sd-styles/${styleId}/rename`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerAddStyleImages
     * @request PUT:/api/sd-styles/{styleId}/images
     * @secure
     */
    sdStyleControllerAddStyleImages: (
      styleId: string,
      data: AddSDStyleImagesDto,
      params: RequestParams = {},
    ) =>
      this.request<SDStyle, any>({
        path: `/api/sd-styles/${styleId}/images`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerGetStyleSharedUsers
     * @request GET:/api/sd-styles/{styleId}/permissions
     * @secure
     */
    sdStyleControllerGetStyleSharedUsers: (
      styleId: string,
      query: {
        /** @default 0 */
        skip: number;
        /** @default 10 */
        take: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<SDStylePermission[], any>({
        path: `/api/sd-styles/${styleId}/permissions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerShareStyle
     * @request POST:/api/sd-styles/{styleId}/share
     * @secure
     */
    sdStyleControllerShareStyle: (
      styleId: string,
      data: ShareToUsersDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/sd-styles/${styleId}/share`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerUnshareStyle
     * @request DELETE:/api/sd-styles/{styleId}/share
     * @secure
     */
    sdStyleControllerUnshareStyle: (
      styleId: string,
      data: UnshareDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/sd-styles/${styleId}/share`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerSwitchSharingMode
     * @request POST:/api/sd-styles/{styleId}/share/mode
     * @secure
     */
    sdStyleControllerSwitchSharingMode: (
      styleId: string,
      data: SwitchWorkspaceSharingModeDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/sd-styles/${styleId}/share/mode`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerUpdateWorkspaceSharingRole
     * @request PUT:/api/sd-styles/{styleId}/share-by-workspace
     * @secure
     */
    sdStyleControllerUpdateWorkspaceSharingRole: (
      styleId: string,
      data: UpdateSharingRoleDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/sd-styles/${styleId}/share-by-workspace`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerUpdateSharingRole
     * @request PATCH:/api/sd-styles/permission/{permissionId}/role
     * @secure
     */
    sdStyleControllerUpdateSharingRole: (
      permissionId: number,
      data: UpdateSharingRoleDto,
      params: RequestParams = {},
    ) =>
      this.request<SDStylePermission, any>({
        path: `/api/sd-styles/permission/${permissionId}/role`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerAddStyleToLibrary
     * @request POST:/api/sd-styles/{styleId}/add-to-library
     * @secure
     */
    sdStyleControllerAddStyleToLibrary: (styleId: string, params: RequestParams = {}) =>
      this.request<SDStyleRoleCapabilities, any>({
        path: `/api/sd-styles/${styleId}/add-to-library`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerRemoveStyleFromLibrary
     * @request POST:/api/sd-styles/{styleId}/remove-from-library
     * @secure
     */
    sdStyleControllerRemoveStyleFromLibrary: (styleId: string, params: RequestParams = {}) =>
      this.request<SDStyleRoleCapabilities, any>({
        path: `/api/sd-styles/${styleId}/remove-from-library`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerAddStylesToLibrary
     * @request POST:/api/sd-styles/add-to-library
     * @secure
     */
    sdStyleControllerAddStylesToLibrary: (
      data: AddStylesToLibraryDto,
      params: RequestParams = {},
    ) =>
      this.request<AddStylesToLibraryResult, any>({
        path: `/api/sd-styles/add-to-library`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerRemoveStylesFromLibrary
     * @request POST:/api/sd-styles/remove-from-library
     * @secure
     */
    sdStyleControllerRemoveStylesFromLibrary: (
      data: RemoveStylesFromLibraryDto,
      params: RequestParams = {},
    ) =>
      this.request<RemoveStylesFromLibraryResult, any>({
        path: `/api/sd-styles/remove-from-library`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerSoftDeleteStyles
     * @request DELETE:/api/sd-styles/soft-deletion
     * @secure
     */
    sdStyleControllerSoftDeleteStyles: (data: DeleteSDStylesDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/sd-styles/soft-deletion`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerRecoverStyles
     * @request POST:/api/sd-styles/recover
     * @secure
     */
    sdStyleControllerRecoverStyles: (data: RecoverDeletedSDStylesDto, params: RequestParams = {}) =>
      this.request<RecoverSDStylesResult, any>({
        path: `/api/sd-styles/recover`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerFullyDeleteStyles
     * @request POST:/api/sd-styles/full-deletion
     * @secure
     */
    sdStyleControllerFullyDeleteStyles: (data: DeleteSDStylesDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/sd-styles/full-deletion`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerFullyDeleteAllStyles
     * @request DELETE:/api/sd-styles/full-deletion/all
     * @secure
     */
    sdStyleControllerFullyDeleteAllStyles: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/sd-styles/full-deletion/all`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerToggleFavoriteStyle
     * @request POST:/api/sd-styles/{sdStyleId}/favorite
     * @secure
     */
    sdStyleControllerToggleFavoriteStyle: (sdStyleId: string, params: RequestParams = {}) =>
      this.request<FavoriteResult, any>({
        path: `/api/sd-styles/${sdStyleId}/favorite`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerUpdateDiscoveryStyle
     * @request POST:/api/sd-styles/discovery
     * @secure
     */
    sdStyleControllerUpdateDiscoveryStyle: (
      data: UpdateSDStyleDiscoveryDto,
      params: RequestParams = {},
    ) =>
      this.request<number, any>({
        path: `/api/sd-styles/discovery`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerCreateSdStyleComment
     * @request POST:/api/sd-styles/{sdStyleId}/comments
     * @secure
     */
    sdStyleControllerCreateSdStyleComment: (
      sdStyleId: string,
      data: CreateSDStyleCommentDto,
      params: RequestParams = {},
    ) =>
      this.request<Comment, any>({
        path: `/api/sd-styles/${sdStyleId}/comments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerGetSdStyleComments
     * @request GET:/api/sd-styles/{sdStyleId}/comments
     * @secure
     */
    sdStyleControllerGetSdStyleComments: (
      sdStyleId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        parentCommentId?: string;
        commentId?: string;
        notificationId?: string;
        order?: CommentsOrderOption;
        nodeIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCommentsResult, any>({
        path: `/api/sd-styles/${sdStyleId}/comments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SDStyle
     * @name SdStyleControllerGetSdStyleMentionableUsers
     * @request GET:/api/sd-styles/{sdStyleId}/comments/mentionable
     * @secure
     */
    sdStyleControllerGetSdStyleMentionableUsers: (
      sdStyleId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetMentionableUsersResult, any>({
        path: `/api/sd-styles/${sdStyleId}/comments/mentionable`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore SD Style
     * @name ExploreSdStyleControllerGetExploreSdStyles
     * @request GET:/api/explore/sd-styles
     * @secure
     */
    exploreSdStyleControllerGetExploreSdStyles: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        searchTerm?: string;
        ids?: string[];
        sortBy?: SortOptions;
        onlyFavorite?: boolean;
        onlyMine?: boolean;
        onlyCurrentWorkspace?: boolean;
        creatorUid?: string;
        tagIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetExploreSDStylesResponse, any>({
        path: `/api/explore/sd-styles`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore SD Style
     * @name ExploreSdStyleControllerGetStyle
     * @request GET:/api/explore/sd-styles/{sdStyleId}
     * @secure
     */
    exploreSdStyleControllerGetStyle: (sdStyleId: string, params: RequestParams = {}) =>
      this.request<ExploreSDStyleDetail, any>({
        path: `/api/explore/sd-styles/${sdStyleId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore SD Style
     * @name ExploreSdStyleControllerCreateExploreSdStyleComment
     * @request POST:/api/explore/sd-styles/{sdStyleId}/comments
     * @secure
     */
    exploreSdStyleControllerCreateExploreSdStyleComment: (
      sdStyleId: string,
      data: CreateSDStyleCommentDto,
      params: RequestParams = {},
    ) =>
      this.request<Comment, any>({
        path: `/api/explore/sd-styles/${sdStyleId}/comments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore SD Style
     * @name ExploreSdStyleControllerGetExploreSdStyleComments
     * @request GET:/api/explore/sd-styles/{sdStyleId}/comments
     * @secure
     */
    exploreSdStyleControllerGetExploreSdStyleComments: (
      sdStyleId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        parentCommentId?: string;
        commentId?: string;
        notificationId?: string;
        order?: CommentsOrderOption;
        nodeIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCommentsResult, any>({
        path: `/api/explore/sd-styles/${sdStyleId}/comments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore SD Style
     * @name ExploreSdStyleControllerGetExploreMentionableUsers
     * @request GET:/api/explore/sd-styles/{sdStyleId}/comments/mentionable
     * @secure
     */
    exploreSdStyleControllerGetExploreMentionableUsers: (
      sdStyleId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetMentionableUsersResult, any>({
        path: `/api/explore/sd-styles/${sdStyleId}/comments/mentionable`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerList
     * @request POST:/api/sd-workflows/list
     * @secure
     */
    sdWorkflowControllerList: (data: SearchSDWorkflowDto, params: RequestParams = {}) =>
      this.request<SearchSDWorkflowResponse, any>({
        path: `/api/sd-workflows/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerGetWorkflowDetails
     * @request GET:/api/sd-workflows/{workflowId}
     * @secure
     */
    sdWorkflowControllerGetWorkflowDetails: (workflowId: string, params: RequestParams = {}) =>
      this.request<SDWorkflowDetail, any>({
        path: `/api/sd-workflows/${workflowId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerSaveWorkflow
     * @request PUT:/api/sd-workflows/{workflowId}
     * @secure
     */
    sdWorkflowControllerSaveWorkflow: (
      workflowId: string,
      data: UpdateSDWorkflowDto,
      params: RequestParams = {},
    ) =>
      this.request<SDWorkflow, any>({
        path: `/api/sd-workflows/${workflowId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerCreateWorkflow
     * @request POST:/api/sd-workflows
     * @secure
     */
    sdWorkflowControllerCreateWorkflow: (data: UpdateSDWorkflowDto, params: RequestParams = {}) =>
      this.request<SDWorkflow, any>({
        path: `/api/sd-workflows`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerDuplicateWorkflow
     * @request POST:/api/sd-workflows/{workflowId}/duplicate
     * @secure
     */
    sdWorkflowControllerDuplicateWorkflow: (workflowId: string, params: RequestParams = {}) =>
      this.request<SDWorkflow, any>({
        path: `/api/sd-workflows/${workflowId}/duplicate`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerRenameWorkflow
     * @request POST:/api/sd-workflows/{workflowId}/rename
     * @secure
     */
    sdWorkflowControllerRenameWorkflow: (
      workflowId: string,
      data: RenameSDWorkflowDto,
      params: RequestParams = {},
    ) =>
      this.request<SDWorkflow, any>({
        path: `/api/sd-workflows/${workflowId}/rename`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerSoftDeleteWorkflows
     * @request DELETE:/api/sd-workflows/soft-deletion
     * @secure
     */
    sdWorkflowControllerSoftDeleteWorkflows: (
      data: DeleteSDWorkflowsDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/sd-workflows/soft-deletion`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerRecoverWorkflows
     * @request POST:/api/sd-workflows/recover
     * @secure
     */
    sdWorkflowControllerRecoverWorkflows: (
      data: RecoverDeletedSDWorkflowsDto,
      params: RequestParams = {},
    ) =>
      this.request<RecoverSDWorkflowsResult, any>({
        path: `/api/sd-workflows/recover`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerFullyDeleteWorkflows
     * @request POST:/api/sd-workflows/full-deletion
     * @secure
     */
    sdWorkflowControllerFullyDeleteWorkflows: (
      data: DeleteSDWorkflowsDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/sd-workflows/full-deletion`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerFullyDeleteAllSdWorkflows
     * @request DELETE:/api/sd-workflows/full-deletion/all
     * @secure
     */
    sdWorkflowControllerFullyDeleteAllSdWorkflows: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/sd-workflows/full-deletion/all`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerAddTask
     * @summary User creates a task
     * @request POST:/api/sd-workflows/task
     * @secure
     */
    sdWorkflowControllerAddTask: (data: CreateComfyUIRecipeTaskDto, params: RequestParams = {}) =>
      this.request<RecipeTask, any>({
        path: `/api/sd-workflows/task`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerPublishRecipe
     * @summary User creates a task
     * @request POST:/api/sd-workflows/{workflowId}/publish
     * @secure
     */
    sdWorkflowControllerPublishRecipe: (
      workflowId: string,
      data: PublishRecipeDto,
      params: RequestParams = {},
    ) =>
      this.request<Recipe, any>({
        path: `/api/sd-workflows/${workflowId}/publish`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerGetSdWorkflowSharedUsers
     * @request GET:/api/sd-workflows/{workflowId}/permissions
     * @secure
     */
    sdWorkflowControllerGetSdWorkflowSharedUsers: (
      workflowId: string,
      query: {
        /** @default 0 */
        skip: number;
        /** @default 10 */
        take: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<SDWorkflowPermission[], any>({
        path: `/api/sd-workflows/${workflowId}/permissions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerShareSdWorkflow
     * @request POST:/api/sd-workflows/{workflowId}/share
     * @secure
     */
    sdWorkflowControllerShareSdWorkflow: (
      workflowId: string,
      data: ShareToUsersDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/sd-workflows/${workflowId}/share`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerUnshareSdWorkflow
     * @request DELETE:/api/sd-workflows/{workflowId}/share
     * @secure
     */
    sdWorkflowControllerUnshareSdWorkflow: (
      workflowId: string,
      data: UnshareDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/sd-workflows/${workflowId}/share`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerSwitchSharingMode
     * @request POST:/api/sd-workflows/{workflowId}/share/mode
     * @secure
     */
    sdWorkflowControllerSwitchSharingMode: (
      workflowId: string,
      data: SwitchWorkspaceSharingModeDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/sd-workflows/${workflowId}/share/mode`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerUpdateWorkspaceSharingRole
     * @request PUT:/api/sd-workflows/{workflowId}/share-by-workspace
     * @secure
     */
    sdWorkflowControllerUpdateWorkspaceSharingRole: (
      workflowId: string,
      data: UpdateSharingRoleDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/sd-workflows/${workflowId}/share-by-workspace`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerUpdateSharingRole
     * @request PATCH:/api/sd-workflows/permission/{permissionId}/role
     * @secure
     */
    sdWorkflowControllerUpdateSharingRole: (
      permissionId: number,
      data: UpdateSharingRoleDto,
      params: RequestParams = {},
    ) =>
      this.request<SDWorkflowPermission, any>({
        path: `/api/sd-workflows/permission/${permissionId}/role`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerUpdateDiscoverySdWorkflow
     * @request POST:/api/sd-workflows/permission/discovery
     * @secure
     */
    sdWorkflowControllerUpdateDiscoverySdWorkflow: (
      data: UpdateSDWorkflowDiscoveryDto,
      params: RequestParams = {},
    ) =>
      this.request<number, any>({
        path: `/api/sd-workflows/permission/discovery`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerGetComments
     * @request GET:/api/sd-workflows/{workflowId}/comments
     * @secure
     */
    sdWorkflowControllerGetComments: (
      workflowId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        parentCommentId?: string;
        commentId?: string;
        notificationId?: string;
        order?: CommentsOrderOption;
        nodeIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCommentsResult, any>({
        path: `/api/sd-workflows/${workflowId}/comments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerCreateComment
     * @request POST:/api/sd-workflows/{workflowId}/comments
     * @secure
     */
    sdWorkflowControllerCreateComment: (
      workflowId: string,
      data: CreateSDWorkflowCommentDto,
      params: RequestParams = {},
    ) =>
      this.request<Comment, any>({
        path: `/api/sd-workflows/${workflowId}/comments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerGetSdWorkflowMentionableUsers
     * @request GET:/api/sd-workflows/{workflowId}/mentionable
     * @secure
     */
    sdWorkflowControllerGetSdWorkflowMentionableUsers: (
      workflowId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetMentionableUsersResult, any>({
        path: `/api/sd-workflows/${workflowId}/mentionable`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerTagSdWorkflows
     * @request POST:/api/sd-workflows/tags
     * @secure
     */
    sdWorkflowControllerTagSdWorkflows: (data: TagSDWorkflowDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/sd-workflows/tags`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerUntagSdWorkflows
     * @request DELETE:/api/sd-workflows/tags
     * @secure
     */
    sdWorkflowControllerUntagSdWorkflows: (data: UntagSDWorkflowDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/sd-workflows/tags`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ComfyUI Workflows
     * @name SdWorkflowControllerToggleFavoriteSdWorkflow
     * @request POST:/api/sd-workflows/{sdWorkflowId}/favorite
     * @secure
     */
    sdWorkflowControllerToggleFavoriteSdWorkflow: (
      sdWorkflowId: string,
      params: RequestParams = {},
    ) =>
      this.request<FavoriteResult, any>({
        path: `/api/sd-workflows/${sdWorkflowId}/favorite`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerGetWorkflows
     * @request GET:/api/workflows
     * @secure
     */
    workflowControllerGetWorkflows: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        view?: WorkflowViewOptions;
        workflowIds?: string[];
        statuses?: WorkflowStatus[];
        sortBy?: WorkflowSortOptions;
        searchTerm?: string;
        /** @format date-time */
        fromDate?: string;
        /** @format date-time */
        toDate?: string;
        onlyDiscoverable?: boolean;
        onlyShared?: boolean;
        isPinned?: boolean;
        recipeIds?: string[];
        modelHashes?: string[];
        loraHashes?: string[];
        styleIds?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetWorkflowsResult, any>({
        path: `/api/workflows`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerGetWorkflowById
     * @request GET:/api/workflows/{workflowId}
     * @secure
     */
    workflowControllerGetWorkflowById: (workflowId: string, params: RequestParams = {}) =>
      this.request<WorkflowDetail, any>({
        path: `/api/workflows/${workflowId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerUpdateWorkflowById
     * @request PATCH:/api/workflows/{workflowId}
     * @secure
     */
    workflowControllerUpdateWorkflowById: (
      workflowId: string,
      data: UpdateWorkflowByIdDto,
      params: RequestParams = {},
    ) =>
      this.request<WorkflowDetail, any>({
        path: `/api/workflows/${workflowId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerGetWorkflowPermission
     * @request GET:/api/workflows/{workflowId}/permissions
     * @secure
     */
    workflowControllerGetWorkflowPermission: (workflowId: string, params: RequestParams = {}) =>
      this.request<GetWorkflowPermissionResult, any>({
        path: `/api/workflows/${workflowId}/permissions`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerShareWorkflow
     * @request POST:/api/workflows/{workflowId}/permissions
     * @secure
     */
    workflowControllerShareWorkflow: (
      workflowId: string,
      data: ShareWorkflowDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/workflows/${workflowId}/permissions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerUnshareWorkflow
     * @request DELETE:/api/workflows/{workflowId}/permissions
     * @secure
     */
    workflowControllerUnshareWorkflow: (
      workflowId: string,
      data: UnshareDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/workflows/${workflowId}/permissions`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerUpdateWorkflowPermission
     * @request PATCH:/api/workflows/permissions/{permissionId}
     * @secure
     */
    workflowControllerUpdateWorkflowPermission: (
      permissionId: number,
      data: UpdateWorkflowPermissionDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/workflows/permissions/${permissionId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerUpdateWorkflowWorkspaceRole
     * @request PUT:/api/workflows/{workflowId}/workspace-permission
     * @secure
     */
    workflowControllerUpdateWorkflowWorkspaceRole: (
      workflowId: string,
      data: UpdateSharingRoleDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/workflows/${workflowId}/workspace-permission`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerUpdateWorkflowSharingMode
     * @request PUT:/api/workflows/{workflowId}/sharing-mode
     * @secure
     */
    workflowControllerUpdateWorkflowSharingMode: (
      workflowId: string,
      data: SwitchWorkspaceSharingModeDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/workflows/${workflowId}/sharing-mode`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerUpdateWorkflowsDiscovery
     * @request POST:/api/workflows/discovery
     * @secure
     */
    workflowControllerUpdateWorkflowsDiscovery: (
      data: UpdateWorkflowsDiscoveryDto,
      params: RequestParams = {},
    ) =>
      this.request<number, any>({
        path: `/api/workflows/discovery`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerToggleFavoriteWorkflow
     * @request POST:/api/workflows/{workflowId}/favorite
     * @secure
     */
    workflowControllerToggleFavoriteWorkflow: (workflowId: string, params: RequestParams = {}) =>
      this.request<FavoriteResult, any>({
        path: `/api/workflows/${workflowId}/favorite`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerTagWorkflows
     * @request POST:/api/workflows/tags
     * @secure
     */
    workflowControllerTagWorkflows: (data: TagWorkflowsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workflows/tags`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerUntagWorkflows
     * @request DELETE:/api/workflows/tags
     * @secure
     */
    workflowControllerUntagWorkflows: (data: UnTagWorkflowsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workflows/tags`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerCreateWorkflowComment
     * @request POST:/api/workflows/{workflowId}/comments
     * @secure
     */
    workflowControllerCreateWorkflowComment: (
      workflowId: string,
      data: CreateWorkflowCommentDto,
      params: RequestParams = {},
    ) =>
      this.request<Comment, any>({
        path: `/api/workflows/${workflowId}/comments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerGetWorkflowComments
     * @request GET:/api/workflows/{workflowId}/comments
     * @secure
     */
    workflowControllerGetWorkflowComments: (
      workflowId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        parentCommentId?: string;
        commentId?: string;
        notificationId?: string;
        order?: CommentsOrderOption;
        nodeIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCommentsResult, any>({
        path: `/api/workflows/${workflowId}/comments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerGetWorkflowMentionableUsers
     * @request GET:/api/workflows/{workflowId}/comments/mentionable
     * @secure
     */
    workflowControllerGetWorkflowMentionableUsers: (
      workflowId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetMentionableUsersResult, any>({
        path: `/api/workflows/${workflowId}/comments/mentionable`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workflow
     * @name WorkflowControllerFullyDeleteAllWorkflows
     * @request DELETE:/api/workflows/full-deletion/all
     * @secure
     */
    workflowControllerFullyDeleteAllWorkflows: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/workflows/full-deletion/all`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore workflow
     * @name ExploreWorkflowControllerGetExploreWorkflows
     * @request GET:/api/explore/workflows
     * @secure
     */
    exploreWorkflowControllerGetExploreWorkflows: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        searchTerm?: string;
        sortBy?: SortOptions;
        creatorUid?: string;
        onlyCurrentWorkspace?: boolean;
        onlyFavorite?: boolean;
        onlyMine?: boolean;
        tagIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetExploreWorkflowsResult, any>({
        path: `/api/explore/workflows`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore workflow
     * @name ExploreWorkflowControllerGetExploreWorkflowById
     * @request GET:/api/explore/workflows/{workflowId}
     * @secure
     */
    exploreWorkflowControllerGetExploreWorkflowById: (
      workflowId: string,
      params: RequestParams = {},
    ) =>
      this.request<ExploreWorkflowDetail, any>({
        path: `/api/explore/workflows/${workflowId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore workflow
     * @name ExploreWorkflowControllerCreateExploreWorkflowComment
     * @request POST:/api/explore/workflows/{workflowId}/comments
     * @secure
     */
    exploreWorkflowControllerCreateExploreWorkflowComment: (
      workflowId: string,
      data: CreateWorkflowCommentDto,
      params: RequestParams = {},
    ) =>
      this.request<Comment, any>({
        path: `/api/explore/workflows/${workflowId}/comments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore workflow
     * @name ExploreWorkflowControllerGetExploreWorkflowcomment
     * @request GET:/api/explore/workflows/{workflowId}/comments
     * @secure
     */
    exploreWorkflowControllerGetExploreWorkflowcomment: (
      workflowId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        parentCommentId?: string;
        commentId?: string;
        notificationId?: string;
        order?: CommentsOrderOption;
        nodeIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCommentsResult, any>({
        path: `/api/explore/workflows/${workflowId}/comments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore workflow
     * @name ExploreWorkflowControllerGetExploreWorkflowMentionableUsers
     * @request GET:/api/explore/workflows/{workflowId}/comments/mentionable
     * @secure
     */
    exploreWorkflowControllerGetExploreWorkflowMentionableUsers: (
      workflowId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetMentionableUsersResult, any>({
        path: `/api/explore/workflows/${workflowId}/comments/mentionable`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pinned Items
     * @name PinControllerGetPinnedItems
     * @request GET:/api/pinned-items
     * @secure
     */
    pinControllerGetPinnedItems: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        type?: PinnedItemType;
      },
      params: RequestParams = {},
    ) =>
      this.request<PinnedItems[], any>({
        path: `/api/pinned-items`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pinned Items
     * @name PinControllerPinItems
     * @request POST:/api/pinned-items
     * @secure
     */
    pinControllerPinItems: (data: PinItemsDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/pinned-items`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pinned Items
     * @name PinControllerUnpinItems
     * @request DELETE:/api/pinned-items
     * @secure
     */
    pinControllerUnpinItems: (data: UnpinItemsDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/pinned-items`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Creator Profile
     * @name CreatorProfileControllerGetCreatorProfiles
     * @request GET:/api/profiles
     * @secure
     */
    creatorProfileControllerGetCreatorProfiles: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        sortOptions?: CreatorProfileSortOptions;
        searchTerm?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCreatorProfilesResult, any>({
        path: `/api/profiles`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Creator Profile
     * @name CreatorProfileControllerGetCreatorProfileByUsername
     * @request GET:/api/profiles/{username}
     * @secure
     */
    creatorProfileControllerGetCreatorProfileByUsername: (
      username: string,
      params: RequestParams = {},
    ) =>
      this.request<CreatorProfileItem, any>({
        path: `/api/profiles/${username}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Creator Profile
     * @name CreatorProfileControllerToggleCreatorProfile
     * @request POST:/api/profiles/status
     * @secure
     */
    creatorProfileControllerToggleCreatorProfile: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/profiles/status`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Access Request
     * @name AccessRequestControllerRequestAccess
     * @request POST:/api/access-requests
     * @secure
     */
    accessRequestControllerRequestAccess: (data: RequestAccessDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/access-requests`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Access Request
     * @name AccessRequestControllerGrantAccess
     * @request POST:/api/access-requests/{accessRequestId}
     * @secure
     */
    accessRequestControllerGrantAccess: (
      accessRequestId: string,
      data: GrantAccessDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/access-requests/${accessRequestId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Turbo Mode
     * @name TurboModeControllerGetLastActiveSession
     * @summary Get last active session
     * @request GET:/api/turbo/session
     * @secure
     */
    turboModeControllerGetLastActiveSession: (params: RequestParams = {}) =>
      this.request<TurboSession, any>({
        path: `/api/turbo/session`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Turbo Mode
     * @name TurboModeControllerGetLastActiveSessionOrCreateNew
     * @summary Get last active or create new session
     * @request POST:/api/turbo/session
     * @secure
     */
    turboModeControllerGetLastActiveSessionOrCreateNew: (params: RequestParams = {}) =>
      this.request<TurboSession, any>({
        path: `/api/turbo/session`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Turbo Mode
     * @name TurboModeControllerTerminateSession
     * @summary User terminates a turbo session
     * @request DELETE:/api/turbo/session/{sessionId}
     * @secure
     */
    turboModeControllerTerminateSession: (sessionId: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/turbo/session/${sessionId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Turbo Mode
     * @name TurboModeControllerAddTask
     * @summary User creates a task
     * @request POST:/api/turbo/task
     * @secure
     */
    turboModeControllerAddTask: (data: CreateTurboTaskDto, params: RequestParams = {}) =>
      this.request<RecipeTask, any>({
        path: `/api/turbo/task`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Preview Entity
     * @name PreviewControllerGetPreviewInfo
     * @request GET:/api/preview
     */
    previewControllerGetPreviewInfo: (
      query: {
        type: PreviewEntityType;
        id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PreviewInfo, any>({
        path: `/api/preview`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wallet
     * @name WalletControllerGetOwnedWallets
     * @request GET:/api/wallet/owned
     * @secure
     */
    walletControllerGetOwnedWallets: (params: RequestParams = {}) =>
      this.request<GetOwnedWalletsResponse, any>({
        path: `/api/wallet/owned`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wallet
     * @name WalletControllerGetConnectedUser
     * @request GET:/api/wallet/{address}
     * @secure
     */
    walletControllerGetConnectedUser: (address: string, params: RequestParams = {}) =>
      this.request<GetConnectedUserResponse, any>({
        path: `/api/wallet/${address}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wallet
     * @name WalletControllerConnectWallet
     * @request POST:/api/wallet/connect
     * @secure
     */
    walletControllerConnectWallet: (data: ConnectWalletRequest, params: RequestParams = {}) =>
      this.request<ConnectWalletResponse, any>({
        path: `/api/wallet/connect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wallet
     * @name WalletControllerConfirmConnectWallet
     * @request POST:/api/wallet/connect/confirm
     * @secure
     */
    walletControllerConfirmConnectWallet: (
      data: ConfirmWalletRequest,
      params: RequestParams = {},
    ) =>
      this.request<ConfirmWalletResponse, any>({
        path: `/api/wallet/connect/confirm`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wallet
     * @name WalletControllerDisconnectWallet
     * @request POST:/api/wallet/disconnect
     * @secure
     */
    walletControllerDisconnectWallet: (data: ConnectWalletRequest, params: RequestParams = {}) =>
      this.request<ConnectWalletResponse, any>({
        path: `/api/wallet/disconnect`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wallet
     * @name WalletControllerConfirmDisconnectWallet
     * @request POST:/api/wallet/disconnect/confirm
     * @secure
     */
    walletControllerConfirmDisconnectWallet: (
      data: ConfirmWalletRequest,
      params: RequestParams = {},
    ) =>
      this.request<ConfirmWalletResponse, any>({
        path: `/api/wallet/disconnect/confirm`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Follow
     * @name FollowControllerGetUserFollowersByUser
     * @request GET:/api/follow/{userUid}/followers
     * @secure
     */
    followControllerGetUserFollowersByUser: (
      userUid: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetUserFollowersResult, any>({
        path: `/api/follow/${userUid}/followers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Follow
     * @name FollowControllerGetUserFollowingsByUser
     * @request GET:/api/follow/{userUid}/followings
     * @secure
     */
    followControllerGetUserFollowingsByUser: (
      userUid: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetUserFollowingsResult, any>({
        path: `/api/follow/${userUid}/followings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Follow
     * @name FollowControllerToggleFollowUser
     * @request POST:/api/follow/toggle
     * @secure
     */
    followControllerToggleFollowUser: (data: ToggleFollowUserDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/follow/toggle`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment Gateway
     * @name PaymentGatewayControllerReceivePaymentResponse
     * @request POST:/api/payment-gateway/webhook
     * @secure
     */
    paymentGatewayControllerReceivePaymentResponse: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/payment-gateway/webhook`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment Gateway
     * @name PaymentGatewayControllerProcessXsollaResponsse
     * @request POST:/api/payment-gateway/webhook/xsolla
     * @secure
     */
    paymentGatewayControllerProcessXsollaResponsse: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/payment-gateway/webhook/xsolla`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment Gateway
     * @name PaymentGatewayControllerRequestRefund
     * @request POST:/api/payment-gateway/refund
     * @secure
     */
    paymentGatewayControllerRequestRefund: (data: RequestRefundDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/payment-gateway/refund`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment Gateway
     * @name PaymentGatewayControllerProcessStripeEvents
     * @request POST:/api/payment-gateway/webhook/stripe
     * @secure
     */
    paymentGatewayControllerProcessStripeEvents: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/payment-gateway/webhook/stripe`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerGetCreditPackages
     * @request GET:/api/payment/credit-packages
     * @secure
     */
    paymentControllerGetCreditPackages: (params: RequestParams = {}) =>
      this.request<CreditPackageItem[], any>({
        path: `/api/payment/credit-packages`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerPrePurchasePackageByCrypto
     * @request POST:/api/payment/crypto/pre-purchase
     * @secure
     */
    paymentControllerPrePurchasePackageByCrypto: (
      data: PreCryptoTransactionRequest,
      params: RequestParams = {},
    ) =>
      this.request<PreCryptoTransactionResponse, any>({
        path: `/api/payment/crypto/pre-purchase`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerPurchasePackageByCrypto
     * @request POST:/api/payment/crypto/purchase
     * @secure
     */
    paymentControllerPurchasePackageByCrypto: (
      data: PurchasePackageByCryptoDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/payment/crypto/purchase`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerPurchaseCreditPackage
     * @request POST:/api/payment/credit-packages/purchase
     * @secure
     */
    paymentControllerPurchaseCreditPackage: (
      data: PurchaseCreditPackageDto,
      params: RequestParams = {},
    ) =>
      this.request<PurchaseCreditPackageResult, any>({
        path: `/api/payment/credit-packages/purchase`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerGetSubscriptionPackages
     * @request GET:/api/payment/subscription
     * @secure
     */
    paymentControllerGetSubscriptionPackages: (
      query?: {
        group?: SubscriptionGroup;
      },
      params: RequestParams = {},
    ) =>
      this.request<SubscriptionPackageItem[], any>({
        path: `/api/payment/subscription`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerGetSubscriptionPackageById
     * @request GET:/api/payment/subscription/{subscriptionPackageId}
     * @secure
     */
    paymentControllerGetSubscriptionPackageById: (
      subscriptionPackageId: string,
      params: RequestParams = {},
    ) =>
      this.request<SubscriptionPackageItem, any>({
        path: `/api/payment/subscription/${subscriptionPackageId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerSubscribe
     * @request POST:/api/payment/subscription/purchase
     * @secure
     */
    paymentControllerSubscribe: (data: SubscribeDto, params: RequestParams = {}) =>
      this.request<PurchaseSubscriptionPackageResult, any>({
        path: `/api/payment/subscription/purchase`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerCancelSubscription
     * @request POST:/api/payment/subscription/cancel
     * @secure
     */
    paymentControllerCancelSubscription: (
      data: CancelSubscriptionDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/payment/subscription/cancel`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerGetSubscriptionPreviewAmount
     * @request POST:/api/payment/subscription/preview-amount
     * @secure
     */
    paymentControllerGetSubscriptionPreviewAmount: (
      data: SubscribeDto,
      params: RequestParams = {},
    ) =>
      this.request<CalculateSubscriptionAmountResult, any>({
        path: `/api/payment/subscription/preview-amount`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerDisableAutoRenewal
     * @request POST:/api/payment/subscription/disable-auto-renewal
     * @secure
     */
    paymentControllerDisableAutoRenewal: (
      data: DisableAutoRenewalDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/payment/subscription/disable-auto-renewal`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerUpdatePaymentMethod
     * @request PATCH:/api/payment/subscription/{subscriptionId}/payment-methods
     * @secure
     */
    paymentControllerUpdatePaymentMethod: (
      subscriptionId: string,
      data: UpdatePaymentMethodDto,
      params: RequestParams = {},
    ) =>
      this.request<UpdatePaymentMethodResult, any>({
        path: `/api/payment/subscription/${subscriptionId}/payment-methods`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerUpdateStripePaymentMethod
     * @request PATCH:/api/payment/subscription/{subscriptionId}/stripe-payment-methods
     * @secure
     */
    paymentControllerUpdateStripePaymentMethod: (
      subscriptionId: string,
      params: RequestParams = {},
    ) =>
      this.request<UpdatePaymentMethodResult, any>({
        path: `/api/payment/subscription/${subscriptionId}/stripe-payment-methods`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Payment
     * @name PaymentControllerCreateEnterpriseContact
     * @request POST:/api/payment/subscription/contacts
     * @secure
     */
    paymentControllerCreateEnterpriseContact: (
      data: CreateEnterpriseContactDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/payment/subscription/contacts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerList
     * @request POST:/api/wildcard/list
     * @secure
     */
    wildcardControllerList: (data: SearchWildcardDto, params: RequestParams = {}) =>
      this.request<SearchWildcardResponse, any>({
        path: `/api/wildcard/list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerGetWildcard
     * @request GET:/api/wildcard/{wildcardId}
     * @secure
     */
    wildcardControllerGetWildcard: (wildcardId: string, params: RequestParams = {}) =>
      this.request<WildcardDetail, any>({
        path: `/api/wildcard/${wildcardId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerSaveWildcard
     * @request PUT:/api/wildcard/{wildcardId}
     * @secure
     */
    wildcardControllerSaveWildcard: (
      wildcardId: string,
      data: UpdateWildcardDto,
      params: RequestParams = {},
    ) =>
      this.request<Wildcard, any>({
        path: `/api/wildcard/${wildcardId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerCreateWildcards
     * @request POST:/api/wildcard
     * @secure
     */
    wildcardControllerCreateWildcards: (data: CreateWildcardDto, params: RequestParams = {}) =>
      this.request<SearchWildcardResponse, any>({
        path: `/api/wildcard`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerGetWildcardSharedUsers
     * @request GET:/api/wildcard/{wildcardId}/permissions
     * @secure
     */
    wildcardControllerGetWildcardSharedUsers: (
      wildcardId: string,
      query: {
        /** @default 0 */
        skip: number;
        /** @default 10 */
        take: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<WildcardPermission[], any>({
        path: `/api/wildcard/${wildcardId}/permissions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerShareWildcard
     * @request POST:/api/wildcard/{wildcardId}/share
     * @secure
     */
    wildcardControllerShareWildcard: (
      wildcardId: string,
      data: ShareToUsersDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/wildcard/${wildcardId}/share`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerUnshareWildcard
     * @request DELETE:/api/wildcard/{wildcardId}/share
     * @secure
     */
    wildcardControllerUnshareWildcard: (
      wildcardId: string,
      data: UnshareDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/wildcard/${wildcardId}/share`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerSwitchSharingMode
     * @request POST:/api/wildcard/{wildcardId}/share/mode
     * @secure
     */
    wildcardControllerSwitchSharingMode: (
      wildcardId: string,
      data: SwitchWorkspaceSharingModeDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/wildcard/${wildcardId}/share/mode`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerUpdateWorkspaceSharingRole
     * @request PUT:/api/wildcard/{wildcardId}/share-by-workspace
     * @secure
     */
    wildcardControllerUpdateWorkspaceSharingRole: (
      wildcardId: string,
      data: UpdateSharingRoleDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/wildcard/${wildcardId}/share-by-workspace`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerUpdateSharingRole
     * @request PATCH:/api/wildcard/permission/{permissionId}/role
     * @secure
     */
    wildcardControllerUpdateSharingRole: (
      permissionId: number,
      data: UpdateSharingRoleDto,
      params: RequestParams = {},
    ) =>
      this.request<WildcardPermission, any>({
        path: `/api/wildcard/permission/${permissionId}/role`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerAddWildcardToLibrary
     * @request POST:/api/wildcard/{wildcardId}/add-to-library
     * @secure
     */
    wildcardControllerAddWildcardToLibrary: (wildcardId: string, params: RequestParams = {}) =>
      this.request<WildcardRoleCapabilities, any>({
        path: `/api/wildcard/${wildcardId}/add-to-library`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerRemoveWildcardFromLibrary
     * @request POST:/api/wildcard/{wildcardId}/remove-from-library
     * @secure
     */
    wildcardControllerRemoveWildcardFromLibrary: (wildcardId: string, params: RequestParams = {}) =>
      this.request<WildcardRoleCapabilities, any>({
        path: `/api/wildcard/${wildcardId}/remove-from-library`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerAddWildcardsToLibrary
     * @request POST:/api/wildcard/add-to-library
     * @secure
     */
    wildcardControllerAddWildcardsToLibrary: (
      data: AddWildcardsToLibraryDto,
      params: RequestParams = {},
    ) =>
      this.request<AddWildcardsToLibraryResult, any>({
        path: `/api/wildcard/add-to-library`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerRemoveWildcardsFromLibrary
     * @request POST:/api/wildcard/remove-from-library
     * @secure
     */
    wildcardControllerRemoveWildcardsFromLibrary: (
      data: RemoveWildcardsFromLibraryDto,
      params: RequestParams = {},
    ) =>
      this.request<RemoveWildcardsFromLibraryResult, any>({
        path: `/api/wildcard/remove-from-library`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerRecoverWildcards
     * @request POST:/api/wildcard/recover
     * @secure
     */
    wildcardControllerRecoverWildcards: (data: DeleteWildcardsDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/wildcard/recover`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerSoftDeleteWildcards
     * @request POST:/api/wildcard/soft-deletion
     * @secure
     */
    wildcardControllerSoftDeleteWildcards: (data: DeleteWildcardsDto, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/wildcard/soft-deletion`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerFullyDeleteWildcards
     * @request POST:/api/wildcard/full-deletion
     * @secure
     */
    wildcardControllerFullyDeleteWildcards: (
      data: DeleteWildcardsDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/wildcard/full-deletion`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wildcard
     * @name WildcardControllerFullyDeleteAllWildcards
     * @request DELETE:/api/wildcard/full-deletion/all
     * @secure
     */
    wildcardControllerFullyDeleteAllWildcards: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/wildcard/full-deletion/all`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Billing
     * @name BillingControllerGetOrderHistories
     * @request GET:/api/billing/histories
     * @secure
     */
    billingControllerGetOrderHistories: (
      query: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        workspaceId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOrderHistoriesResponse, any>({
        path: `/api/billing/histories`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore Recipe
     * @name ExploreRecipeControllerGetExploreRecipes
     * @request GET:/api/explore/recipes
     * @secure
     */
    exploreRecipeControllerGetExploreRecipes: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        searchTerm?: string;
        sortBy?: SortOptions;
        creatorUid?: string;
        onlyFavorite?: boolean;
        onlyCurrentWorkspace?: boolean;
        tagIds?: number[];
        ids?: string[];
        categoryId?: number;
        createdBy?: CreatedByOptions;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetExploreRecipesResult, any>({
        path: `/api/explore/recipes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore Recipe
     * @name ExploreRecipeControllerGetExploreRecipeById
     * @request GET:/api/explore/recipes/{recipeId}
     * @secure
     */
    exploreRecipeControllerGetExploreRecipeById: (recipeId: string, params: RequestParams = {}) =>
      this.request<ExploreRecipeItem, any>({
        path: `/api/explore/recipes/${recipeId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore Recipe
     * @name ExploreRecipeControllerCreateExploreRecipeComment
     * @request POST:/api/explore/recipes/{recipeId}/comments
     * @secure
     */
    exploreRecipeControllerCreateExploreRecipeComment: (
      recipeId: string,
      data: CreateRecipeCommentDto,
      params: RequestParams = {},
    ) =>
      this.request<Comment, any>({
        path: `/api/explore/recipes/${recipeId}/comments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore Recipe
     * @name ExploreRecipeControllerGetExploreRecipeComments
     * @request GET:/api/explore/recipes/{recipeId}/comments
     * @secure
     */
    exploreRecipeControllerGetExploreRecipeComments: (
      recipeId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        parentCommentId?: string;
        commentId?: string;
        notificationId?: string;
        order?: CommentsOrderOption;
        nodeIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCommentsResult, any>({
        path: `/api/explore/recipes/${recipeId}/comments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore Recipe
     * @name ExploreRecipeControllerGetExploreRecipeMentionableUsers
     * @request GET:/api/explore/recipes/{recipeId}/comments/mentionable
     * @secure
     */
    exploreRecipeControllerGetExploreRecipeMentionableUsers: (
      recipeId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetMentionableUsersResult, any>({
        path: `/api/explore/recipes/${recipeId}/comments/mentionable`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore SD Workflow
     * @name ExploreSdWorkflowControllerGetExploreSdWorkflows
     * @request GET:/api/explore/sd-workflows
     * @secure
     */
    exploreSdWorkflowControllerGetExploreSdWorkflows: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        searchTerm?: string;
        ids?: string[];
        sortBy?: SortOptions;
        onlyFavorite?: boolean;
        onlyMine?: boolean;
        onlyCurrentWorkspace?: boolean;
        creatorUid?: string;
        tagIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetExploreSDWorkflowsResult, any>({
        path: `/api/explore/sd-workflows`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore SD Workflow
     * @name ExploreSdWorkflowControllerGetExploreSdWorkflowById
     * @request GET:/api/explore/sd-workflows/{sdWorkflowId}
     * @secure
     */
    exploreSdWorkflowControllerGetExploreSdWorkflowById: (
      sdWorkflowId: string,
      params: RequestParams = {},
    ) =>
      this.request<ExploreSDWorkflowDetail, any>({
        path: `/api/explore/sd-workflows/${sdWorkflowId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore SD Workflow
     * @name ExploreSdWorkflowControllerCreateExploreSdWorkflowComment
     * @request POST:/api/explore/sd-workflows/{sdWorkflowId}/comments
     * @secure
     */
    exploreSdWorkflowControllerCreateExploreSdWorkflowComment: (
      sdWorkflowId: string,
      data: CreateSDWorkflowCommentDto,
      params: RequestParams = {},
    ) =>
      this.request<Comment, any>({
        path: `/api/explore/sd-workflows/${sdWorkflowId}/comments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore SD Workflow
     * @name ExploreSdWorkflowControllerGetExploreSdWorkflowComments
     * @request GET:/api/explore/sd-workflows/{sdWorkflowId}/comments
     * @secure
     */
    exploreSdWorkflowControllerGetExploreSdWorkflowComments: (
      sdWorkflowId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        parentCommentId?: string;
        commentId?: string;
        notificationId?: string;
        order?: CommentsOrderOption;
        nodeIds?: number[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GetCommentsResult, any>({
        path: `/api/explore/sd-workflows/${sdWorkflowId}/comments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Explore SD Workflow
     * @name ExploreSdWorkflowControllerGetExploreMentionableUsers
     * @request GET:/api/explore/sd-workflows/{sdWorkflowId}/comments/mentionable
     * @secure
     */
    exploreSdWorkflowControllerGetExploreMentionableUsers: (
      sdWorkflowId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        search?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetMentionableUsersResult, any>({
        path: `/api/explore/sd-workflows/${sdWorkflowId}/comments/mentionable`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backup
     * @name BackupControllerGetUserBackup
     * @request GET:/api/backup
     * @secure
     */
    backupControllerGetUserBackup: (params: RequestParams = {}) =>
      this.request<BackupItem, any>({
        path: `/api/backup`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Backup
     * @name BackupControllerRequestBackup
     * @request POST:/api/backup
     * @secure
     */
    backupControllerRequestBackup: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/backup`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name FeedbackControllerCreateFeedback
     * @request POST:/api/feedback
     * @secure
     */
    feedbackControllerCreateFeedback: (data: CreateFeedbackDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/feedback`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Feedback
     * @name FeedbackControllerUpdateFeedback
     * @request PATCH:/api/feedback/{feedbackId}
     * @secure
     */
    feedbackControllerUpdateFeedback: (
      feedbackId: number,
      data: UpdateFeedbackDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/feedback/${feedbackId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leaderboard
     * @name LeaderboardControllerTest
     * @request GET:/api/leaderboard/test
     * @secure
     */
    leaderboardControllerTest: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/leaderboard/test`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leaderboard
     * @name LeaderboardControllerGetLeaderboards
     * @request GET:/api/leaderboard
     * @secure
     */
    leaderboardControllerGetLeaderboards: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<LeaderboardItem[], any>({
        path: `/api/leaderboard`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leaderboard
     * @name LeaderboardControllerGetLatestRankingByLeadboard
     * @request GET:/api/leaderboard/{leaderboardId}/ranking
     * @secure
     */
    leaderboardControllerGetLatestRankingByLeadboard: (
      leaderboardId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserRanking[], any>({
        path: `/api/leaderboard/${leaderboardId}/ranking`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Leaderboard
     * @name LeaderboardControllerGetSelfLatestRanking
     * @request GET:/api/leaderboard/{leaderboardId}/ranking/me
     * @secure
     */
    leaderboardControllerGetSelfLatestRanking: (
      leaderboardId: string,
      params: RequestParams = {},
    ) =>
      this.request<UserRanking, any>({
        path: `/api/leaderboard/${leaderboardId}/ranking/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Moderation
     * @name ModerationControllerProcessImagesLabels
     * @request POST:/api/moderation/webhook
     */
    moderationControllerProcessImagesLabels: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/moderation/webhook`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Moderation
     * @name ModerationControllerToggleHiddenExploreImages
     * @request POST:/api/moderation/images/hide
     */
    moderationControllerToggleHiddenExploreImages: (
      data: HideExploreImagesDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/moderation/images/hide`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Moderation
     * @name ModerationControllerGetImages
     * @request POST:/api/moderation/images
     */
    moderationControllerGetImages: (
      data: GetInspirationBoardImagesQuery,
      params: RequestParams = {},
    ) =>
      this.request<GetInspirationBoardImagesResult, any>({
        path: `/api/moderation/images`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Moderation
     * @name ModerationControllerGetUsers
     * @request GET:/api/moderation/users
     */
    moderationControllerGetUsers: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        searchTerm?: string;
        sortBy?: ModerationUserSortOptions;
        sortOrder?: SortOrder;
      },
      params: RequestParams = {},
    ) =>
      this.request<ModerationGetUsersResult, any>({
        path: `/api/moderation/users`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Moderation
     * @name ModerationControllerGetUserByUid
     * @request GET:/api/moderation/users/{userUid}
     */
    moderationControllerGetUserByUid: (userUid: string, params: RequestParams = {}) =>
      this.request<ModerationUserItem, any>({
        path: `/api/moderation/users/${userUid}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Moderation
     * @name ModerationControllerGetGaiaEarlyAccesses
     * @request GET:/api/moderation/gaia-early-access
     */
    moderationControllerGetGaiaEarlyAccesses: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        searchTerm?: string;
        sortBy?: GaiaEarlyAccessSortOptions;
        sortOrder?: SortOrder;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetGaiaEarlyAccessResult, any>({
        path: `/api/moderation/gaia-early-access`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Moderation
     * @name ModerationControllerGetTransactions
     * @request GET:/api/moderation/transactions
     */
    moderationControllerGetTransactions: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        /** @format date-time */
        fromDate?: string;
        /** @format date-time */
        toDate?: string;
        productType?: ProductType;
        status?: TransactionStatus;
        paymentMethod?: PaymentMethod;
        sortOrder?: SortOrder;
        sortBy?: TransactionSortByOptions;
        searchTerm?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetTransactionsResult, any>({
        path: `/api/moderation/transactions`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Moderation
     * @name ModerationControllerGetTransactionById
     * @request GET:/api/moderation/transactions/{transactionId}
     */
    moderationControllerGetTransactionById: (transactionId: string, params: RequestParams = {}) =>
      this.request<TransactionItem, any>({
        path: `/api/moderation/transactions/${transactionId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Moderation
     * @name ModerationControllerExportTransaction
     * @request POST:/api/moderation/transactions/export
     */
    moderationControllerExportTransaction: (data: GetTransactionsDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/moderation/transactions/export`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Moderation
     * @name ModerationControllerGetWhitelist
     * @request GET:/api/moderation/whitelist
     */
    moderationControllerGetWhitelist: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        searchTerm?: string;
        hasSentWelcomeMail?: boolean;
        sortBy?: WhitelistEmailSortOptions;
        sortOrder?: SortOrder;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetWhiteListEmailsResult, any>({
        path: `/api/moderation/whitelist`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Moderation
     * @name ModerationControllerAddWhitelistUser
     * @request POST:/api/moderation/whitelist
     */
    moderationControllerAddWhitelistUser: (
      data: AddWhitelistUserDto[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/moderation/whitelist`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Moderation
     * @name ModerationControllerSendWhitelistEmail
     * @request POST:/api/moderation/whitelist/welcome-email
     */
    moderationControllerSendWhitelistEmail: (
      data: SendWhitelistEmailDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/moderation/whitelist/welcome-email`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Moderation
     * @name ModerationControllerGetEnterpriseContacts
     * @request GET:/api/moderation/subscriptions/contacts
     */
    moderationControllerGetEnterpriseContacts: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetEnterpriseContactsResult, any>({
        path: `/api/moderation/subscriptions/contacts`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tracking
     * @name TrackingControllerTrackViews
     * @request POST:/api/tracking/views
     */
    trackingControllerTrackViews: (data: TrackDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/tracking/views`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quests
     * @name QuestControllerGetQuestByUser
     * @request GET:/api/quests
     * @secure
     */
    questControllerGetQuestByUser: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        status?: QuestStatus;
        frequency?: QuestFrequency;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetUserQuestsResult, any>({
        path: `/api/quests`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quests
     * @name QuestControllerGetCurrentSeason
     * @request GET:/api/quests/season/now
     * @secure
     */
    questControllerGetCurrentSeason: (params: RequestParams = {}) =>
      this.request<QuestSeasonItem, any>({
        path: `/api/quests/season/now`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quests
     * @name QuestControllerClaimAllQuest
     * @request POST:/api/quests/all/claim
     * @secure
     */
    questControllerClaimAllQuest: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/quests/all/claim`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quests
     * @name QuestControllerClaimQuest
     * @request POST:/api/quests/{questId}/claim
     * @secure
     */
    questControllerClaimQuest: (questId: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/quests/${questId}/claim`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Quests
     * @name QuestControllerIncreaseSocialQuestClaimable
     * @request POST:/api/quests/social-quest/claimable
     * @secure
     */
    questControllerIncreaseSocialQuestClaimable: (
      data: IncreaseSocialQuestCLaimableDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/quests/social-quest/claimable`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Referral V2
     * @name ReferralControllerGetUserReferrals
     * @request GET:/api/referral
     * @secure
     */
    referralControllerGetUserReferrals: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetUserReferralsResult, any>({
        path: `/api/referral`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Referral V2
     * @name ReferralControllerAddReferralV2
     * @request POST:/api/referral
     * @secure
     */
    referralControllerAddReferralV2: (data: AddReferralV2Dto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/referral`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WOW Point
     * @name WowPointControllerGetUserWowPointHistory
     * @request GET:/api/wow-points/histories
     * @secure
     */
    wowPointControllerGetUserWowPointHistory: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<WowPointHistoryItem[], any>({
        path: `/api/wow-points/histories`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tipping
     * @name TippingControllerTipWowPoint
     * @request POST:/api/tipping/wow-points
     * @secure
     */
    tippingControllerTipWowPoint: (data: TipDto, params: RequestParams = {}) =>
      this.request<TipResult, any>({
        path: `/api/tipping/wow-points`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Agent
     * @name AgentControllerCommand
     * @request POST:/api/agent/command
     * @secure
     */
    agentControllerCommand: (data: SimpleAgentCommand, params: RequestParams = {}) =>
      this.request<RecipeTask[], any>({
        path: `/api/agent/command`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerGetChatRooms
     * @request GET:/api/chat-rooms
     * @secure
     */
    chatRoomControllerGetChatRooms: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        workspaceId?: string;
        isPrivate?: boolean;
        searchTerm?: string;
        creatorUid?: string;
        isPinned?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetChatRoomsResult, any>({
        path: `/api/chat-rooms`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerCreateChatRoom
     * @request POST:/api/chat-rooms
     * @secure
     */
    chatRoomControllerCreateChatRoom: (data: CreateChatRoomDto, params: RequestParams = {}) =>
      this.request<ChatRoomItem, any>({
        path: `/api/chat-rooms`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerCanUserCreateRoom
     * @request POST:/api/chat-rooms/permissions/can-create
     * @secure
     */
    chatRoomControllerCanUserCreateRoom: (params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/chat-rooms/permissions/can-create`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerGetChatRoomById
     * @request GET:/api/chat-rooms/{roomId}
     * @secure
     */
    chatRoomControllerGetChatRoomById: (roomId: string, params: RequestParams = {}) =>
      this.request<ChatRoomItem, any>({
        path: `/api/chat-rooms/${roomId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerUpdateChatRoom
     * @request PATCH:/api/chat-rooms/{roomId}
     * @secure
     */
    chatRoomControllerUpdateChatRoom: (
      roomId: string,
      data: UpdateChatRoomDto,
      params: RequestParams = {},
    ) =>
      this.request<ChatRoomItem, any>({
        path: `/api/chat-rooms/${roomId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerDeleteChatRoom
     * @request DELETE:/api/chat-rooms/{roomId}
     * @secure
     */
    chatRoomControllerDeleteChatRoom: (roomId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/chat-rooms/${roomId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerSearchChatRooms
     * @request POST:/api/chat-rooms/search
     * @secure
     */
    chatRoomControllerSearchChatRooms: (data: SearchChatRoomsDto, params: RequestParams = {}) =>
      this.request<ChatRoomItem[], any>({
        path: `/api/chat-rooms/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerResetChatRoomReadState
     * @request POST:/api/chat-rooms/{roomId}/members/read/reset
     * @secure
     */
    chatRoomControllerResetChatRoomReadState: (roomId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/chat-rooms/${roomId}/members/read/reset`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerGetRoomMembers
     * @request GET:/api/chat-rooms/{roomId}/members
     * @secure
     */
    chatRoomControllerGetRoomMembers: (
      roomId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        searchTerm?: string;
        role?: ChatRoomRole;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetRoomMembersResult, any>({
        path: `/api/chat-rooms/${roomId}/members`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerJoinChatRoom
     * @request POST:/api/chat-rooms/{roomId}/members
     * @secure
     */
    chatRoomControllerJoinChatRoom: (
      roomId: string,
      data: JoinChatRoomDto,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/chat-rooms/${roomId}/members`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerLeaveChatRoom
     * @request DELETE:/api/chat-rooms/{roomId}/members
     * @secure
     */
    chatRoomControllerLeaveChatRoom: (roomId: string, params: RequestParams = {}) =>
      this.request<boolean, any>({
        path: `/api/chat-rooms/${roomId}/members`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerGetChatRoomByJoinCode
     * @request GET:/api/chat-rooms/join-code/{joinCode}
     * @secure
     */
    chatRoomControllerGetChatRoomByJoinCode: (joinCode: string, params: RequestParams = {}) =>
      this.request<ChatRoomItem, any>({
        path: `/api/chat-rooms/join-code/${joinCode}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerJoinChatRoomByJoinCode
     * @request POST:/api/chat-rooms/join-code/{joinCode}/members
     * @secure
     */
    chatRoomControllerJoinChatRoomByJoinCode: (joinCode: string, params: RequestParams = {}) =>
      this.request<ChatRoomItem, any>({
        path: `/api/chat-rooms/join-code/${joinCode}/members`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerInviteRoomMembers
     * @request POST:/api/chat-rooms/{roomId}/invites
     * @secure
     */
    chatRoomControllerInviteRoomMembers: (
      roomId: string,
      data: InviteRoomMembersDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/chat-rooms/${roomId}/invites`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerUpdateRoomInvite
     * @request POST:/api/chat-rooms/invites/{inviteId}
     * @secure
     */
    chatRoomControllerUpdateRoomInvite: (
      inviteId: string,
      data: UpdateRoomInviteDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/chat-rooms/invites/${inviteId}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerUpdateRoomMember
     * @request PATCH:/api/chat-rooms/members/{roomMemberId}
     * @secure
     */
    chatRoomControllerUpdateRoomMember: (
      roomMemberId: string,
      data: UpdateRoomMemberDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/chat-rooms/members/${roomMemberId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerKickMember
     * @request DELETE:/api/chat-rooms/members/{roomMemberId}
     * @secure
     */
    chatRoomControllerKickMember: (roomMemberId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/chat-rooms/members/${roomMemberId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerGetMessagesByRoom
     * @request GET:/api/chat-rooms/{roomId}/messages
     * @secure
     */
    chatRoomControllerGetMessagesByRoom: (
      roomId: string,
      query: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        roomId: string;
        isPinned?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ChatMessage[], any>({
        path: `/api/chat-rooms/${roomId}/messages`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerDeleteRoomMessage
     * @request DELETE:/api/chat-rooms/{roomId}/messages/{messageId}
     * @secure
     */
    chatRoomControllerDeleteRoomMessage: (
      messageId: string,
      roomId: string,
      params: RequestParams = {},
    ) =>
      this.request<boolean, any>({
        path: `/api/chat-rooms/${roomId}/messages/${messageId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerGetRoomRecipeTasks
     * @request GET:/api/chat-rooms/{roomId}/recipe-tasks
     * @secure
     */
    chatRoomControllerGetRoomRecipeTasks: (
      roomId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        statuses?: RecipeTaskStatus[];
        recipeId?: string;
        creatorUid?: string;
        searchTerm?: string;
        recipeTaskIds?: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<RecipeTaskItem[], any>({
        path: `/api/chat-rooms/${roomId}/recipe-tasks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerFavoriteGenerated
     * @request POST:/api/chat-rooms/recipe-tasks/{recipeTaskId}/generated/favorite
     * @secure
     */
    chatRoomControllerFavoriteGenerated: (recipeTaskId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/chat-rooms/recipe-tasks/${recipeTaskId}/generated/favorite`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerUnfavoriteGenerated
     * @request DELETE:/api/chat-rooms/recipe-tasks/{recipeTaskId}/generated/favorite
     * @secure
     */
    chatRoomControllerUnfavoriteGenerated: (recipeTaskId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/chat-rooms/recipe-tasks/${recipeTaskId}/generated/favorite`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerPinRoom
     * @request POST:/api/chat-rooms/{roomId}/pinned
     * @secure
     */
    chatRoomControllerPinRoom: (roomId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/chat-rooms/${roomId}/pinned`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerUnpinRoom
     * @request DELETE:/api/chat-rooms/{roomId}/pinned
     * @secure
     */
    chatRoomControllerUnpinRoom: (roomId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/chat-rooms/${roomId}/pinned`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerGetRoomPinnedMessages
     * @request GET:/api/chat-rooms/{roomId}/messages/pinned
     * @secure
     */
    chatRoomControllerGetRoomPinnedMessages: (
      roomId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ChatMessage[], any>({
        path: `/api/chat-rooms/${roomId}/messages/pinned`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerPinMessage
     * @request POST:/api/chat-rooms/{roomId}/messages/{messageId}/pinned
     * @secure
     */
    chatRoomControllerPinMessage: (messageId: string, roomId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/chat-rooms/${roomId}/messages/${messageId}/pinned`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerUnpinMessage
     * @request DELETE:/api/chat-rooms/{roomId}/messages/{messageId}/pinned
     * @secure
     */
    chatRoomControllerUnpinMessage: (
      messageId: string,
      roomId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/chat-rooms/${roomId}/messages/${messageId}/pinned`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerGetRoomRecipes
     * @request GET:/api/chat-rooms/{roomId}/recipes
     * @secure
     */
    chatRoomControllerGetRoomRecipes: (
      roomId: string,
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        type?: RoomRecipeType;
        searchTerm?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RecipeItem[], any>({
        path: `/api/chat-rooms/${roomId}/recipes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerAddRoomRecipes
     * @request POST:/api/chat-rooms/{roomId}/recipes
     * @secure
     */
    chatRoomControllerAddRoomRecipes: (
      roomId: string,
      data: AddRoomRecipesDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/chat-rooms/${roomId}/recipes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerRemoveAllRoomRecipes
     * @request DELETE:/api/chat-rooms/{roomId}/recipes
     * @secure
     */
    chatRoomControllerRemoveAllRoomRecipes: (roomId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/chat-rooms/${roomId}/recipes`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerGetRoomRecipe
     * @request GET:/api/chat-rooms/{roomId}/recipes/{recipeId}
     * @secure
     */
    chatRoomControllerGetRoomRecipe: (
      recipeId: string,
      roomId: string,
      params: RequestParams = {},
    ) =>
      this.request<RecipeItem, any>({
        path: `/api/chat-rooms/${roomId}/recipes/${recipeId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Room
     * @name ChatRoomControllerRemoveRoomRecipes
     * @request DELETE:/api/chat-rooms/{roomId}/recipes/{recipeIds}
     * @secure
     */
    chatRoomControllerRemoveRoomRecipes: (
      recipeIds: string[],
      roomId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/chat-rooms/${roomId}/recipes/${recipeIds}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prompt Library
     * @name PromptLibraryControllerGetPrompts
     * @request GET:/api/prompt-library
     * @secure
     */
    promptLibraryControllerGetPrompts: (
      query?: {
        /** @default 0 */
        skip?: number;
        /** @default 10 */
        take?: number;
        searchTerm?: string;
        sortBy?: PromptSortOptions;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetPromptsResult, any>({
        path: `/api/prompt-library`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prompt Library
     * @name PromptLibraryControllerCreatePrompt
     * @request POST:/api/prompt-library
     * @secure
     */
    promptLibraryControllerCreatePrompt: (data: CreatePromptDto, params: RequestParams = {}) =>
      this.request<PromptItem, any>({
        path: `/api/prompt-library`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prompt Library
     * @name PromptLibraryControllerGetPromptById
     * @request GET:/api/prompt-library/{promptId}
     * @secure
     */
    promptLibraryControllerGetPromptById: (promptId: string, params: RequestParams = {}) =>
      this.request<PromptItem, any>({
        path: `/api/prompt-library/${promptId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prompt Library
     * @name PromptLibraryControllerUpdatePrompt
     * @request PATCH:/api/prompt-library/{promptId}
     * @secure
     */
    promptLibraryControllerUpdatePrompt: (
      promptId: string,
      data: UpdatePromptDto,
      params: RequestParams = {},
    ) =>
      this.request<PromptItem, any>({
        path: `/api/prompt-library/${promptId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prompt Library
     * @name PromptLibraryControllerSoftDeletePrompts
     * @request DELETE:/api/prompt-library/{promptIds}
     * @secure
     */
    promptLibraryControllerSoftDeletePrompts: (promptIds: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/prompt-library/${promptIds}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Prompt Library
     * @name PromptLibraryControllerFullyDeletePrompts
     * @request DELETE:/api/prompt-library/{promptIds}/full
     * @secure
     */
    promptLibraryControllerFullyDeletePrompts: (promptIds: string[], params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/prompt-library/${promptIds}/full`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Message
     * @name ChatMessageControllerAddMessageReaction
     * @request POST:/api/chat-messages/{messageId}/reactions
     * @secure
     */
    chatMessageControllerAddMessageReaction: (
      messageId: string,
      data: AddMessageReactionDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/chat-messages/${messageId}/reactions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Chat Message
     * @name ChatMessageControllerDeleteMessageReaction
     * @request POST:/api/chat-messages/{messageId}/reactions/unreact
     * @secure
     */
    chatMessageControllerDeleteMessageReaction: (
      messageId: string,
      data: AddMessageReactionDto,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/chat-messages/${messageId}/reactions/unreact`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
}
