export const FOLDER_TREE_RECYCLE_BIN = {
  id: "trash",
  name: "Trash",
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  deletedAt: new Date().toISOString(),
  children: [],
  creator: null,
  images: [],
  user: null,
  parentFolder: null,
  pinned: false,
  imagesCount: 0,
}

export const FOLDER_TREE_DELETED_IMAGES = {
  id: "deleted-images",
  name: "Deleted Images",
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  deletedAt: new Date().toISOString(),
  children: [],
  images: [],
  user: null,
  creator: null,
  parentFolder: null,
  pinned: false,
  imagesCount: 0,
}

export const FOLDER_TREE_FEATURE = {
  id: "file-feature",
  name: "Feature Update",
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  deletedAt: new Date().toISOString(),
  children: [],
  images: [],
  user: null,
  creator: null,
  parentFolder: null,
  pinned: false,
  imagesCount: 0,
  type: "notion",
  url: "https://www.notion.so/atherlabs/AtherX-ArtVenture-ab590bf30de14e809975087082b84022",
}

export const FOLDER_TREE_PROMPTS_BOOK_ENG = {
  id: "file-prompt-book-eng",
  name: "Prompt Book (English)",
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  deletedAt: new Date().toISOString(),
  children: [],
  images: [],
  user: null,
  creator: null,
  parentFolder: null,
  pinned: false,
  imagesCount: 0,
  type: "pdf",
  url: "https://contents.playsipher.com/pdf/Stable+Diffusion+Prompt+Book+From+OpenArt+11-13_compressed.pdf",
}

export const FOLDER_TREE_PROMPTS_BOOK_VI = {
  id: "file-prompt-book-vi",
  name: "Prompt Book (Vietnamese)",
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  deletedAt: new Date().toISOString(),
  children: [],
  images: [],
  user: null,
  creator: null,
  parentFolder: null,
  pinned: false,
  imagesCount: 0,
  type: "pdf",
  url: "https://contents.playsipher.com/pdf/Prompt-Book-VI.pdf",
}
