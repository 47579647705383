export const countryData = [
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1e8.svg",
    country: "Ascension Island",
    code: "ac"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1e9.svg",
    country: "Andorra",
    code: "ad"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1ea.svg",
    country: "United Arab Emirates",
    code: "ae"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1eb.svg",
    country: "Afghanistan",
    code: "af"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1ec.svg",
    country: "Antigua & Barbuda",
    code: "ag"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1ee.svg",
    country: "Anguilla",
    code: "ai"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1f1.svg",
    country: "Albania",
    code: "al"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1f2.svg",
    country: "Armenia",
    code: "am"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1f4.svg",
    country: "Angola",
    code: "ad"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1f6.svg",
    country: "Antarctica",
    code: "aq"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1f7.svg",
    country: "Argentina",
    code: "ar"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1f8.svg",
    country: "American Samoa",
    code: "as"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1f9.svg",
    country: "Austria",
    code: "at"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1fa.svg",
    country: "Australia",
    code: "au"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1fc.svg",
    country: "Aruba",
    code: "aw"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1fd.svg",
    country: "Åland Islands",
    code: "ax"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e6-1f1ff.svg",
    country: "Azerbaijan",
    code: "az"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1e6.svg",
    country: "Bosnia & Herzegovina",
    code: "ba"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1e7.svg",
    country: "Barbados",
    code: "bb"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1e9.svg",
    country: "Bangladesh",
    code: "bd"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1ea.svg",
    country: "Belgium",
    code: "be"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1eb.svg",
    country: "Burkina Faso",
    code: "bf"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1ec.svg",
    country: "Bulgaria",
    code: "bg"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1ed.svg",
    country: "Bahrain",
    code: "bh"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1ee.svg",
    country: "Burundi",
    code: "bi"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1ef.svg",
    country: "Benin",
    code: "bj"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1f1.svg",
    country: "St. Barthélemy",
    code: "bl"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1f2.svg",
    country: "Bermuda",
    code: "bm"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1f3.svg",
    country: "Brunei",
    code: "bn"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1f4.svg",
    country: "Bolivia",
    code: "bo"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1f6.svg",
    country: "Caribbean Netherlands"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1f7.svg",
    country: "Brazil",
    code: "br"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1f8.svg",
    country: "Bahamas",
    code: "bs"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1f9.svg",
    country: "Bhutan",
    code: "bt"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1fb.svg",
    country: "Bouvet Island",
    code: "bv"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1fc.svg",
    country: "Botswana",
    code: "bw"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1fe.svg",
    country: "Belarus",
    code: "by"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e7-1f1ff.svg",
    country: "Belize",
    code: "bz"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1e6.svg",
    country: "Canada",
    code: "ca"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1e8.svg",
    country: "Cocos (Keeling) Islands",
    code: "cc"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1e9.svg",
    country: "Congo - Kinshasa",
    code: "cg"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1eb.svg",
    country: "Central African Republic",
    code: "cf"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1ec.svg",
    country: "Congo - Brazzaville",
    code: "cd"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1ed.svg",
    country: "Switzerland",
    code: "ch"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1ee.svg",
    country: "Côte d’Ivoire",
    code: "ci"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1f0.svg",
    country: "Cook Islands",
    code: "ck"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1f1.svg",
    country: "Chile",
    code: "cl"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1f2.svg",
    country: "Cameroon",
    code: "cm"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1f3.svg",
    country: "China",
    code: "cn"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1f4.svg",
    country: "Colombia",
    code: "co"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1f5.svg",
    country: "Clipperton Island",
    code: "cp"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1f7.svg",
    country: "Costa Rica",
    code: "cr"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1fa.svg",
    country: "Cuba",
    code: "cu"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1fb.svg",
    country: "Cape Verde",
    code: "cv"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1fc.svg",
    country: "Curaçao",
    code: "cw"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1fd.svg",
    country: "Christmas Island",
    code: "cx"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1fe.svg",
    country: "Cyprus",
    code: "cy"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e8-1f1ff.svg",
    country: "Czechia",
    code: "cz"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e9-1f1ea.svg",
    country: "Germany",
    code: "de"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e9-1f1ec.svg",
    country: "Diego Garcia",
    code: "dg"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e9-1f1ef.svg",
    country: "Djibouti",
    code: "dj"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e9-1f1f0.svg",
    country: "Denmark",
    code: "dk"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e9-1f1f2.svg",
    country: "Dominica",
    code: "dm"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e9-1f1f4.svg",
    country: "Dominican Republic",
    code: "do"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1e9-1f1ff.svg",
    country: "Algeria",
    code: "dz"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ea-1f1e6.svg",
    country: "Ceuta & Melilla",
    code: "ea"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ea-1f1e8.svg",
    country: "Ecuador",
    code: "ec"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ea-1f1ea.svg",
    country: "Estonia",
    code: "ee"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ea-1f1ec.svg",
    country: "Egypt",
    code: "eg"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ea-1f1ed.svg",
    country: "Western Sahara",
    code: "eh"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ea-1f1f7.svg",
    country: "Eritrea",
    code: "er"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ea-1f1f8.svg",
    country: "Spain",
    code: "es"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ea-1f1f9.svg",
    country: "Ethiopia",
    code: "et"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ea-1f1fa.svg",
    country: "European Union",
    code: "eu"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1eb-1f1ee.svg",
    country: "Finland",
    code: "fi"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1eb-1f1ef.svg",
    country: "Fiji",
    code: "fj"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1eb-1f1f0.svg",
    country: "Falkland Islands",
    code: "fk"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1eb-1f1f2.svg",
    country: "Micronesia",
    code: "fm"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1eb-1f1f4.svg",
    country: "Faroe Islands",
    code: "fo"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1eb-1f1f7.svg",
    country: "France",
    code: "fr"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1e6.svg",
    country: "Gabon",
    code: "ga"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1e7.svg",
    country: "United Kingdom",
    code: "gb"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1e9.svg",
    country: "Grenada",
    code: "gd"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1ea.svg",
    country: "Georgia",
    code: "ge"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1eb.svg",
    country: "French Guiana",
    code: "gf"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1ec.svg",
    country: "Guernsey",
    code: "gg"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1ed.svg",
    country: "Ghana",
    code: "gh"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1ee.svg",
    country: "Gibraltar",
    code: "gi"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1f1.svg",
    country: "Greenland",
    code: "gl"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1f2.svg",
    country: "Gambia",
    code: "gm"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1f3.svg",
    country: "Guinea",
    code: "gn"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1f5.svg",
    country: "Guadeloupe",
    code: "gp"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1f6.svg",
    country: "Equatorial Guinea",
    code: "gq"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1f7.svg",
    country: "Greece",
    code: "gr"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1f8.svg",
    country: "South Georgia & South', Sandwich Islands",
    code: "gs"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1f9.svg",
    country: "Guatemala",
    code: "gt"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1fa.svg",
    country: "Guam",
    code: "gu"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1fc.svg",
    country: "Guinea-Bissau",
    code: "gw"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ec-1f1fe.svg",
    country: "Guyana",
    code: "gy"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ed-1f1f0.svg",
    country: "Hong Kong SAR China",
    code: "hk"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ed-1f1f2.svg",
    country: "Heard & McDonald Islands",
    code: "hm"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ed-1f1f3.svg",
    country: "Honduras",
    code: "hn"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ed-1f1f7.svg",
    country: "Croatia",
    code: "hr"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ed-1f1f9.svg",
    country: "Haiti",
    code: "ht"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ed-1f1fa.svg",
    country: "Hungary",
    code: "hu"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ee-1f1e8.svg",
    country: "Canary Islands",
    code: "ic"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ee-1f1e9.svg",
    country: "Indonesia",
    code: "id"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ee-1f1ea.svg",
    country: "Ireland",
    code: "ie"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ee-1f1f1.svg",
    country: "Israel",
    code: "il"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ee-1f1f2.svg",
    country: "Isle of Man",
    code: "im"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ee-1f1f3.svg",
    country: "India",
    code: "in"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ee-1f1f4.svg",
    country: "British Indian Ocean Territory",
    code: "io"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ee-1f1f6.svg",
    country: "Iraq",
    code: "iq"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ee-1f1f7.svg",
    country: "Iran"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ee-1f1f8.svg",
    country: "Iceland",
    code: "is"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ee-1f1f9.svg",
    country: "Italy",
    code: "it"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ef-1f1ea.svg",
    country: "Jersey",
    code: "je"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ef-1f1f2.svg",
    country: "Jamaica",
    code: "jm"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ef-1f1f4.svg",
    country: "Jordan",
    code: "jo"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ef-1f1f5.svg",
    country: "Japan",
    code: "jp"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f0-1f1ea.svg",
    country: "Kenya",
    code: "ke"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f0-1f1ec.svg",
    country: "Kyrgyzstan",
    code: "kg"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f0-1f1ed.svg",
    country: "Cambodia",
    code: "kh"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f0-1f1ee.svg",
    country: "Kiribati",
    code: "ki"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f0-1f1f2.svg",
    country: "Comoros",
    code: "km"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f0-1f1f3.svg",
    country: "St. Kitts & Nevis",
    code: "kn"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f0-1f1f5.svg",
    country: "North Korea",
    code: "kp"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f0-1f1f7.svg",
    country: "South Korea",
    code: "kr"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f0-1f1fc.svg",
    country: "Kuwait",
    code: "kw"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f0-1f1fe.svg",
    country: "Cayman Islands",
    code: "ky"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f0-1f1ff.svg",
    country: "Kazakhstan",
    code: "kz"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f1-1f1e6.svg",
    country: "Laos",
    code: "la"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f1-1f1e7.svg",
    country: "Lebanon",
    code: "lb"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f1-1f1e8.svg",
    country: "St. Lucia",
    code: "lc"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f1-1f1ee.svg",
    country: "Liechtenstein",
    code: "li"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f1-1f1f0.svg",
    country: "Sri Lanka",
    code: "lk"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f1-1f1f7.svg",
    country: "Liberia",
    code: "lr"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f1-1f1f8.svg",
    country: "Lesotho",
    code: "ls"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f1-1f1f9.svg",
    country: "Lithuania",
    code: "lt"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f1-1f1fa.svg",
    country: "Luxembourg",
    code: "lu"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f1-1f1fb.svg",
    country: "Latvia",
    code: "lv"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f1-1f1fe.svg",
    country: "Libya",
    code: "ly"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1e6.svg",
    country: "Morocco",
    code: "ma"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1e8.svg",
    country: "Monaco",
    code: "mc"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1e9.svg",
    country: "Moldova",
    code: "md"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1ea.svg",
    country: "Montenegro",
    code: "me"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1eb.svg",
    country: "St. Martin",
    code: "mf"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1ec.svg",
    country: "Madagascar",
    code: "mg"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1ed.svg",
    country: "Marshall Islands",
    code: "mh"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1f0.svg",
    country: "North Macedonia",
    code: "mk"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1f1.svg",
    country: "Mali",
    code: "ml"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1f2.svg",
    country: "Myanmar (Burma)",
    code: "mm"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1f3.svg",
    country: "Mongolia",
    code: "mn"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1f4.svg",
    country: "Macao Sar China",
    code: "mo"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1f5.svg",
    country: "Northern Mariana Islands",
    code: "mp"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1f6.svg",
    country: "Martinique",
    code: "mq"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1f7.svg",
    country: "Mauritania",
    code: "mr"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1f8.svg",
    country: "Montserrat",
    code: "ms"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1f9.svg",
    country: "Malta",
    code: "mt"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1fa.svg",
    country: "Mauritius",
    code: "mu"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1fb.svg",
    country: "Maldives",
    code: "mv"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1fc.svg",
    country: "Malawi",
    code: "mw"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1fd.svg",
    country: "Mexico",
    code: "mx"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1fe.svg",
    country: "Malaysia",
    code: "my"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f2-1f1ff.svg",
    country: "Mozambique",
    code: "mz"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f3-1f1e6.svg",
    country: "Namibia",
    code: "na"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f3-1f1e8.svg",
    country: "New Caledonia",
    code: "nc"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f3-1f1ea.svg",
    country: "Niger",
    code: "ne"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f3-1f1eb.svg",
    country: "Norfolk Island",
    code: "nf"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f3-1f1ec.svg",
    country: "Nigeria",
    code: "ng"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f3-1f1ee.svg",
    country: "Nicaragua",
    code: "ni"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f3-1f1f1.svg",
    country: "Netherlands",
    code: "nl"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f3-1f1f4.svg",
    country: "Norway",
    code: "no"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f3-1f1f5.svg",
    country: "Nepal",
    code: "np"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f3-1f1f7.svg",
    country: "Nauru",
    code: "nr"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f3-1f1fa.svg",
    country: "Niue",
    code: "nu"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f3-1f1ff.svg",
    country: "New Zealand",
    code: "nz"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f4-1f1f2.svg",
    country: "Oman",
    code: "om"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f5-1f1e6.svg",
    country: "Panama",
    code: "pa"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f5-1f1ea.svg",
    country: "Peru",
    code: "pe"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f5-1f1eb.svg",
    country: "French Polynesia",
    code: "pf"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f5-1f1ec.svg",
    country: "Papua New Guinea",
    code: "pg"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f5-1f1ed.svg",
    country: "Philippines",
    code: "ph"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f5-1f1f0.svg",
    country: "Pakistan",
    code: "pk"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f5-1f1f1.svg",
    country: "Poland",
    code: "pl"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f5-1f1f2.svg",
    country: "St. Pierre & Miquelon",
    code: "pm"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f5-1f1f3.svg",
    country: "Pitcairn Islands",
    code: "pn"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f5-1f1f7.svg",
    country: "Puerto Rico",
    code: "pr"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f5-1f1f8.svg",
    country: "Palestinian Territories",
    code: "ps"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f5-1f1f9.svg",
    country: "Portugal",
    code: "pt"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f5-1f1fc.svg",
    country: "Palau",
    code: "pw"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f5-1f1fe.svg",
    country: "Paraguay",
    code: "py"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f6-1f1e6.svg",
    country: "Qatar",
    code: "qa"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f7-1f1ea.svg",
    country: "Réunion",
    code: "re"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f7-1f1f4.svg",
    country: "Romania",
    code: "ro"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f7-1f1f8.svg",
    country: "Serbia",
    code: "yu"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f7-1f1fa.svg",
    country: "Russia",
    code: "ru"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f7-1f1fc.svg",
    country: "Rwanda",
    code: "rw"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1e6.svg",
    country: "Saudi Arabia",
    code: "sa"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1e7.svg",
    country: "Solomon Islands",
    code: "sb"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1e8.svg",
    country: "Seychelles",
    code: "sc"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1e9.svg",
    country: "Sudan",
    code: "sd"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1ea.svg",
    country: "Sweden",
    code: "se"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1ec.svg",
    country: "Singapore",
    code: "sg"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1ed.svg",
    country: "St. Helena",
    code: "sh"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1ee.svg",
    country: "Slovenia",
    code: "si"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1ef.svg",
    country: "Svalbard & Jan Mayen",
    code: "sj"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1f0.svg",
    country: "Slovakia",
    code: "sk"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1f1.svg",
    country: "Sierra Leone",
    code: "sl"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1f2.svg",
    country: "San Marino",
    code: "sm"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1f3.svg",
    country: "Senegal",
    code: "sn"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1f4.svg",
    country: "Somalia",
    code: "so"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1f7.svg",
    country: "Suriname",
    code: "sr"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1f8.svg",
    country: "South Sudan",
    code: "ss"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1f9.svg",
    country: "São Tomé & Príncipe",
    code: "st"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1fb.svg",
    country: "El Salvador",
    code: "sv"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1fd.svg",
    country: "Sint Maarten",
    code: "sx"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1fe.svg",
    country: "Syria",
    code: "sy"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f8-1f1ff.svg",
    country: "Eswatini",
    code: "sz"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1e6.svg",
    country: "Tristan Da Cunha",
    code: "sh"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1e8.svg",
    country: "Turks & Caicos Islands",
    code: "tc"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1e9.svg",
    country: "Chad",
    code: "td"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1eb.svg",
    country: "French Southern Territories",
    code: "tf"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1ec.svg",
    country: "Togo",
    code: "tg"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1ed.svg",
    country: "Thailand",
    code: "th"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1ef.svg",
    country: "Tajikistan",
    code: "tj"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1f0.svg",
    country: "Tokelau",
    code: "tk"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1f1.svg",
    country: "Timor-Leste",
    code: "tl"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1f2.svg",
    country: "Turkmenistan",
    code: "tm"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1f3.svg",
    country: "Tunisia",
    code: "tn"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1f4.svg",
    country: "Tonga",
    code: "to"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1f7.svg",
    country: "Turkey",
    code: "tr"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1f9.svg",
    country: "Trinidad & Tobago",
    code: "tt"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1fb.svg",
    country: "Tuvalu",
    code: "tv"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1fc.svg",
    country: "Taiwan",
    code: "tw"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1f9-1f1ff.svg",
    country: "Tanzania",
    code: "tz"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fa-1f1e6.svg",
    country: "Ukraine",
    code: "ua"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fa-1f1ec.svg",
    country: "Uganda",
    code: "ug"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fa-1f1f2.svg",
    country: "U.S. Outlying Islands",
    code: "um"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fa-1f1f3.svg",
    country: "United Nations",
    code: "un"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fa-1f1f8.svg",
    country: "United States",
    code: "us"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fa-1f1fe.svg",
    country: "Uruguay",
    code: "uy"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fa-1f1ff.svg",
    country: "Uzbekistan",
    code: "uz"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fb-1f1e6.svg",
    country: "Vatican City",
    code: "va"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fb-1f1e8.svg",
    country: "St. Vincent & Grenadines",
    code: "vc"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fb-1f1ea.svg",
    country: "Venezuela",
    code: "ve"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fb-1f1ec.svg",
    country: "British Virgin Islands",
    code: "vg"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fb-1f1ee.svg",
    country: "U.S. Virgin Islands",
    code: "vi"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fb-1f1f3.svg",
    country: "Vietnam",
    code: "vn"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fb-1f1fa.svg",
    country: "Vanuatu",
    code: "vu"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fc-1f1eb.svg",
    country: "Wallis & Futuna",
    code: "wf"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fc-1f1f8.svg",
    country: "Samoa",
    code: "ws"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fd-1f1f0.svg",
    country: "Kosovo",
    code: "xk"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fe-1f1ea.svg",
    country: "Yemen",
    code: "ye"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1fe-1f1f9.svg",
    country: "Mayotte",
    code: "yt"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ff-1f1e6.svg",
    country: "South Africa",
    code: "za"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ff-1f1f2.svg",
    country: "Zambia",
    code: "zm"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f1ff-1f1fc.svg",
    country: "Zimbabwe",
    code: "zw"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f3f4-e0067-e0062-e0065-e006e-e0067-e007f.svg",
    country: "England",
    code: "uk"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f3f4-e0067-e0062-e0073-e0063-e0074-e007f.svg",
    country: "Scotland",
    code: "uk"
  },
  {
    flag: "https://cdn.jsdelivr.net/npm/twemoji@11.3.0/2/svg/1f3f4-e0067-e0062-e0077-e006c-e0073-e007f.svg",
    country: "Wales",
    code: "uk"
  }
].sort((a, b) => a.country.localeCompare(b.country))
