const PREFACE_P = [
  "By accessing or using the Services, you consent to the collection, use, and disclosure of your personal information in accordance with this Privacy Policy.",
  "Vixion Company Limited (“VIXION”, “us”, “we”, or “our”) is committed to protecting your privacy and personal data. This Privacy Policy explains how we collect, use, disclose, and protect your personal data when you use our Services or any products that link to this Privacy Policy, such as our websites, AI-powered products, and applications (the “Services”).",
  "Please read this Privacy Policy carefully before using our Services. By using our Services, you agree to our collection, use, sharing, and protection of your personal data as described in this Privacy Policy. You also agree to our terms and conditions that apply to our Services.",
  "This Privacy Policy applies to all users of the Services.",
]

const COLLECTION_INFO_P = {
  title:
    "We may collect information about you in a variety of ways. The information we collect depends on the Services you use and your interaction with our Services.",
  content: [
    "Personal Data: We collect personal information, such as your name, email address, and other information you provide when you register for a VIXION account or participate in certain activities through the Services.",
    "Derivative Data: We automatically collect information about your usage of the Services, such as the content you generate and interact with, the frequency and duration of your activities, and other analytics data.",
    "Device Information: We collect device-specific information, including device model, operating system, browser type, unique device identifiers, and IP address.",
    "Third-Party Data: We may receive personal information from third parties, such as social media platforms, if you choose to connect your VIXION account to such platforms.",
    "Cookies and Similar Technologies: We use cookies and similar technologies to collect information about your use of the Services and to improve your user experience. You have control over the use of cookies and similar technologies via your web browser settings. You can customize your preferences, refuse all cookies, or set your browser to notify you when a cookie is being sent. Please be aware that disabling cookies may affect the functionality of the Services, and some features may not operate as intended if cookies are not accepted.",
  ],
}

const USE_INFO_P = {
  title: "We may use your personal information for various purposes, including:",
  content: [
    "To provide, maintain, and improve the Services: The personal information you provide us is essential for delivering the Services you use. This encompasses managing your VIXION account, which allows you to log in, as well as operating and maintaining the Services effectively;",
    "To communicate with you about your VIXION account: We utilize the contact information you provide, such as your email address, to reach out to you regarding essential aspects of the Services. This includes sending communications about technical issues you might encounter, security alerts to ensure the protection of your account, and administrative messages that pertain to your use of our Services. Our goal is to keep you well-informed and to ensure that you have a secure and efficient experience while using our Services.",
    "To monitor and analyze trends, usage, and activities in connection with the Services: We diligently examine the data related to your interaction with our Services, including the content you create and engage with. This analysis helps us gain a deeper understanding of how users utilize our Services. By evaluating the effectiveness of the Services, we can identify areas for enhancement and tailor our development efforts to better serve our user base. Our commitment to continuous improvement drives us to refine our Services and ensure they meet the evolving needs of our users;",
    "To personalize and improve your user experience: We are dedicated to customizing the Services to better suit your preferences and requirements. To achieve this, we not only utilize the information you directly provide to us but also combine it with the derivative data we collect about your usage patterns, as well as information we obtain from other sources, both within and outside of our Services. This amalgamation of data, which includes insights from the behavior of other users, allows us to refine and tailor your experience with VIXION.",
    "To enforce our Terms of Service and other policies: We employ the personal information we collect to ensure compliance with our Terms of Service and to uphold our various policies. This is crucial for maintaining a safe and trustworthy environment for all users of the Services. By monitoring for adherence and addressing any violations, we protect the integrity of our Services and the rights of our users, as well as our own legal rights and interests.",
    "To comply with legal obligations: We may use your personal information as necessary to fulfill our legal responsibilities, respond to legal proceedings, or as required by law enforcement or other government authorities.",
  ],
}

const SHARING_INFO_P = {
  title:
    "In our efforts to offer innovative and advanced Services, VIXION engages with technology partners, including third-party service providers that support some of our AI-powered products. When you use these Services, you acknowledge that any information you input, including personal data you incorporate within that input, may be shared with these third-party service providers to enable certain functionalities within the Services.",
  content: [
    "With your consent, and specifically by using the AI Products, you give your explicit direction and agreement for the transfer of personal data you include in an input to our partnered third parties. It is important to note that the level of data protection in the United States, or other countries where our partners operate, may vary from the protections offered within Vietnam.",
    "As with other instances of data sharing outlined in this Privacy Policy, our service providers, including those that facilitate our AI Products, are contractually obligated to maintain the confidentiality of your personal data and are restricted to use it solely for the purposes for which it is provided to them.",
  ],
  ps: [
    "Please be assured that sharing of your information with our technology partners is done with the utmost consideration for your privacy and in alignment with this Privacy Policy.",
  ],
}

const THIRD_PARTY_D = {
  title:
    "We respect your privacy and offer the convenience of creating a VIXION account and logging in using third-party services such as Sign in with Google or Sign in with Apple. When you use such services, we collect your email address, name, and profile picture. This information is used to create your VIXION account, verify your identity during login, and assist with password resets if necessary. Your information is securely stored on our servers until your VIXION account is terminated, either by yourself or by us. Rest assured, your information pertaining to this section will neither be disclosed nor shared.",
}

const YOUR_RIGHTS_P = {
  title:
    "We respect your choices and rights regarding your personal data. Depending on the exact nature of our relationship with you and how you use the Services, you may have different options and rights available to you. These include:",
  content: [
    `You can request access to, correction of, or deletion of your personal data we’ve collected. You can also request to restrict or stop processing your personal data in certain circumstances;`,
    "You can change your email marketing preferences at any time, such as by using the opt-out mechanism provided in our marketing emails, updating your VIXION account settings, or contacting us with your request;",
    "You can change your browser or mobile device settings to block, manage, delete or limit tracking technologies like cookies. In some cases, blocking or disabling cookies may cause the Services not to work as intended, and some features may not be available;",
    `If you’re under the age of 16 and have a VIXION account, you can ask that we remove or anonymize certain content you’ve provided on the Services;`,
  ],
  ps: [
    "Some jurisdictions may provide you with additional rights under applicable law. To make use of these rights, please get in touch with us using the contact information provided in section XI. CONTACT.",
    "Please note that we may ask you for additional information to verify your identity before completing your request.",
  ],
}

const CHANGES_P =
  "VIXION reserves the right to update or modify this Privacy Policy at any time. We will notify you of any significant changes by posting an update on our Services or by sending an email notification. Your continued use of the Services after any such changes constitutes your acceptance of the new Privacy Policy. If you do not agree to any of these terms or any future version of the Privacy Policy, do not use or access (or continue to access) the Services. We encourage you to review this policy regularly to stay informed."

const CHILDREN_P =
  "The Services is intended for adult Users and not intended to be accessed or used by children under the age of 16. We do not knowingly collect personal data from children without obtaining parental consent in accordance with applicable laws and regulations. If you are a parent or guardian and you believe your child has provided us with their personal data without your consent, please contact us using the contact information provided in section X. CONTACT and we will take steps to delete it."

const MISCELLANEOUS_P = {
  content: [
    "Third-Party Websites: Our Services may contain links to third-party websites and applications of interest that are not affiliated with us. Once you have used these links to leave our Services, any information you provide to these third parties is not covered by this Privacy Policy.",
    "Data Retention: After your VIXION account has been terminated or deactivated, we will preserve your profile information and any content you have provided for a period that is commercially reasonable and as long as we possess a legitimate reason for its retention. Specifically, VIXION maintains your data to comply with legal and audit responsibilities, and for backup and archival purposes. This ensures that we adhere to various regulatory requirements and preserve the integrity of our Services, while also safeguarding your information against potential data loss or system failures.",
    "Security of Your Information: We implement reasonable security measures to protect your personal information. However, despite our efforts, we must acknowledge that no system can guarantee complete security. Therefore, we cannot fully assure the security of the information you transmit to VIXION, nor can we guarantee that your data on the Service will not be accessed, disclosed, altered, or destroyed under all circumstances.",
    "International Data Transfers: Your personal information may be transferred to and processed in countries other than Vietnam where VIXION's service providers operate. It is important to recognize that the data protection laws in these countries may differ from those in your own country. In some instances, these laws might not offer the same level of data protection. However, we take steps to ensure that adequate safeguards are in place to protect your personal data and that all transfers comply with applicable data protection laws. Our commitment is to provide protection for your personal data, no matter where it is processed.",
  ],
}

const ADDITIONAL_INFORMATION = {
  title:
    "If you are located in the European Economic Area (EEA), the United Kingdom, or Switzerland, the following additional information applies to you. VIXION is committed to compliance with the General Data Protection Regulation (GDPR) and recognizes the following rights of European users:",
  content: [
    "Right of Access: You may request confirmation as to whether or not personal data concerning you is being processed and access to your stored personal data, along with supplementary information.",
    "Right to Rectification: You have the right to have incorrect or incomplete personal data we hold about you corrected.",
    "Right to Erasure: Under certain circumstances, you may request the deletion of your personal data from our systems.",
    "Right to Restriction of Processing: You may ask for the processing of your personal data to be restricted if there is a dispute about its accuracy or the processing is unlawful.",
    "Right to Data Portability: You may request to receive the personal data concerning you in a structured, commonly used, and machine-readable format or request the direct transfer to another controller.",
    "Right to Object: You have the right to object to the processing of your personal data based on grounds relating to your particular situation, particularly in the case of data processing for direct marketing purposes or data processing based on our legitimate interests.",
    "Right to Withdraw Consent: Where the processing is based on your consent, you have the right to withdraw consent at any time, without affecting the lawfulness of processing based on consent before its withdrawal.",
    "Right to Lodge a Complaint: You have the right to lodge a complaint with a supervisory authority in your country of residence, place of work, or the place of the alleged infringement.",
  ],
  ps: [
    "VIXION processes your personal data on the lawful basis of your consent, the necessity to perform a contract with you, compliance with our legal obligations, protection of your vital interests, or our legitimate interests, provided those interests do not override your fundamental rights and freedoms.",
  ],
}

const ADDITIONAL_INFORMATION_US = {
  title:
    "For users residing in the United States, VIXION recognizes and adheres to certain state-specific privacy rights. Notably, if you are a resident of California, you are granted specific rights under the California Consumer Privacy Act (CCPA).",

  content: [
    "Request access to the personal information VIXION has collected about you;",
    "Request that VIXION delete your personal information;",
    "Request that VIXION correct inaccurate personal information;",
    `Opt out of the "sharing" of your personal information;`,
    "Appeal decisions where we deny your rights request",
  ],
  ps: [
    "To exercise any CCPA rights, or for any privacy-related requests, including accessing, correcting, or deleting your personal information, you may reach out to us using the contact information provided in section X. CONTACT. Please note that we may authenticate your request using the email address associated with your VIXION account and, if necessary, request proof of residency. We aim to respond to verified requests within the timeframe required by applicable law.",
  ],
}

const CONTACT_P = ""
export {
  PREFACE_P,
  COLLECTION_INFO_P,
  USE_INFO_P,
  SHARING_INFO_P,
  THIRD_PARTY_D,
  YOUR_RIGHTS_P,
  CHANGES_P,
  MISCELLANEOUS_P,
  CONTACT_P,
  CHILDREN_P,
  ADDITIONAL_INFORMATION,
  ADDITIONAL_INFORMATION_US,
}
