export * from "./account-setting"
export * from "./country"
export * from "./faq"
export * from "./folder-tree"
export * from "./landing"
export * from "./model"
export * from "./privacy-policy"
export * from "./regex"
export * from "./terms-of-service"
export * from "./tips-and-trick"
export * from "./paymentContracts"

export const clientUrl = process.env.NEXT_PUBLIC_CLIENT_URL || "https://artventure.sipher.gg"
export const clientDomain = clientUrl.replace("https://", "").replace("http://", "")
export const prodClientUrl = "protogaia.com"
export const cdnUrl = process.env.NEXT_PUBLIC_CDN_URL || `cdn.${prodClientUrl}`
export const cdnPublicFolderUrl = `https://${cdnUrl}/resources/public`
export const telegramBotId = process.env.NEXT_PUBLIC_TELEGRAM_BOT_ID
export const xsollaUrl = `https://${process.env.NEXT_PUBLIC_TEST_NET === "true" ? "sandbox-" : ""}secure.xsolla.com`
export const payment2C2PUrl = `https://${process.env.NEXT_PUBLIC_TEST_NET === "true" ? "sandbox-" : ""}pgw-ui.2c2p.com`

export const devCdnUrl = "cdn-artventure.sipher.gg"
