const PREFACE = `VIXION COMPANY LIMITED (“VIXION”, “us”, “we”, or “our”) is a Vietnamese limited liability company that offers various AI Products and services, such as GAIA, GAIA, and any other products that VIXION may develop in the future (collectively, the “Services”). These Terms of Service ("Terms") constitute a legally binding agreement between you and VIXION, governing your access to and use of the Services.`

const ACCEPTANCE = [
  "BY USING THE SERVICES, YOU CONFIRM THAT YOU HAVE READ, UNDERSTOOD, AND ACCEPTED THESE TERMS AND ANY APPLICABLE POLICIES, INCLUDING OUR PRIVACY POLICY. IF YOU DO NOT AGREE TO THESE TERMS, YOU MUST NOT USE THE SERVICES. THESE TERMS APPLY TO ALL VISITORS, USERS, AND OTHERS WHO ACCESS OR USE THE SERVICES  ",
  "TO ENTER INTO THE CONTRACT CREATED BY THESE TERMS, YOU MUST BE AN ADULT OF THE LEGAL AGE OF MAJORITY IN YOUR COUNTRY OF RESIDENCE. YOU ARE LEGALLY AND FINANCIALLY RESPONSIBLE FOR ALL ACTIONS USING OR ACCESSING OUR SOFTWARE, INCLUDING THE ACTIONS OF ANYONE YOU ALLOW TO ACCESS TO YOUR ACCOUNT. YOU AFFIRM THAT YOU HAVE REACHED THE LEGAL AGE OF MAJORITY, UNDERSTAND AND ACCEPT THESE TERMS (INCLUDING ITS DISPUTE RESOLUTION TERMS). IF YOU ARE UNDER THE LEGAL AGE OF MAJORITY, YOUR PARENT OR LEGAL GUARDIAN MUST CONSENT TO THESE TERMS.",
]

const CHANGES =
  "VIXION may revise and update these Terms from time to time in our sole discretion. All changes are effective immediately when posted, and your continued use of the Services following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page frequently, so you are aware of any changes, as they are binding on you."

const ACCOUNT = [
  "You may be required to create an account and provide certain information about yourself to use some features of the Services. In addition to creating an account directly with VIXION, you may also have the option to sign up and log in using third-party authentication services. When you sign up for a VIXION account using a third-party account, you are authorizing VIXION to access certain information from your third-party account, such as your name, email address, and profile picture. VIXION will only use this information to create and manage your VIXION account. Please note that each third-party account provider has its own privacy policy that governs how your information is collected and used. You should review the privacy policy of each third-party account provider before signing up for a VIXION account using their service.",
  "You are responsible for maintaining the confidentiality of your account password and for all activities that occur under your account, regardless of whether you create an account directly with VIXION or use a third-party service provider. You agree to notify VIXION immediately if you suspect any unauthorized use of your account or any other breach of security.",
]
const PROHIBITED = [
  "Violates any applicable federal, state, local, or international law or regulation;",
  "Infringe on the proprietary or privacy rights of others, including intellectual property, identity, or personal information;",
  "Interferes with the proper functioning of the Services, including any attempt to bypass or circumvent measures employed to prevent or limit access to the Services;",
  "Introduce or disseminate malicious software or data that could damage, disrupt, or impair the functionality of any technology or services;",
  "Disrupt or interfere with the operational performance of the services, or attempt to evade any security measures in place to protect the services;",
  "Utilize automated systems or software to extract data or content from the Services without authorization;",
  "Falsely represent yourself or your affiliation with any person or entity;",
  "Engage in behavior that VIXION deems detrimental to its services or its user community, or that could result in legal liability for VIXION or its users;",
  "Misrepresent content generated by AI or automated processes as human-generated;",
  "Offer guidance on medical, legal, or financial matters that should be provided by qualified professionals;",
  "Produce or distribute content that could be used in legal proceedings or for judicial purposes;",
  "Create or propagate content that could contribute to physical, psychological, or emotional harm, including harm to minors, animals, or property;",
  "Harass, abuse, defame, or discriminate against individuals or groups based on protected characteristics;",
  "Distribute sexually explicit or graphically violent content, or content that includes profanity or other offensive subjects;",
  "Promote or engage in fraudulent schemes or illegal transactions;",
  "Violate the rights of any party, including data protection and intellectual property rights;",
  "Disseminate false, deceptive, or misleading information, or support the proliferation of such content;",
  "Threaten or compromise democratic processes or institutions.",
]
const MISCELLANEOUS = [
  {
    title: "a. Copyright",
    content: [
      "You retain ownership of the Output created via the Services. However, you warrant and represent that you possess all necessary rights, licenses, and permissions for using, publishing, and sharing the Output for your intended purposes, without infringing or violating any third party's intellectual property rights, privacy rights, or other rights.",
      "You bear sole responsibility for your Output and the consequences of its use, publication, or sharing. VIXION does not endorse, support, represent, or guarantee the accuracy, truthfulness, or reliability of any Output generated through the Services. VIXION has no obligation to pre-screen, monitor, or review any Output, but reserves the right to remove or refuse to support the generation of Output that violates these Terms or any applicable law, or is deemed harmful, offensive, or inappropriate, at our sole discretion.",
      "VIXION is not responsible or liable for any loss or damage resulting from the use of any Output generated via the Services. Opinions, advice, statements, or other information contained in Output are attributable to the respective users, not VIXION. You rely on such Output at your own risk.",
    ],
  },
  {
    title: "b. Publication",
    content: [
      "Sharing and publishing the Output is permitted, provided that the Output is attributed to your name or company, and the role of VIXION or Services is explicitly disclosed. It's required that you indicate that the content is AI-generated, that the Output adheres to the Terms, and that no offensive content is disseminated.",
    ],
  },
  {
    title: "c. Warranties",
    content: [
      `The Services are provided "as is" and "as available," without any express or implied warranties. VIXION disclaims all warranties, including implied warranties of merchantability, fitness for a particular purpose, non-infringement, and title.`,
      "VIXION does not warrant that the Services will be uninterrupted, secure, error-free, or free from viruses or other harmful components, nor that defects will be corrected. VIXION makes no representations or warranties about the accuracy, reliability, or completeness of any content on the Services.",
    ],
  },
  {
    title: "d. Limitation of Liability",
    content: [
      "To the fullest extent permitted by law, VIXION, our affiliates, officers, directors, employees, agents, licensors, and service providers will not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including loss of profits or data, or any other damages resulting from or related to your use or inability to use the Services, any content on the Services, or any services obtained through the Services, even if VIXION has been advised of the possibility of such damages.",
    ],
  },
  {
    title: "e. Intellectual Property Rights",
    content: [
      "Our Services may comprise proprietary components developed by VIXION and components based on open-source software. You acknowledge that the Services may contain elements subject to open-source licensing terms. You must comply with the respective open-source licenses when using these components. All proprietary components remain the exclusive property of VIXION, our licensors, or other providers and are protected under applicable intellectual property laws. Please adhere to each component's respective licensing terms when using our Services.",
      "You acknowledge and agree that any feedback, comments, or suggestions you provide regarding the Services, including improvements or modifications, will be the exclusive property of VIXION, and you hereby irrevocably assign to VIXION all rights, titles, and interests in such feedback, comments, or suggestions.",
    ],
  },
  {
    title: "f. Indemnification",
    content: [
      "You agree to indemnify, defend, and hold harmless VIXION, our affiliates, officers, directors, employees, agents, licensors, and service providers from any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising from or related to your violation of these Terms or your use of the Services, including your Output, any use of the Services' content, services, and products not expressly authorized in these Terms, or any information obtained from the Services.",
    ],
  },
]
const GOVERNING_LAW = [
  "These Terms and any dispute or claim arising out of or in connection with them, their subject matter, or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of Vietnam, without giving effect to any choice or conflict of law provision or rule.",
  "You agree that any legal action, suit, or proceeding arising out of or related to these Terms or the Services shall be instituted exclusively in the courts of Vietnam, and you waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.",
]
const OTHER_PROVISIONS = [
  {
    title: "a. Sever ability",
    content:
      "If any provision of these Terms is held to be invalid, illegal, or unenforceable by a court of competent jurisdiction, such provision shall be modified to the minimum extent necessary to make it valid and enforceable, and the remaining provisions of these Terms will remain in full force and effect.",
  },
  {
    title: "b. No Waiver",
    content:
      "No waiver by VIXION of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of VIXION to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.",
  },
]
const AGREEMENT =
  "These Terms, together with any documents expressly referred to in them, constitute the entire agreement between you and VIXION with respect to the Services and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to the Services."

export { PREFACE, ACCEPTANCE, CHANGES, ACCOUNT, PROHIBITED, MISCELLANEOUS, GOVERNING_LAW, OTHER_PROVISIONS, AGREEMENT }
