import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactNode, useRef } from "react"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

interface QueryProviderProps {
  children: ReactNode
}

export function QueryProvider({ children }: QueryProviderProps) {
  const qcRef = useRef(queryClient)

  return <QueryClientProvider client={qcRef.current}>{children}</QueryClientProvider>
}