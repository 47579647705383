import { AddEthereumChainParameter, defineChain } from 'viem'

interface BasicChainInformation {
  urls: string[]
  name: string
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls']
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency']
}

export const FunkiSepolia = defineChain({
  id: 3397901,
  name: 'Funki Sepolia',
  network: 'FunkiSepolia',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    public: { http: ['https://funki-testnet.alt.technology'] },
    default: { http: ['https://funki-testnet.alt.technology'] },
  },
  blockExplorers: {
    default: { name: 'FunkiSepoliaExplorer', url: 'https://sepolia-sandbox.funkichain.com' },
  },
})

export const Funki = defineChain({
  id: 33979,
  name: 'Funki',
  network: 'Funki',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    public: { http: ['https://funki-testnet.alt.technology'] },
    default: { http: ['https://funki-testnet.alt.technology'] },
  },
  blockExplorers: {
    default: { name: 'FunkiExplorer', url: 'https://sepolia-sandbox.funkichain.com' },
  },
})

export const Amoy = defineChain({
  id: 80002,
  name: 'Amoy',
  network: 'Amoy',
  nativeCurrency: { name: 'Matic', symbol: 'Matic', decimals: 18 },
  rpcUrls: {
    public: { http: ['https://polygon-amoy-heimdall-rpc.publicnode.com:443'] },
    default: { http: ['https://polygon-amoy-heimdall-rpc.publicnode.com:443'] },
  },
  blockExplorers: {
    default: { name: 'AmoyScan', url: 'https://www.okx.com/explorer/amoy' },
  },
})

export const CHAINS: {
  [chainId: number]: BasicChainInformation | ExtendedChainInformation
} = {
  [3397901]: {
    name: 'Funki Sepolia',
    urls: ['https://funki-testnet.alt.technology'],
    blockExplorerUrls: ['https://sepolia-sandbox.funkichain.com'],
  },
  [33979]: {
    name: 'Funki',
    urls: ['https://funki-testnet.alt.technology'],
    blockExplorerUrls: ['https://sepolia-sandbox.funkichain.com'],
  },
  1: {
    urls: ['https://mainnet.infura.io/v3/47a3dff66e3e49c2b8fff75f0eb95c90'],
    name: 'Mainnet',
    blockExplorerUrls: ['https://etherscan.io'],
  },
  10: {
    urls: ['https://optimism-rpc.publicnode.com', 'https://endpoints.omniatech.io/v1/op/mainnet/public'],
    name: 'Optimism',
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
  },
  137: {
    urls: ['https://rpc-mainnet.matic.network', 'https://polygon-rpc.com', 'https://matic-mainnet.chainstacklabs.com'],
    name: 'Matic Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    blockExplorerUrls: ['https://polygonscan.com'],
  },
  80002: {
    urls: ['https://polygon-amoy-heimdall-rpc.publicnode.com:443'],
    name: 'Amoy Testnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    blockExplorerUrls: ['https://www.okx.com/explorer/amoy'],
  },
  11155420: {
    urls: ['https://optimism-sepolia.blockpi.network/v1/rpc/public', 'https://sepolia.optimism.io'],
    name: 'Sepolia Optimism',
    blockExplorerUrls: ['https://sepolia-optimistic.etherscan.io/'],
  },
  11155111: {
    urls: ['https://eth-sepolia.g.alchemy.com/v2/O5_ERqjSQ27SUpWsuQrnKCoi0erOntKW'],
    name: 'Sepolia',
    blockExplorerUrls: ['https://sepolia.etherscan.io'],
  },
}
