export const modelRecipeOptions = [
  {
    createdAt: "2023-09-08T02:57:34.762Z",
    updatedAt: "2023-10-12T19:20:05.788Z",
    hash: "4f24a26d75",
    name: "Anime Like 2.5D",
    description: "Ghibli anime style",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/AnimeLike25D_v11.w400.webp",
    styles: "anime",
    defaultSettings: null,
    fileName: "AnimeLike25D_v11.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-07-18T20:18:20.615Z",
    updatedAt: "2023-10-12T19:20:05.867Z",
    hash: "dde3b17c05",
    name: "A-Zovya Photoreal v2",
    description: "A-Zovya Photoreal v2",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/A-ZovyaPhotoreal.w400.webp",
    styles: "real",
    defaultSettings: null,
    fileName: "A-ZovyaPhotoreal_v2.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-10-12T19:20:06.001Z",
    updatedAt: "2023-10-12T19:20:06.001Z",
    hash: "d0204271fb",
    name: "A-Zovya RPG Artist Tools v2 Art",
    description: "A-Zovya RPG Artist Tools v2 Art",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/A-ZovyaRPGArtistTools_v2Art.w400.webp",
    styles: "semi-real",
    defaultSettings: null,
    fileName: "A-ZovyaRPGArtistTools_v2Art.pruned.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-05-29T09:27:27.572Z",
    updatedAt: "2023-10-12T19:20:06.172Z",
    hash: "8c4042921a",
    name: "A-Zovya RPG Artist Tools v3",
    description: "A-Zovya RPG Artist Tools v3",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/A-ZovyaRPGArtistTools_v3VAE.w400.webp",
    styles: "semi-real",
    defaultSettings: null,
    fileName: "A-ZovyaRPGArtistTools_v3VAE.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-07-18T20:18:21.205Z",
    updatedAt: "2023-10-12T19:20:06.252Z",
    hash: "4556b129be",
    name: "CarDos Animated v3.0",
    description: "CarDos Animated v3.0",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/CarDosAnimated_v30.w400.webp",
    styles: "semi-real",
    defaultSettings: null,
    fileName: "CarDosAnimated_v30.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-07-18T20:18:21.525Z",
    updatedAt: "2023-10-12T19:20:06.336Z",
    hash: "cc5ffdbb34",
    name: "CarDos Anime v1.0",
    description: "CarDos Anime v1.0",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/CarDosAnime_v10.w400.webp",
    styles: "anime",
    defaultSettings: null,
    fileName: "CarDosAnime_v10.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-05-29T09:27:27.572Z",
    updatedAt: "2023-10-12T19:20:06.422Z",
    hash: "f243aabb88",
    name: "CarDos Anime v2.0",
    description: "CarDos Anime v2.0",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/CarDosAnime_v20.w400.webp",
    styles: "anime",
    defaultSettings: null,
    fileName: "CarDosAnime_v20.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-08-29T10:47:24.369Z",
    updatedAt: "2023-10-12T19:20:06.502Z",
    hash: "bd518b9aee",
    name: "CetusMix Coda Edition",
    description: "CetusMix Coda Edition",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/CetusMix_Codaedition.w400.webp",
    styles: "anime",
    defaultSettings: null,
    fileName: "cetusMix_Codaedition.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-05-29T09:27:27.572Z",
    updatedAt: "2023-10-12T19:20:06.582Z",
    hash: "b6c16f806c",
    name: "Children's Stories v1 Tool-Anime",
    description: "Children's Stories v1 Tool-Anime",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/ChildrenStories_v1ToonAnime.w400.webp",
    styles: "comic",
    defaultSettings: null,
    fileName: "ChildrenStories_v1ToonAnime.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-07-18T20:18:22.427Z",
    updatedAt: "2023-10-12T19:20:06.657Z",
    hash: "c464e27568",
    name: "DreamShaper v3.32",
    description: "DreamShaper v3.32",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/DreamShaper_v332.w400.webp",
    styles: "real",
    defaultSettings: null,
    fileName: "DreamShaper_v331.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-07-18T20:18:23.061Z",
    updatedAt: "2023-10-12T19:20:06.733Z",
    hash: "879db523c3",
    name: "DreamShaper v8",
    description: "DreamShaper v8",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/DreamShaper_v7.w400.webp",
    styles: "real",
    defaultSettings: null,
    fileName: "DreamShaper_v8.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-06-14T02:23:49.447Z",
    updatedAt: "2023-10-12T19:20:06.895Z",
    hash: "8276e665fe",
    name: "DualComicMix Strike",
    description: "DualComicMix Strike",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/DuelComicMixStrike.w400.webp",
    styles: "comic",
    defaultSettings: null,
    fileName: "DuelComicMixStrike.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-09-08T03:17:22.554Z",
    updatedAt: "2023-10-12T19:20:06.818Z",
    hash: "fb72375a7a",
    name: "DualComicMix v1",
    description: "DualComicMix v1",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/DuelComicMix%20v1.0.w400.webp",
    styles: "comic",
    defaultSettings: null,
    fileName: "DuelComicMix_v1.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-09-08T03:17:22.908Z",
    updatedAt: "2023-10-12T19:20:06.983Z",
    hash: "e60664b77b",
    name: "EtherRealMix v3",
    description: "EtherRealMix v3",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/EtherRealMix_v3.w400.webp",
    styles: "comic",
    defaultSettings: null,
    fileName: "EtherRealMix_v3.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-07-18T20:18:23.845Z",
    updatedAt: "2023-10-12T19:20:07.068Z",
    hash: "5dd56bfa12",
    name: "Flat-2D Animerge v3",
    description: "Flat-2D Animerge v3",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/Flat2DAnimerge_v3.w400.webp",
    styles: "anime",
    defaultSettings: null,
    fileName: "Flat2DAnimerge_v3.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-05-29T09:27:27.572Z",
    updatedAt: "2023-10-12T19:20:07.991Z",
    hash: "76b00ee812",
    name: "iCoMix v2.0",
    description: "iCoMix v2.0",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/iCoMix_v2.w400.webp",
    styles: "comic",
    defaultSettings: null,
    fileName: "iCoMix_v02.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-05-29T09:27:27.572Z",
    updatedAt: "2023-10-12T19:20:07.142Z",
    hash: "f1b08b30f8",
    name: "Lyriel v1.4",
    description: "Lyriel v1.4",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/Lyriel_v14.w400.webp",
    styles: "semi-real",
    defaultSettings: null,
    fileName: "Lyriel_v14.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-07-18T20:18:24.297Z",
    updatedAt: "2023-10-12T19:20:07.216Z",
    hash: "ec6f68ea63",
    name: "Lyriel v1.6",
    description: "Lyriel v1.6",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/Lyriel_v16.w400.webp",
    styles: "semi-real",
    defaultSettings: null,
    fileName: "Lyriel_v16.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-09-08T02:57:36.437Z",
    updatedAt: "2023-10-12T19:20:07.296Z",
    hash: "c35e1054c0",
    name: "Mistoon Anime v2.0",
    description: "Mistoon Anime v2.0",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/MistoonAnime_v20.w400.webp",
    styles: "comic",
    defaultSettings: null,
    fileName: "MistoonAnime_v20.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-08-14T09:55:02.907Z",
    updatedAt: "2023-10-12T19:20:07.451Z",
    hash: "c0d1994c73",
    name: "Realistic Vision v2.0",
    description: "Realistic Vision v2.0",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/RealisticVision_v2.0.w400.webp",
    styles: "real",
    defaultSettings: null,
    fileName: "RealisticVision_v20.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-05-29T09:27:27.572Z",
    updatedAt: "2023-10-12T19:20:07.374Z",
    hash: "4199bcdd14",
    name: "ReV Animated v1.2.2",
    description: "ReV Animated v1.2.2",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/ReVAnimated_v122.w400.webp",
    styles: "semi-real",
    defaultSettings: null,
    fileName: "ReVAnimated_v122.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
  {
    createdAt: "2023-09-08T03:17:23.966Z",
    updatedAt: "2023-10-12T19:20:07.915Z",
    hash: "52768d2bc4",
    name: "ToonYou Beta3",
    description: "ToonYou Beta3",
    thumbnail: "https://d2lae7oxvyyfdd.cloudfront.net/verified-models-thumbnail/ToonYou_Beta3.w400.webp",
    styles: "anime",
    defaultSettings: null,
    fileName: "ToonYou_Beta3.safetensors",
    variant: "normal",
    baseModel: "sd-1x",
  },
]
